import { Button, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { useStudyStatus } from "../../Utilities/FetchHooks/Studies/StudiesHooks";
import { forwardRef, useMemo, useState } from "react";
import moment from "moment";

const DicomSendStatus = ({ dicomSendEntry }) => {
  const { status } = dicomSendEntry;

  const statusMap = {
    0: {
      title: "Pending",
      icon: "fas fa-clock",
      color: "text-warning",
    },
    1: {
      title: "Sent",
      icon: "fas fa-check",
      color: "text-success",
    },
    2: {
      title: "Failed",
      icon: "fas fa-times",
      color: "text-danger",
    },
  };

  return (
    <div
      className="d-flex align-items-center p-1"
      style={{ background: "#fefefe" }}
    >
      <div className="text-center">
        Study was sent at:{" "}
        {moment(dicomSendEntry.created).format("DD.MM.YYYY HH:mm")}
        <span>
          <i className="fas fa-arrow-right px-1" />
        </span>
        {dicomSendEntry.systeme.bezeichnung} ({dicomSendEntry.systeme.aetitle})
      </div>

      <div className="text-center">
        <i
          className={`${statusMap[status].icon} ${statusMap[status].color}`}
        ></i>{" "}
        {statusMap[status].title}
      </div>
    </div>
  );
};

const StudyPopover = forwardRef(
  ({ popper, children, show: _, ...props }, ref) => {
    return (
      <Popover ref={ref} content {...props}>
        {props.status === "loading" ? (
          <div>
            <Spinner animation="border" role="status" />
          </div>
        ) : props.status === "error" ? (
          <h5>Error</h5>
        ) : props.status === "success" &&
          (!props.data.dicom_send || props.data.dicom_send.length === 0) &&
          (!props.data.protokol || props.data.protokol.length === 0) ? (
          <h5>No data</h5>
        ) : (
          <div>
            {props?.data?.dicom_send?.map((dicomSendEntry) => (
              <DicomSendStatus
                key={dicomSendEntry.id}
                dicomSendEntry={dicomSendEntry}
              />
            ))}
          </div>
        )}
      </Popover>
    );
  }
);

function StudyInfoPopover({ studyId, customToggle = null, autoLoad = true }) {
  const [loadingEnabled, setLoadingEnabled] = useState(autoLoad);
  const { data, status } = useStudyStatus({
    studyId: studyId,
    enabled: loadingEnabled,
  });

  const color = useMemo(() => {
    let color = "secondary";
    if (data) {
      const set = new Set();
      data?.dicom_send?.forEach((dicomEntry) => {
        set.add(dicomEntry.status);
      });
      if (set.has(1)) {
        color = "success";
      }

      if (set.has(0)) {
        color = "warning";
      }

      if (set.has(2)) {
        color = "danger";
      }
    }
    return color;
  }, [data]);

  return (
    <OverlayTrigger
      trigger="focus"
      placement="left"
      overlay={<StudyPopover data={data} status={status} studyId={studyId} />}
      onToggle={() => {
        setLoadingEnabled(true);
      }}
    >
      {customToggle ? (
        () => customToggle
      ) : (
        <Button variant={color} size="sm">
          <i className="fas fa-info fa-sm"></i>
        </Button>
      )}
    </OverlayTrigger>
  );
}

export default StudyInfoPopover;
