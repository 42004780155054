import "./StandingCranial.scss";

function StandingCranial({ angle }) {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 283.5 283.5"
    >
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          data-name="Unbenannter Verlauf"
          x1="1319.8"
          y1="-228.1"
          x2="1281.6"
          y2="-228.1"
          gradientTransform="translate(1343.7 -84.1) rotate(-180)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".3" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_2"
          data-name="Unbenannter Verlauf 2"
          x1="1167.4"
          y1="43.7"
          x2="1208.6"
          y2="43.7"
          gradientTransform="translate(-154.4 1251.1) rotate(-69.7) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_3"
          data-name="Unbenannter Verlauf 3"
          x1="1160.5"
          y1="5.7"
          x2="1209.3"
          y2="5.7"
          gradientTransform="translate(-154.4 1251) rotate(-69.7) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
      </defs>
      <g id="Ebene_11" data-name="Ebene_1">
        <g id="Ebene_1-2">
          <g id="a">
            <rect
              class="standingcranial5"
              x="-.1"
              y="-.2"
              width="283.5"
              height="283.5"
            />
            <rect
              class="standingcranial4"
              x="10.7"
              y="9.1"
              width="17.2"
              height="270.2"
            />
            <path
              class="standingcranial7"
              d="M26,62.9l-1.3,15.3c1.1,16.2,7.4,32.6,6.6,38.3,0,11.9-8.3,16.8-6.6,25.8,1.1,4.7,2.9,9.2,6.2,16.8-2.1,6.6-2.8,11.4-2.6,15-.5,6.8-.3,19,2.3,30.2-1.8,4.8-2.2,12.6-2.1,21.6.5,11.1,4.9,22.3,4.7,33.5,0,8.3-3.2,14.5-.3,15.5-2.8-.5,31.3,1.5,28.1-1.5-6.2-2.4-15.6-.7-19.8-12.4,0-13.5.3-39,4.4-57.2,1.8-8.5,8.8-37.2,7.7-49.1l3.2-65.6s8.1-6.6-9.2-29.3c-1.5-12.4,3.9-15,4-14.6,4.3,1.1,6.2.3,6.2-.4.1-1.6.1-2.3.3-3.8.5-.5.3-1,1-1.2.3-.5.6-2-.2-4.3l2.8-.2c.5-2.6-1.6-3.5-3.2-6.4-.5-.8-.4-2.1.4-3.6.5-.9-2.9-11.5-15.3-12.7-10.1.3-12.7,2.2-13.7,12.7-.2,6.2,7,14.8,5.8,22.6-4.4,3.3-9.1,13.6-9.4,14.9h0Z"
            />
            <g>
              <g>
                <line
                  class="standingcranial6"
                  x1="246.4"
                  y1="136.5"
                  x2="250.3"
                  y2="136.5"
                />
                <line
                  class="standingcranial3"
                  x1="78.3"
                  y1="137.9"
                  x2="238.9"
                  y2="136.6"
                />
                <line
                  class="standingcranial6"
                  x1="70.7"
                  y1="138"
                  x2="74.6"
                  y2="137.9"
                />
              </g>
              <line
                class="standingcranial6"
                x1="91.5"
                y1="9.4"
                x2="251.6"
                y2="136.7"
              />
              <g>
                <path
                  class="standingcranial6"
                  d="M88,17.8c-17.3,35.7-22.3,75-16.8,116.6"
                />
                <path
                  class="standingcranial9"
                  d="M91.5,10.8c-.5,3.9-.4,9,.5,12.7l-4.5-4.8-6.5-.8c3.5-1.4,7.7-4.5,10.5-7.2h0Z"
                />
              </g>
              <g>
                <polygon
                  class="standingcranial2"
                  points="209.8 97.1 196.2 136.1 227.6 135.8 233.4 119.2 209.8 97.1"
                />
                <path
                  class="standingcranial8"
                  d="M228.2,133.2c2.3-13.4,14.5-22.2,27.2-19.7,12.7,2.5,21.3,15.3,19,28.7s-14.4,22.2-27.2,19.7c-12.7-2.5-21.3-15.3-19-28.7h0Z"
                />
              </g>
            </g>
          </g>
          <g class="standingcranial1">
            <g class="standingcranial1">
              <g class="standingcranial1">
                <text
                  class="standingcranial0"
                  transform="translate(79.6 112.8)"
                >
                  <tspan x="0" y="0">
                    {angle}°
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default StandingCranial;
