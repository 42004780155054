import { getFetch, MakeUrl, postFetch, REST_URL } from "../../FetchWrapper";

export async function getFacilities(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/pmed/facilities`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getFacility(keycloak, params) {
  if (!params.facilityId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/pmed/facilities/${params.facilityId}`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getFacilityRefPhysicians(keycloak, params) {
  if (!params.facilityId) {
    return {};
  }
  const url = MakeUrl(
    `${REST_URL}/pmed/facilities/${params.facilityId}/refphysicians`
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getRefPhysicians(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/pmed/refphysicians`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getRefPhysician(keycloak, params) {
  if (!params.refPhysId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/pmed/refphysicians/${params.refPhysId}`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getResources(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/pmed/resources`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getSystems(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/pmed/systems`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getUsers(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/pmed/users`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getUser(keycloak, params) {
  if (!params.userId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/pmed/users/${params.userId}`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getUserPreferences(keycloak, params) {
  if (!params.userId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/pmed/users/${params.userId}/preferences`);
  return getFetch(url, keycloak, params.xmask);
}

export async function setUserPreferences(keycloak, params) {
  if (!params.userId || !params.data) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/pmed/users/${params.userId}/preferences`);
  return postFetch(url, keycloak, params.data);
}

export async function getProtokol(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/pmed/protokol`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}
