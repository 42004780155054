import woman from "../images/woman";
import man from "../images/man";

const IMAGES = {
  woman: {
    11: woman.image11,
    12: woman.image12,
    14: woman.image14,
    15: woman.image15,
    17: woman.image17,
    18: woman.image18,
    20: woman.image20,
    21: woman.image21,
    23: woman.image23,
    24: woman.image24,
  },
  men: {
    11: man.image11,
    12: man.image12,
    14: man.image14,
    15: man.image15,
    17: man.image17,
    18: man.image18,
    20: man.image20,
    21: man.image21,
    23: man.image23,
    24: man.image24,
  },
};

const ALLOWED_EXAMS = [
  "11",
  "12",
  "14",
  "15",
  "17",
  "18",
  "20",
  "21",
  "23",
  "24",
];

function useExamPreparationImage(examId, gender = "woman") {
  if (!examId) return null;
  const prefix = examId.toString().substring(0, 2);
  if (!ALLOWED_EXAMS.includes(prefix)) return null;

  return IMAGES[gender][prefix];
}

export default useExamPreparationImage;
