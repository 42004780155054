import underTable from "./images/underTable.svg";
import onTable from "./images/onTable.svg";
import wallMounted from "./images/wallmount.svg";
import beamHold from "./images/beamHold.svg";

import styles from "./RecieverPos.module.scss";
import { Spinner } from "react-bootstrap";

function RecieverPos({ examData }) {
  if (!examData || !examData.img_rec_pos) return <Spinner animation="border" />;
  if (examData.img_rec_pos === "Untertisch")
    return (
      <img className={styles.rec_pos} src={underTable} alt="Under Table" />
    );
  if (examData.img_rec_pos === "Auftisch")
    return <img className={styles.rec_pos} src={onTable} alt="Under Table" />;
  if (examData.img_rec_pos === "Rasterwandstativ")
    return (
      <img className={styles.rec_pos} src={wallMounted} alt="Under Table" />
    );
  if (examData.img_rec_pos === "gehalten")
    return <img className={styles.rec_pos} src={beamHold} alt="Under Table" />;
}

export default RecieverPos;
