import {
  SET_REPORT_TYPE,
  ADD_REPORT_SELECTED_ID,
  REMOVE_REPORT_SELECTED_ID,
  ADD_REMOVE_REPORT_SELECTED_ID,
  SET_REPORT_DATE_RANGE,
  ADD_REPORT_FORMAT,
  REMOVE_REPORT_FORMAT,
  SET_INITIAL_REPORT_STATE,
  CLEAR_REPORT_DETAILS,
  SET_REPORT_IS_PACKAGE,
  SET_OPENED_PATIENT,
  REMOVE_OPENED_PATIENT,
  CLEAR_OPENED_PATIENT,
  ADD_REMOVE_REPORT_VARIANT,
  SET_ACTIVE_THEME,
  SET_WEBVIEWER_AVAILABLE,
  SELECT_EXAM,
  UNSELECT_EXAM,
  SELECT_EXAM_SERIE,
  UNSELECT_EXAM_SERIE,
  SELECT_EXAM_SERIE_IMAGE,
  UNSELECT_EXAM_SERIE_IMAGE,
  CLEAR_SELECTED_EXAMINATIONS,
  ADD_IMPORTING_JOB,
  REMOVE_IMPORTING_JOB,
  UPDATE_IMPORTING_JOB,
  SET_RELOAD_EXAMINATIONS_FLAG,
  SET_RELOAD_PATIENTLIST_FLAG,
  SET_SELECT_ALL_EXAMINATIONS_FLAG,
  SET_EXPAND_ALL_EXAMINATIONS,
  SET_RELOAD_PATIENTDATA_FLAG,
  ADD_FACILITY_SELECTED_ID,
  ADD_NEW_PRICE,
  REPLACE_NEW_PRICES,
  ADD_PRICE_INFO,
  ADD_DEFAULT_PRICES,
  EMPTY_NEW_PRICES,
  TOGGLE_CONFIGURATION,
  CLEAR_SELECTED_FACILITIES,
  SET_CHANGED_PRICES,
  SET_EXAMPLANNER_PATIENT_ID,
  CLEAR_EXAMPLANNER_PATIENT_ID,
  SET_EXAMPLANNER_DEVICE_ID,
  CLEAR_EXAMPLANNER_DEVICE_ID,
  SET_EXAMPLANNER_FACILITY_ID,
  CLEAR_EXAMPLANNER_FACILITY_ID,
  SET_EXAMPLANNER_FACILITY_DOC_ID,
  CLEAR_EXAMPLANNER_FACILITY_DOC_ID,
  SET_EXAMPLANNER_RADIOLOGIST_ID,
  CLEAR_EXAMPLANNER_RADIOLOGIST_ID,
  ADD_EXAMPLANNER_INDICIATION,
  REMOVE_EXAMPLANNER_INDICATION,
  CLEAR_EXAMPLANNER_INDICATION,
  ADD_EXAMPLANNER_EXAMINATION,
  REMOVE_EXAMPLANNER_EXAMINATION,
  CLEAR_EXAMPLANNER_EXAMINATION,
  SELECT_EXAMPLANNER_BODYPART,
  CLEAR_EXAMPLANNER_BODYPART,
  SELECT_EXAMPLANNER_DEVICE_TYPE,
  CLEAR_EXAMPLANNER_VIEW_CONTROLL,
  CLEAR_EXAMPLANNER_RIS_DATA,
  CLEAR_EXAMPLANNER_STATE,
  CLEAR_EXAMPLANNER_BODYPART_HISTORY,
  ADD_EXAMPLANNER_BODYPART_TO_HISTORY,
  SELECT_PREVIOUS_BODY_PART,
  SET_XMSTATS_FILTER_OBJ,
  RESET_XMSTATS_FILTER_OBJ,
  SET_XMSTATS_SELECTED_PATIENT,
  SET_XMSTATS_SELECTED_EXAM,
  SET_XMSTATS_SELECTED_SERIE,
  SET_XMSTATS_SELECTED_IMAGE,
  SET_XMSTATS_CHANGES_MADE,
  SET_XMSTATS_RELOAD_FLAG,
  SET_XMSTATS_SELECTED_SUBSTANTIATION,
  RESET_XMSTATS_STATE,
  INCRASE_XMSTATS_SHOULD_SAVE_COUNT,
  SET_XMSTATS_SELECTED_MODALITY,
  SET_XMSTATS_RELOAD_EXAM_FLAG,
  SET_QA_SELECTED_EXAM,
  RESET_QA_SELECTED_EXAM,
  SET_QA_SELECTED_SERIE,
  RESET_QA_SELECTED_SERIE,
  SET_QA_SELECTED_IMAGE,
  RESET_QA_SELECTED_IMAGE,
  SET_QA_CHANGES,
  RESET_QA_CHANGES,
  SAVE_QA_CHANGES,
  RESET_REFETCH_FLAG,
  CLEAR_EXAM_ARCHIVE_FILTER,
  SET_EXAM_ARCHIVE_FILTER,
  ADD_REMOVE_EXAM_ARCHIVE_MODALITY,
  SET_EXAM_ARCHIVE_FROM_DATE,
  SET_EXAM_ARCHIVE_TO_DATE,
  SET_EXAM_ARCHIVE_PATIENT_SEARCH,
  SET_EXAM_ARCHIVE_DATE_FILTER_ENABLED,
  SET_EXAM_ARCHIVE_SORT,
} from "./actionTypes";

export const setReportType = (content) => ({
  type: SET_REPORT_TYPE,
  payload: {
    content,
  },
});

export const addReportSelectedId = (content) => ({
  type: ADD_REPORT_SELECTED_ID,
  payload: {
    content,
  },
});

export const removeReportSelectedId = (content) => ({
  type: REMOVE_REPORT_SELECTED_ID,
  payload: {
    content,
  },
});

export const addRemoveReportSelectedId = (content) => ({
  type: ADD_REMOVE_REPORT_SELECTED_ID,
  payload: {
    content,
  },
});

export const setReportDateRange = (content) => ({
  type: SET_REPORT_DATE_RANGE,
  payload: {
    content,
  },
});

export const addReportFormat = (content) => ({
  type: ADD_REPORT_FORMAT,
  payload: {
    content,
  },
});

export const removeReportFormat = (content) => ({
  type: REMOVE_REPORT_FORMAT,
  payload: {
    content,
  },
});

export const setInitialReportsState = (content) => ({
  type: SET_INITIAL_REPORT_STATE,
  payload: {
    content,
  },
});

export const clearReportDetails = (content) => ({
  type: CLEAR_REPORT_DETAILS,
  payload: {
    content,
  },
});

export const setReportIsPackage = (content) => ({
  type: SET_REPORT_IS_PACKAGE,
  payload: {
    content,
  },
});

export const addRemoveReportVariant = (content) => ({
  type: ADD_REMOVE_REPORT_VARIANT,
  payload: {
    content,
  },
});

export const toggleConfiguration = (content) => ({
  type: TOGGLE_CONFIGURATION,
  payload: {
    content,
  },
});

export const setOpenedPatient = (content) => ({
  type: SET_OPENED_PATIENT,
  payload: {
    content,
  },
});

export const removeOpenedPatient = (content) => ({
  type: REMOVE_OPENED_PATIENT,
  payload: {
    content,
  },
});

export const clearOpenedPatient = (content) => ({
  type: CLEAR_OPENED_PATIENT,
  payload: {
    content,
  },
});

export const setWebViewerAvailable = (content) => ({
  type: SET_WEBVIEWER_AVAILABLE,
  payload: {
    content,
  },
});

export const setActiveTheme = (content) => ({
  type: SET_ACTIVE_THEME,
  payload: {
    content,
  },
});

//ExamSelection part
export const selectExam = (content) => ({
  type: SELECT_EXAM,
  payload: {
    content,
  },
});
export const unselectExam = (content) => ({
  type: UNSELECT_EXAM,
  payload: {
    content,
  },
});
export const selectExamSerie = (content) => ({
  type: SELECT_EXAM_SERIE,
  payload: {
    content,
  },
});
export const unselectExamSerie = (content) => ({
  type: UNSELECT_EXAM_SERIE,
  payload: {
    content,
  },
});
export const selectExamSerieImage = (content) => ({
  type: SELECT_EXAM_SERIE_IMAGE,
  payload: {
    content,
  },
});
export const unselectExamSerieImage = (content) => ({
  type: UNSELECT_EXAM_SERIE_IMAGE,
  payload: {
    content,
  },
});
export const clearSelectedExaminations = (content) => ({
  type: CLEAR_SELECTED_EXAMINATIONS,
  payload: {
    content,
  },
});

//importing jobs
export const addImportingJob = (content) => ({
  type: ADD_IMPORTING_JOB,
  payload: {
    content,
  },
});

export const removeImportingJob = (content) => ({
  type: REMOVE_IMPORTING_JOB,
  payload: {
    content,
  },
});

export const updateImportingJob = (content) => ({
  type: UPDATE_IMPORTING_JOB,
  payload: {
    content,
  },
});

export const setReloadPatientListFlag = (content) => ({
  type: SET_RELOAD_PATIENTLIST_FLAG,
  payload: {
    content,
  },
});

export const setReloadExaminationsFlag = (content) => ({
  type: SET_RELOAD_EXAMINATIONS_FLAG,
  payload: {
    content,
  },
});

export const setSelectAllExaminationsFlag = (content) => ({
  type: SET_SELECT_ALL_EXAMINATIONS_FLAG,
  payload: {
    content,
  },
});

export const setExpandAllExaminations = (content) => ({
  type: SET_EXPAND_ALL_EXAMINATIONS,
  payload: {
    content,
  },
});

export const setReloadPatientDataFlag = (content) => ({
  type: SET_RELOAD_PATIENTDATA_FLAG,
  payload: {
    content,
  },
});

export const addFacilityID = (content) => ({
  type: ADD_FACILITY_SELECTED_ID,
  payload: {
    content,
  },
});

export const clearSelectedFacilities = (content) => ({
  type: CLEAR_SELECTED_FACILITIES,
  payload: {
    content,
  },
});

export const addNewPrice = (content) => ({
  type: ADD_NEW_PRICE,
  payload: {
    content,
  },
});

export const replaceNewPrices = (content) => ({
  type: REPLACE_NEW_PRICES,
  payload: {
    content,
  },
});

export const addPriceInfo = (content) => ({
  type: ADD_PRICE_INFO,
  payload: {
    content,
  },
});

export const addDefaultPrices = (content) => ({
  type: ADD_DEFAULT_PRICES,
  payload: {
    content,
  },
});

export const emptyNewPrices = (content) => ({
  type: EMPTY_NEW_PRICES,
  payload: {
    content,
  },
});

export const setChangedPrices = (content) => ({
  type: SET_CHANGED_PRICES,
  payload: {
    content,
  },
});

export const setExamplannerPatientId = (content) => ({
  type: SET_EXAMPLANNER_PATIENT_ID,
  payload: {
    content,
  },
});

export const clearExamplannerPatientId = (content) => ({
  type: CLEAR_EXAMPLANNER_PATIENT_ID,
  payload: {
    content,
  },
});

export const setExamplannerDeviceId = (content) => ({
  type: SET_EXAMPLANNER_DEVICE_ID,
  payload: {
    content,
  },
});

export const clearExamplannerDeviceId = (content) => ({
  type: CLEAR_EXAMPLANNER_DEVICE_ID,
  payload: {
    content,
  },
});

export const setExamplannerFacilityId = (content) => ({
  type: SET_EXAMPLANNER_FACILITY_ID,
  payload: {
    content,
  },
});

export const clearExamplannerFacilityId = (content) => ({
  type: CLEAR_EXAMPLANNER_FACILITY_ID,
  payload: {
    content,
  },
});

export const setExamplannerFacilityDocId = (content) => ({
  type: SET_EXAMPLANNER_FACILITY_DOC_ID,
  payload: {
    content,
  },
});

export const clearExamplannerFacilityDocId = (content) => ({
  type: CLEAR_EXAMPLANNER_FACILITY_DOC_ID,
  payload: {
    content,
  },
});

export const setExamplannerRadiologistId = (content) => ({
  type: SET_EXAMPLANNER_RADIOLOGIST_ID,
  payload: {
    content,
  },
});

export const clearExamplannerRadiologistId = (content) => ({
  type: CLEAR_EXAMPLANNER_RADIOLOGIST_ID,
  payload: {
    content,
  },
});

export const addExamplannerIndication = (content) => ({
  type: ADD_EXAMPLANNER_INDICIATION,
  payload: {
    content,
  },
});

export const removeExamplannerIndication = (content) => ({
  type: REMOVE_EXAMPLANNER_INDICATION,
  payload: {
    content,
  },
});

export const clearExamplannerIndication = (content) => ({
  type: CLEAR_EXAMPLANNER_INDICATION,
  payload: {
    content,
  },
});

export const addExamplannerExamination = (content) => ({
  type: ADD_EXAMPLANNER_EXAMINATION,
  payload: {
    content,
  },
});

export const removeExamplannerExamination = (content) => ({
  type: REMOVE_EXAMPLANNER_EXAMINATION,
  payload: {
    content,
  },
});

export const clearExamplannerExamination = (content) => ({
  type: CLEAR_EXAMPLANNER_EXAMINATION,
  payload: {
    content,
  },
});

export const selectExamplannerBodypart = (content) => ({
  type: SELECT_EXAMPLANNER_BODYPART,
  payload: {
    content,
  },
});

export const clearExamplannerBodypart = (content) => ({
  type: CLEAR_EXAMPLANNER_BODYPART,
  payload: {
    content,
  },
});

export const selectExamplannerDeviceType = (content) => ({
  type: SELECT_EXAMPLANNER_DEVICE_TYPE,
  payload: {
    content,
  },
});

export const clearExamplannerViewControll = (content) => ({
  type: CLEAR_EXAMPLANNER_VIEW_CONTROLL,
  payload: {
    content,
  },
});

export const clearExamplannerRisData = (content) => ({
  type: CLEAR_EXAMPLANNER_RIS_DATA,
  payload: {
    content,
  },
});

export const clearExamplannerState = (content) => ({
  type: CLEAR_EXAMPLANNER_STATE,
  payload: {
    content,
  },
});

export const clearExamplannerBodyPartHistory = (content) => ({
  type: CLEAR_EXAMPLANNER_BODYPART_HISTORY,
  payload: {
    content,
  },
});

export const addExamplannerBodyPartToHistory = (content) => ({
  type: ADD_EXAMPLANNER_BODYPART_TO_HISTORY,
  payload: {
    content,
  },
});

export const selectPreviousBodypart = (content) => ({
  type: SELECT_PREVIOUS_BODY_PART,
  payload: {
    content,
  },
});

export const setXmStatsFilterObj = (content) => ({
  type: SET_XMSTATS_FILTER_OBJ,
  payload: {
    content,
  },
});
export const resetXmStatsFilterObj = (content) => ({
  type: RESET_XMSTATS_FILTER_OBJ,
  payload: {
    content,
  },
});
export const setXmStatsSelectedPatient = (content) => ({
  type: SET_XMSTATS_SELECTED_PATIENT,
  payload: {
    content,
  },
});
export const setXmStatsSelectedExam = (content) => ({
  type: SET_XMSTATS_SELECTED_EXAM,
  payload: {
    content,
  },
});
export const setXmStatsSelectedSerie = (content) => ({
  type: SET_XMSTATS_SELECTED_SERIE,
  payload: {
    content,
  },
});
export const setXmStatsSelectedImage = (content) => ({
  type: SET_XMSTATS_SELECTED_IMAGE,
  payload: {
    content,
  },
});
export const setXmStatsChangesMade = (content) => ({
  type: SET_XMSTATS_CHANGES_MADE,
  payload: {
    content,
  },
});
export const setXmStatsReloadFlag = (content) => ({
  type: SET_XMSTATS_RELOAD_FLAG,
  payload: {
    content,
  },
});
export const setXmStatsReloadExamFlag = (content) => ({
  type: SET_XMSTATS_RELOAD_EXAM_FLAG,
  payload: {
    content,
  },
});
export const setXmStatsSelectedSubstantiation = (content) => ({
  type: SET_XMSTATS_SELECTED_SUBSTANTIATION,
  payload: {
    content,
  },
});

export const setXmStatsSelectedModality = (content) => ({
  type: SET_XMSTATS_SELECTED_MODALITY,
  payload: {
    content,
  },
});

export const resetXmStatsState = (content) => ({
  type: RESET_XMSTATS_STATE,
  payload: {
    content,
  },
});

export const incraseXmStatsShouldSaveCount = () => ({
  type: INCRASE_XMSTATS_SHOULD_SAVE_COUNT,
});

//exams qa
export const setQaSelectedExam = (content, refetch = false) => ({
  type: SET_QA_SELECTED_EXAM,
  payload: {
    content,
    refetch: refetch,
  },
});
export const resetQaSelectedExam = (content) => ({
  type: RESET_QA_SELECTED_EXAM,
  payload: {
    content,
  },
});
export const setQaSelectedSerie = (content, refetch = false) => ({
  type: SET_QA_SELECTED_SERIE,
  payload: {
    content,
    refetch: refetch,
  },
});
export const resetQaSelectedSerie = (content) => ({
  type: RESET_QA_SELECTED_SERIE,
  payload: {
    content,
  },
});
export const setQaSelectedImage = (content, refetch = false) => ({
  type: SET_QA_SELECTED_IMAGE,
  payload: {
    content,
    refetch: refetch,
  },
});
export const resetQaSelectedImage = (content) => ({
  type: RESET_QA_SELECTED_IMAGE,
  payload: {
    content,
  },
});
export const setQaChanges = (content) => ({
  type: SET_QA_CHANGES,
  payload: {
    content,
  },
});
export const resetQaChanges = (content) => ({
  type: RESET_QA_CHANGES,
  payload: {
    content,
  },
});

export const saveQaChanges = (content) => ({
  type: SAVE_QA_CHANGES,
  payload: {
    content,
  },
});

export const resetRefetchFlag = (content) => ({
  type: RESET_REFETCH_FLAG,
  payload: {
    content,
  },
});

export const clearEamArchiveFilter = (content) => ({
  type: CLEAR_EXAM_ARCHIVE_FILTER,
  payload: {
    content,
  },
});

export const setExamArchiveFilter = (content) => ({
  type: SET_EXAM_ARCHIVE_FILTER,
  payload: {
    content,
  },
});

export const addRemoveExamArchiveModality = (content) => ({
  type: ADD_REMOVE_EXAM_ARCHIVE_MODALITY,
  payload: {
    content,
  },
});

export const setExamArchiveFromDate = (content) => ({
  type: SET_EXAM_ARCHIVE_FROM_DATE,
  payload: {
    content,
  },
});

export const setExamArchiveToDate = (content) => ({
  type: SET_EXAM_ARCHIVE_TO_DATE,
  payload: {
    content,
  },
});

export const setExamArchivePatientSearch = (content) => ({
  type: SET_EXAM_ARCHIVE_PATIENT_SEARCH,
  payload: {
    content,
  },
});

export const setExamArchiveDateFilterEnabled = (content) => ({
  type: SET_EXAM_ARCHIVE_DATE_FILTER_ENABLED,
  payload: {
    content,
  },
});

export const setExamArchiveSort = (content) => ({
  type: SET_EXAM_ARCHIVE_SORT,
  payload: {
    content,
  },
});
