import { Modal } from "react-bootstrap";
import FacilitiesTable from "../Facilities/FacilitiesList";
import { useDispatch, useSelector } from "react-redux";
import {
  setExamplannerFacilityId,
  clearExamplannerFacilityId,
} from "../../Redux/actions";
import { useTranslation } from "react-i18next";

function FacilitiesSelectorModal({ shown = false, closeHandler = undefined }) {
  const dispatch = useDispatch();
  const selectedFacility = useSelector(
    (state) => state.examPlanner.risData.facilityId
  );

  const clickRow = (i, row) => {
    if (row.original.id === selectedFacility) {
      dispatch(clearExamplannerFacilityId());
      return;
    }
    dispatch(setExamplannerFacilityId(row.original.id));
    closeHandler();
  };
  const { t } = useTranslation(["examplanner", "common"]);
  return (
    <Modal show={shown} onHide={closeHandler} size="lg">
      <Modal.Header closeButton className="p-2">
        <h5>{t("examSelect.selectFacility")}</h5>
      </Modal.Header>
      <Modal.Body>
        <FacilitiesTable
          headerLess={true}
          rowClick={clickRow}
          selectedIds={[selectedFacility]}
        />
      </Modal.Body>
    </Modal>
  );
}

export default FacilitiesSelectorModal;
