import { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addRemoveExamArchiveModality,
  clearEamArchiveFilter,
  clearSelectedExaminations,
  setExamArchiveDateFilterEnabled,
  setExamArchiveFilter,
  setExamArchiveFromDate,
  setExamArchivePatientSearch,
  setExamArchiveToDate,
  setExpandAllExaminations,
  setExamArchiveSort,
} from "../Redux/actions";
import { useStudies } from "../Utilities/FetchHooks/Studies/StudiesHooks";
import { useTranslation } from "react-i18next";

//stylesheets
import "react-day-picker/lib/style.css";
import "./stylesheets/ExamArchive.scss";
import StudiesList from "../Components/StudiesList/StudiesList";

function ExamArchiveFiltering() {
  const dispatch = useDispatch();
  const {
    patientSearch,
    modalities,
    fromDate,
    toDate,
    filter,
    fromEnabled,
    toEnabled,
    sort,
  } = useSelector((state) => state.examArchive);
  const { t } = useTranslation(["archive", "common"]);

  useEffect(() => {
    dispatch(setExamArchiveFilter());
    dispatch(setExpandAllExaminations(false));
  }, []);

  const SORT_OPTIONS = [
    { label: t("patientname", { ns: "common" }), value: "patientsname" },
    { label: t("studyDescript", { ns: "common" }), value: "studydescription" },
    { label: t("studyDate", { ns: "common" }), value: "studydate" },
    { label: t("finding", { ns: "common" }), value: "flag" },
  ];

  const getSortTitle = () => {
    if (!sort) return "Sort";
    const option = SORT_OPTIONS.find((opt) => opt.value === sort.field);
    if (!option) return "Sort";
    return (sort.direction === "asc" ? "↥" : "↧") + " " + option.label;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        dispatch(setExamArchiveFilter());
      }}
    >
      <div
        className="d-flex rounded py-2"
        style={{
          height: "10vh",
          backgroundColor: "#3D3D3D",
          color: "#fff",
          gap: "15px",
        }}
      >
        <div className="d-flex flex-column ml-3">
          <div>{t("patientname", { ns: "common" })}</div>
          <input
            value={patientSearch}
            onChange={(e) => {
              dispatch(setExamArchivePatientSearch(e.target.value));
            }}
            type="text"
            placeholder={t("patientname", { ns: "common" }) + " ..."}
            className="pl-1"
            style={{
              width: "350px",
              fontSize: "1.3rem",
              borderRadius: "6px",
            }}
          />
        </div>
        <div className="d-flex flex-column ml-3">
          <div title="if none checked all modalities displayed">
            {t("modalities", { ns: "common" })}
          </div>
          <Form.Row>
            <Form.Check
              style={{
                width: "80px",
              }}
              type="switch"
              id="XRay-switch"
              label="XRay"
              checked={modalities.includes("xray")}
              onChange={() => dispatch(addRemoveExamArchiveModality("xray"))}
            />
            <Form.Check
              style={{
                width: "80px",
              }}
              type="switch"
              id="CT-switch"
              label="CT"
              checked={modalities.includes("ct")}
              onChange={() => dispatch(addRemoveExamArchiveModality("ct"))}
            />
            <Form.Check
              style={{
                width: "80px",
              }}
              type="switch"
              id="MRI-switch"
              label="MR"
              checked={modalities.includes("mr")}
              onChange={() => dispatch(addRemoveExamArchiveModality("mr"))}
            />
          </Form.Row>
          <Form.Row>
            <Form.Check
              style={{
                width: "80px",
              }}
              type="switch"
              id="MG-switch"
              label="MG"
              checked={modalities.includes("mg")}
              onChange={() => dispatch(addRemoveExamArchiveModality("mg"))}
            />
            <Form.Check
              style={{
                width: "80px",
              }}
              type="switch"
              id="USG-switch"
              label="US"
              checked={modalities.includes("us")}
              onChange={() => dispatch(addRemoveExamArchiveModality("us"))}
            />
          </Form.Row>
        </div>
        <div className="d-flex ml-3 flex-column">
          <div className="d-flex">
            <div>{t("from", { ns: "common" })}</div>
            <Form.Check
              style={{
                width: "80px",
                marginLeft: "10px",
              }}
              type="switch"
              id="fromDate-switch"
              // label=""
              checked={fromEnabled}
              onChange={() =>
                dispatch(
                  setExamArchiveDateFilterEnabled({
                    fromEnabled: !fromEnabled,
                    toEnabled: toEnabled,
                  })
                )
              }
            />
          </div>
          <input
            style={{
              fontSize: "1.3rem",
              borderRadius: "6px",
            }}
            value={fromDate}
            onChange={(e) => dispatch(setExamArchiveFromDate(e.target.value))}
            type="date"
          />
        </div>
        <div className="d-flex ml-3 flex-column">
          <div className="d-flex">
            <div>{t("to", { ns: "common" })}</div>
            <Form.Check
              style={{
                width: "80px",
                marginLeft: "10px",
              }}
              type="switch"
              id="toDate-switch"
              // label=""
              checked={toEnabled}
              onChange={() =>
                dispatch(
                  setExamArchiveDateFilterEnabled({
                    fromEnabled: fromEnabled,
                    toEnabled: !toEnabled,
                  })
                )
              }
            />
          </div>
          <input
            style={{
              fontSize: "1.3rem",
              borderRadius: "6px",
            }}
            value={toDate}
            onChange={(e) => dispatch(setExamArchiveToDate(e.target.value))}
            type="date"
          />
        </div>
        <div className="d-flex ml-3 flex-column justify-content-center">
          <DropdownButton title={getSortTitle()} variant="secondary" size="lg">
            {SORT_OPTIONS.map((option) => (
              <Dropdown.Item
                className="p-1"
                key={option.value}
                onClick={() => {
                  dispatch(
                    setExamArchiveSort({
                      field: option.value,
                      direction:
                        sort?.field === option.value &&
                        sort?.direction === "asc"
                          ? "desc"
                          : "asc",
                    })
                  );
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "15px 60px",
                    padding: "5px",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  {sort?.field === option.value && sort?.direction === "asc" ? (
                    <i className="fas fa-chevron-up mr-1 fa-sm" />
                  ) : sort?.field === option.value &&
                    sort?.direction === "desc" ? (
                    <i className="fas fa-chevron-down mr-1 fa-sm" />
                  ) : (
                    <div></div>
                  )}
                  <div>{option.label}</div>
                </div>
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        <div className="d-flex ml-3 ml-auto mr-2" style={{ gap: "10px" }}>
          <Button
            size="lg"
            variant="danger"
            disabled={filter === null}
            style={{ borderRadius: "16px" }}
            onClick={() => dispatch(clearEamArchiveFilter())}
          >
            <i className="fas fa-times fa-sm" />
            <div>{t("buttons.clear", { ns: "common" })}</div>
          </Button>
          <Button
            onClick={() => dispatch(setExamArchiveFilter())}
            size="lg"
            variant="dark"
            style={{ borderRadius: "16px" }}
          >
            <i className="fas fa-search fa-sm" />
            <div>{t("search", { ns: "common" })}</div>
          </Button>
        </div>
      </div>
    </form>
  );
}

function PaginationControlls(props) {
  return (
    <div
      className="d-flex justify-content-center mt-auto rounded my-1 py-1"
      style={{
        gap: "5px",
        backgroundColor: "#3D3D3D",
        color: "#fff",
      }}
    >
      <Button
        onClick={() => {
          props.setCurrentPage(1);
        }}
        disabled={props.currentPage === 1}
        variant="dark"
        style={{ borderRadius: "16px" }}
      >
        <i className="fas fa-chevron-left fa-sm" />
        <i className="fas fa-chevron-left fa-sm" />
      </Button>
      <Button
        onClick={() => {
          props.setCurrentPage(props.currentPage - 1);
        }}
        disabled={props.currentPage === 1}
        variant="dark"
        style={{ borderRadius: "16px" }}
      >
        <i className="fas fa-chevron-left fa-sm" />
      </Button>
      <div className="border rounded d-flex align-items-center justify-content-center px-3">
        {props.currentPage}/
        {Math.ceil(props.ExamsQuery?.data?.total_count / props.pageSize)}
      </div>
      <Button
        onClick={() => {
          props.setCurrentPage(props.currentPage + 1);
        }}
        disabled={
          props.currentPage ===
          Math.ceil(props.ExamsQuery?.data?.total_count / props.pageSize)
        }
        variant="dark"
        style={{ borderRadius: "16px" }}
      >
        <i className="fas fa-chevron-right fa-sm" />
      </Button>
      <Button
        disabled={
          props.currentPage ===
          Math.ceil(props.ExamsQuery?.data?.total_count / props.pageSize)
        }
        onClick={() => {
          props.setCurrentPage(
            Math.ceil(props.ExamsQuery?.data?.total_count / props.pageSize)
          );
        }}
        variant="dark"
        style={{ borderRadius: "16px" }}
      >
        <i className="fas fa-chevron-right fa-sm" />
        <i className="fas fa-chevron-right fa-sm" />
      </Button>
    </div>
  );
}

function ExamArchive() {
  const pageSize = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const { filter, sort } = useSelector((state) => state.examArchive);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter, sort]);

  const ExamsQuery = useStudies({
    pageSize,
    pageNr: currentPage,
    keepPreviousData: false,
    sort: sort,
    filter: filter,
    xmask:
      "total_count,studies{id,patient,dapatid,patientsname,accessionnumber,patientssize,patientsweight,studydate,modality,studydescription,studyinstanceuid,myg,avgmyg,flag}",
  });

  useEffect(() => {
    dispatch(clearSelectedExaminations());
  }, []);

  return (
    <div
      className="px-2 py-2 d-flex flex-column"
      style={{ height: "100vh", backgroundColor: "#292929" }}
    >
      <ExamArchiveFiltering />
      <div
        className="d-flex flex-column bg-red rounded my-1 p-1"
        style={{
          height: "80vh",
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: "#3D3D3D",
        }}
      >
        <StudiesList
          status={ExamsQuery.status}
          studies={ExamsQuery?.data?.studies}
        />
      </div>
      <PaginationControlls
        pageSize={pageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        ExamsQuery={ExamsQuery}
      />
    </div>
  );
}

export default ExamArchive;
