import CR from "./CR.png";
import CT from "./CT.png";
import DX from "./DX.png";
import MG from "./MG.png";
import MR from "./MR.png";
import US from "./US.png";
import Xray from "./Xray.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default { CR, CT, DX, MG, MR, US, Xray };
