import moment from "moment";
import { Card } from "react-bootstrap";

function StudyCard({ id, studydescription, date, patient }) {
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <strong>Description:</strong> {studydescription}
        </Card.Title>
        <Card.Text>
          <strong>Date:</strong> {moment(date).format("DD-MM-YYYY HH:mm")}
        </Card.Text>
        <Card.Text>
          <strong>Patient Name:</strong> {patient.patientsname}{" "}
          {patient.patientsvorname}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default StudyCard;
