import { EXAM_QA_SINGLE } from "../../../../Pages/Consts/ExamHelperRoutes";

import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import ExamsSection from "../ExamsSection";
import SingleQaSection from "./ExamQa/SingleQaSection";
import useActiveExam from "../../Hooks/useActiveExam";

function ExamHelperApp(props) {
  const { activeExam, setExam } = useActiveExam({
    link: EXAM_QA_SINGLE.link,
  });

  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection selectedExam={activeExam} selectedExamSetter={setExam} />
      }
      rightComponent={<SingleQaSection selectedExamId={activeExam} />}
    />
  );
}

export default ExamHelperApp;
