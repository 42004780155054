import styles from "./editpat.module.css";
import CSSModules from "react-css-modules";

import { REST_URL } from "../../Utilities/FetchHooks/Helpers/FetchWrapper";

import { Modal, Button, Form, Col, Row, Container } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PeselDecode } from "../../Utilities/Patients/PatientUtils";
import { MakePutObject } from "../../Utilities/AuthHeader";
import { getDbDateString } from "../../Utilities/DateTimeUtils/DateTimeUtils";
import {
  setReloadPatientListFlag,
  setReloadPatientDataFlag,
} from "../../Redux/actions";
import AuthorizedFunction from "../../Utilities/AuthorizedFunction";
import isAutherized from "../../Utilities/isAuthorized";
import { useContext } from "react";
import { UserPreferencesContext } from "../../Providers/UserPreferencesProvider";

function EditPatientModal({ patient = {}, onHide, show }) {
  const { userPreferences } = useContext(UserPreferencesContext);
  const { keycloak } = useKeycloak();
  const { t } = useTranslation(["addpatientform", "common"]);
  const dispatch = useDispatch();

  const [detailsShown, SetDetailsShown] = useState(false);

  const {
    id,
    patientid,
    patientsvorname,
    patientsname,
    gebdat,
    patientssex,
    strasse,
    ort,
    plz,
  } = patient;

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      patientid: patientid || "",
      patientsvorname: patientsvorname || "",
      patientsname: patientsname || "",
      gebdat: gebdat || "",
      patientssex: patientssex || "",
      strasse: strasse || "",
      ort: ort || "",
      plz: plz || "",
      //unused missing fields in model
      phone_nr: "",
      mail: "",
    },
  });

  const handleFormSubmit = async (formData) => {
    if (!isAutherized(keycloak, ["pm_admin", "pm_user"])) {
      alert("not allowed");
      return;
    }

    if (Object.keys(errors).length > 0) {
      if (
        window.conf.PATIENT_JOURNAL.BLOCK_WRONG_PAT_ID_INPUT &&
        errors.patientid !== undefined
      ) {
        return;
      }
    }
    //delete unused fields to not confuse api
    delete formData.phone_nr;
    delete formData.mail;
    formData.gebdat = getDbDateString(formData.gebdat);
    let res = await fetch(
      `${REST_URL}/ris/patients/${id}`,
      MakePutObject(keycloak, formData)
    );

    if (res.status === 200) {
      dispatch(setReloadPatientListFlag(true));
      dispatch(setReloadPatientDataFlag(true));
      NotificationManager.success(t("notification.editSuccess"));
      hideWrapper();
    } else {
      NotificationManager.error(t("notification.editError"));
      let data = await res.json();
      console.warn("Patient modification failed > ", data);
    }
  };

  const idResolver = (e) => {
    let curVal = e.target.value;
    switch (
      userPreferences.LANG //if needed additional id resolvers can be implemented here
    ) {
      case "PL":
        let decoded = PeselDecode(curVal);
        if (decoded.valid) {
          clearErrors("patientid");
          setValue("patientssex", decoded.sex);
          setValue("gebdat", decoded.formatedDate);
        } else {
          setError("patientid");
        }
        break;
      case "DE":
        break;
      default:
        return;
    }
  };

  const validateId = (idValue) => {
    if (window.conf.PATIENT_JOURNAL.BLOCK_WRONG_PAT_ID_INPUT) {
      switch (window.conf.LANG) {
        case "PL":
          let decoded = PeselDecode(idValue);
          return decoded.valid;
        case "DE":
          return true;
        default:
          return true;
      }
    } else {
      return true;
    }
  };

  const getStyle = () => (detailsShown ? "shown" : "hidden");

  const hideWrapper = () => {
    // prompt unsaved data
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={hideWrapper}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="add-patient-modal"
    >
      <Modal.Header className="py-2" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("header.editPatient")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridID">
              <Form.Label>{t("patBasics.idLabel")}</Form.Label>
              <Form.Control
                {...register("patientid", {
                  required: true,
                  validate: (value) => validateId(value) === true,
                })}
                type="text"
                className={`${errors.patientid ? "is-invalid" : ""}`}
                onChange={idResolver}
                placeholder={t("patBasics.idPlaceHold")}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridFName">
              <Form.Label>{t("patBasics.nameLabel")}</Form.Label>
              <Form.Control
                {...register("patientsvorname", {
                  required: true,
                })}
                type="text"
                className={`${errors.patientsvorname ? "is-invalid" : ""}`}
                placeholder={t("patBasics.namePlaceHold")}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formLName">
              <Form.Label>{t("patBasics.surnameLabel")}</Form.Label>
              <Form.Control
                {...register("patientsname", {
                  required: true,
                })}
                type="text"
                className={`${errors.patientsname ? "is-invalid" : ""}`}
                placeholder={t("patBasics.surnamePlaceHold")}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridDateBirth">
              <Form.Label>{t("patBasics.birthLabel")}</Form.Label>
              <Form.Control
                {...register("gebdat", {
                  required: true,
                })}
                type="date"
                className={`${errors.gebdat ? "is-invalid" : ""}`}
                placeholder={t("patBasics.birthPlaceHold")}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridSex">
              <Form.Label>{t("patBasics.sexLabel")}</Form.Label>
              <Form.Control {...register("patientssex")} as="select">
                <option value="M">{t("patBasics.valueM")}</option>
                <option value="F">{t("patBasics.valueF")}</option>
                <option value="O">{t("patBasics.valueO")}</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              ld={12}
              className="text-center border my-2"
              styleName="details-btn"
              onClick={() => SetDetailsShown(!detailsShown)}
            >
              <p className="mx-0 px-0 my-0 py-0">
                {t("button.details")}{" "}
                <span
                  className={`fas mx-0 px-0 my-0 py-0 ${
                    detailsShown ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                />
              </p>
            </Col>
          </Row>
          <Container
            className={styles.details + " mx-0 px-0"}
            styleName={getStyle()}
            fluid
          >
            <Form.Row>
              <Form.Group as={Col} md="8" controlId="formStreet">
                <Form.Label>{t("patDetails.streetLabel")}</Form.Label>
                <Form.Control
                  {...register("strasse")}
                  type="text"
                  placeholder={t("patDetails.streetPlaceHold")}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="8" controlId="formCity">
                <Form.Label>{t("patDetails.cityLabel")}</Form.Label>
                <Form.Control
                  {...register("ort")}
                  type="text"
                  placeholder={t("patDetails.cityPlaceHold")}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formZip">
                <Form.Label>{t("patDetails.zipLabel")}</Form.Label>
                <Form.Control
                  {...register("plz")}
                  type="text"
                  placeholder={t("patDetails.zipPlaceHold")}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formPhone">
                <Form.Label>{t("patDetails.phoneLabel")}</Form.Label>
                <Form.Control
                  {...register("phone_nr")}
                  type="text"
                  placeholder={t("patDetails.phonePlaceHold")}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formMail">
                <Form.Label>{t("patDetails.mailLabel")}</Form.Label>
                <Form.Control
                  {...register("mail")}
                  type="text"
                  placeholder={t("patDetails.mailPlaceHold")}
                />
              </Form.Group>
            </Form.Row>
          </Container>
          <div className="d-flex flex-row justify-content-between">
            <Button variant="secondary" onClick={hideWrapper}>
              {t("common:buttons.cancel")}
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!AuthorizedFunction(["pm_admin", "pm_user"])}
            >
              {t("common:buttons.save")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
export default CSSModules(EditPatientModal, styles);
