import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setWebViewerAvailable } from "./Redux/actions";
import { useKeycloak } from "@react-keycloak/web";
import AuthScreen from "./Components/ui/authScreen/AuthScreen";
import PrivateRoute from "./Utilities/PrivateRoute";
import IntegrationFetchAndRedirect from "./Pages/IntegrationFetchAndRedirect";
import { Container } from "react-bootstrap";
import { NotificationContainer } from "react-notifications";
import useWindowSize from "./Utilities/Window/windowSize";
import "react-notifications/lib/notifications.css";

import Welcome from "./Pages/LandingPage";
import Menu from "./Components/Menu";
import "./RisNet.css";
import "./RisNet.scss";

import { defaultRoutes, reportsOnlyRoutes } from "./Consts/routes";
import useUserPreferences from "./Utilities/Hooks/useUserPreferences";
import UserPreferencesProvider from "./Providers/UserPreferencesProvider";

function TopNavMobile(props) {
  return (
    <div
      className={`top-nav-mobile d-flex align-items-center w-100 py-2 px-2`}
      style={{
        backgroundColor: "rgb(230, 230, 230)",
      }}
    >
      <div
        onClick={() => props.setNavToggled(!props.navToggled)}
        className={`nav-toggle-btn p-1 rounded`}
      >
        <i className="fas fa-bars fa-lg" />
      </div>
      <div
        className="ml-auto d-flex"
        style={{
          gap: "5px",
        }}
      >
        <div>
          <i className="fas fa-user" />
        </div>
        <div className="text-muted small">{props.name}</div>
      </div>
    </div>
  );
}

function isInstalled() {
  let result = false;
  // For iOS
  if (window.navigator.standalone) result = true;

  // For Android
  if (window.matchMedia("(display-mode: standalone)").matches) result = true;

  // If neither is true, it's not installed
  console.log(`App is running as PWA: ${result}`);
  return result;
}

function RisNet() {
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (initialized) {
      keycloak
        .updateToken(5)
        .then(function (refreshed) {
          if (refreshed) {
            console.success("Token was successfully refreshed");
          } else {
            console.log("Token is still valid");
          }
        })
        .catch(function () {
          console.warn(
            "Failed to refresh the token, or the session has expired"
          );
        });
    }
  }, [keycloak, initialized]);

  const SuspenseComponent = () => (
    <div className="d-flex flex-column" style={{ height: "100vh" }}></div>
  );
  const dispatch = useDispatch();
  dispatch(
    setWebViewerAvailable(
      window.conf.VIEWER_URL !== undefined && window.conf.VIEWER_URL !== ""
    )
  );

  const [navToggled, setNavToggled] = useState(true);
  const { width } = useWindowSize();

  const routes =
    process.env.REACT_APP_BUILD_TYPE === "reports"
      ? reportsOnlyRoutes
      : defaultRoutes;

  return (
    <>
      {keycloak.authenticated ? (
        <Router basename={window.conf.ROUTER_BASE_URL}>
          <UserPreferencesProvider>
            <Suspense fallback={<SuspenseComponent />}>
              <Menu navToggled={navToggled} setToggled={setNavToggled} />
              <div className="app-content">
                {width <= 1199 && (
                  <TopNavMobile
                    name={keycloak.tokenParsed.name}
                    navToggled={navToggled}
                    setNavToggled={setNavToggled}
                  />
                )}
                <Container fluid className="p-0">
                  {window.conf.INTEGRATION && isInstalled() && (
                    <IntegrationFetchAndRedirect />
                  )}
                  <NotificationContainer />
                  <StartRoute keycloak={keycloak} />
                  <Route exact path="/WelcomeScreen" component={Welcome} />
                  {routes.map((route, index) => (
                    <PrivateRoute
                      key={`${route.name}${index}`}
                      roles={route.roles}
                      path={route.path}
                      component={route.component}
                    />
                  ))}
                </Container>
              </div>
            </Suspense>
          </UserPreferencesProvider>
        </Router>
      ) : (
        <AuthScreen />
      )}
    </>
  );
}

export default RisNet;

function StartRoute({ keycloak }) {
  const { userPreferences } = useUserPreferences();
  const target = userPreferences?.STARTING_PAGE;
  return (
    <Route
      exact
      path="/"
      render={() => {
        return keycloak.authenticated ? (
          <Redirect to={target} />
        ) : (
          <Redirect to="/WelcomeScreen" />
        );
      }}
    />
  );
}
