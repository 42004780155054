import style from "./style.scss";

function RechtesOberesSprunggelenkLatLiegend1460(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 378.69 486.24"
    >
      <g id="upper_ankle" data-name="upper ankle">
        <g id="background_layer" data-name="background layer">
          <g id="background">
            <path
              id="background_1"
              data-name="background 1"
              class="oberes_sprunggelenk_lat_rechts_cls4"
              d="M25.42,277.14h0c-3.4.5-5.9,3.4-5.7,6.8-.9,27.2,10.3,50.9,38,69.5,3.4,2.3,7.6,3.1,11.6,2.3l46.7-9c6.7-13.6,15.9-25.9,29.5-35.8,10.8,7.9,15.1,2.1,11.9-19.3,12.6-15.5,30.2-19.4,56.2-3.4,11.8,0,15.1-4.6,10.2-13.6,4.7-10.9,1.7-22.7-3.4-34.7-9.3-24.7-34.8-35.1-68.2-38.6-28.1.3-49.8,15.7-67.8,39.6-2,2.7-3.2,5.9-3.1,9.3.7,15.7-25.4,22.1-55.9,26.9h0Z"
            />
            <path
              id="background_2"
              data-name="background 2"
              class="oberes_sprunggelenk_lat_rechts_cls4"
              d="M192.12,5.74c.6,79.9,3.2,154.7,11.9,214.2-1.1,21.6-8.6,41-34.7,54-6.9,10.7-13.2,10.4-18.8.6-7.5-1.4-14.9-10.1-22.2-23.3-15.1-24.3-14.7-46.1-1.7-65.9,16.8-28.1,20.5-98.8,19.9-183.5"
            />
            <path
              id="background3"
              class="oberes_sprunggelenk_lat_rechts_cls4"
              d="M22.02,376.34c30-7.6,49.1-8.5,55.1-1.1-.2,16.8,2.9,31.3,11.4,42,11.4,12.3,10.2,26-1.1,40.9-19.8,8.1-40.8,6-62.5-2.3"
            />
            <path
              id="background_4"
              data-name="background 4"
              class="oberes_sprunggelenk_lat_rechts_cls4"
              d="M1.12,293.94c3.6-3.6,7.8-5.4,13.6-3.4-1.5,30.9,13.9,55.2,54,69.9,3.3,5,3.1,9.6-4,13.6-19.6,16.8-38.3,19.3-57.4,2.8"
            />
            <path
              id="background_5"
              data-name="background 5"
              class="oberes_sprunggelenk_lat_rechts_cls4"
              d="M3.82,451.34c25-13.8,43.3-28.1,39.8-43.8,4.5-9.8.6-14-8.5-14.8-15,1.9-26.4-2.2-34.1-12.5"
            />
            <path
              id="background_6"
              data-name="background 6"
              class="oberes_sprunggelenk_lat_rechts_cls4"
              d="M.02,485.34c13.3-.2,25.9-2.3,36.9-9.7,16-8.5,21.9-17.9,17-28.4,1.2-11.8-5.7-16.6-21.6-13.6-6.1-3.7-13.6-4.7-21.6-4.5"
            />
          </g>
        </g>
        <g id="upper_ankle_joint" data-name="upper ankle joint">
          <g id="heel">
            <path
              id="heel_1"
              data-name="heel 1"
              class="oberes_sprunggelenk_lat_rechts_cls5"
              d="M351.62,290.94h0c-9.64,27.79,18.67,53.72,3.97,68.37-2.45,2.45-3.41,5.99-3.98,9.41-7.46,45.17-30.23,67.71-62.42,73.87-5.28,1.01-10.74-.32-15.03-3.56-12.94-9.79-26.77-16.13-41.35-19.69-10.8-4.8-24.3-1.5-38.6,4-19.5,4.4-38.8,5.3-57.9,4-10.9-14.2-21.2-19-30.7-11.9-14.5,3.4-22-8.5-22.7-35.2,4-11.5-.2-21-9.7-29-19.4-13.1-15.1-20,9.1-21.6,6.8-6,19-4.8,37.5,4.5,17,3.5,30.5-13.4,43.2-35.2,24-26.6,55.6-38.8,100-26.7,21.2,7.3,41.5,8.1,60.2-.6,13-12,26.26-4.03,28.4,19.3h0Z"
            />
            <path
              id="heel_2"
              data-name="heel 2"
              class="oberes_sprunggelenk_lat_rechts_cls5"
              d="M221.42,416.44c12.1-2.8,24.8-2.9,38.1-1.1"
            />
          </g>
          <g id="tarsus">
            <path
              id="tarsus_1"
              data-name="tarsus 1"
              class="oberes_sprunggelenk_lat_rechts_cls6"
              d="M25.42,277.04h0c-3.4.5-5.9,3.4-5.7,6.8-.9,27.2,10.3,50.9,38,69.5,3.4,2.3,7.6,3.1,11.6,2.3l46.7-9c6.7-13.6,15.9-25.9,29.5-35.8,10.8,7.9,15.1,2.1,11.9-19.3,12.6-15.5,30.2-19.4,56.2-3.4,11.8,0,15.1-4.6,10.2-13.6,4.7-10.9,1.7-22.7-3.4-34.7-9.3-24.7-34.8-35.1-68.2-38.6-28.1.3-49.8,15.7-67.8,39.6-2,2.7-3.2,5.9-3.1,9.3.7,15.7-25.4,22.1-55.9,26.9h0Z"
            />
            <path
              id="tarsus_2"
              data-name="tarsus 2"
              class="oberes_sprunggelenk_lat_rechts_cls6"
              d="M22.02,376.24c30-7.6,49.1-8.5,55.1-1.1-.2,16.8,2.9,31.3,11.4,42,11.4,12.3,10.2,26-1.1,40.9-19.8,8.1-40.8,6-62.5-2.3"
            />
            <path
              id="tarsus_3"
              data-name="tarsus 3"
              class="oberes_sprunggelenk_lat_rechts_cls6"
              d="M1.12,293.84c3.6-3.6,7.8-5.4,13.6-3.4-1.5,30.9,13.9,55.2,54,69.9,3.3,5,3.1,9.6-4,13.6-19.6,16.8-38.3,19.3-57.4,2.8"
            />
            <path
              id="tarsus_4"
              data-name="tarsus 4"
              class="oberes_sprunggelenk_lat_rechts_cls6"
              d="M3.82,451.24c25-13.8,43.3-28.1,39.8-43.8,4.5-9.8.6-14-8.5-14.8-15,1.9-26.4-2.2-34.1-12.5"
            />
            <path
              id="tarsus_5"
              data-name="tarsus 5"
              class="oberes_sprunggelenk_lat_rechts_cls6"
              d="M.02,485.24c13.3-.2,25.9-2.3,36.9-9.7,16-8.5,21.9-17.9,17-28.4,1.2-11.8-5.7-16.6-21.6-13.6-6.1-3.7-13.6-4.7-21.6-4.5"
            />
            <path
              id="tarsus_6"
              data-name="tarsus 6"
              class="oberes_sprunggelenk_lat_rechts_cls6"
              d="M145.32,310.84c-15.2-5.9-29.1-14.4-42-25"
            />
          </g>
          <path
            id="shin"
            class="oberes_sprunggelenk_lat_rechts_cls5"
            d="M181.21.64c1.6,30.4,8.2,63.5,17.6,98.3,8.8,21.7,17.4,45.9,25.6,74.4-.3,24.1-5.3,39-19.9,35.8-30.2-16.6-61.9-18.3-94.9-4.6-4.8,5.2-7.2,1.9-8.5-5.7-30.2-21.6-30-44.4-20.5-67.6C95.42,89.74,95.92,45.14,94.22.04"
          />
          <path
            id="fibula"
            class="oberes_sprunggelenk_lat_rechts_cls6"
            d="M192.12,5.64c.6,79.9,3.2,154.7,11.9,214.2-1.1,21.6-8.6,41-34.7,54-6.9,10.7-13.2,10.4-18.8.6-7.5-1.4-14.9-10.1-22.2-23.3-15.1-24.3-14.7-46.1-1.7-65.9,16.8-28.1,20.5-98.8,19.9-183.5"
          />
          <path
            id="outline"
            class="oberes_sprunggelenk_lat_rechts_cls7"
            d="M180.32,449.04c28.6,12,58.4,19,90.3,18.3,19.2-.4,37.9-6.4,53.5-17.6,31.9-22.8,51.8-56.3,54.2-105.3-1.2-39.7-9.6-70-25-91.5-2.9-4.1-5.7-8.1-8.2-12.4-26.7-45.8-46.4-114-64.8-186.4"
          />
        </g>
      </g>
      <g id="overlays">
        <path
          id="shin_overlay"
          data-name="shin overlay"
          class="tibia1460Fill"
          style={{
            fill: props.colors.tibia1460Fill,
            stroke: props.colors.tibia1460Fill,
            opacity: props.colors.tibia1460Fill,
          }}
          d="M181.21.64c1.6,30.4,8.2,63.5,17.6,98.3,8.8,21.7,17.4,45.9,25.6,74.4-.3,24.1-5.3,39-19.9,35.8-30.2-16.6-61.9-18.3-94.9-4.6-4.8,5.2-7.2,1.9-8.5-5.7-30.2-21.6-30-44.4-20.5-67.6C95.42,89.74,95.92,45.14,94.22.04"
        />
        <path
          id="fibula_overlay"
          data-name="fibula overlay"
          class="fibula1460Fill"
          style={{
            fill: props.colors.fibula1460Fill,
            stroke: props.colors.fibula1460Fill,
            opacity: props.colors.fibula1460Fill,
          }}
          d="M192.12,5.64c.6,79.9,3.2,154.7,11.9,214.2-1.1,21.6-8.6,41-34.7,54-6.9,10.7-13.2,10.4-18.8.6-7.5-1.4-14.9-10.1-22.2-23.3-15.1-24.3-14.7-46.1-1.7-65.9,16.8-28.1,20.5-98.8,19.9-183.5"
        />
        <path
          class="Talusrolle1460Fill"
          style={{
            fill: props.colors.Talusrolle1460Fill,
            stroke: props.colors.Talusrolle1460Fill,
            opacity: props.colors.Talusrolle1460Fill,
          }}
          d="M81.32,250.24c22.6-21.2,46.1-37.2,73-35.3,25.2-.2,46.7,9.6,66.1,25-1.5-5.8-4.5-11.1-9.7-15.8-13.6-14.2-34.4-20.4-58.5-22.8-26.1-1.6-48.7,15.6-69.6,42.6l-1.3,6.3Z"
        />
      </g>
    </svg>
  );
}

export default RechtesOberesSprunggelenkLatLiegend1460;
