import {
  getPatients,
  getPatient,
  getPatientDoses,
  getPatientStudies,
  getPatientWorklist,
  getPatientWebAccessCredentials,
} from "../Helpers/FetchFunctions/ris/PatientFetches";
import usePmedQuery from "../Helpers/UsePmedQuery";

export function usePatients({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["patients", pageNr, pageSize, filter, sort, xmask],
    getPatients,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function usePatient({
  patientId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["patient", patientId, xmask],
    getPatient,
    {
      patientId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function usePatientDoses({
  patientId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["patientDoses", patientId, xmask],
    getPatientDoses,
    {
      patientId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function usePatientStudies({
  patientId,
  xmask = undefined,
  sort = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["patientStudies", patientId, xmask],
    getPatientStudies,
    {
      patientId,
      xmask,
      sort,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function usePatientWorklist({
  patientId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["patientWorklist", patientId, xmask],
    getPatientWorklist,
    {
      patientId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function usePatientWebAccessCredentials({
  patientId,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["patientwebaccess", patientId],
    getPatientWebAccessCredentials,
    {
      patientId,
    },
    keepPreviousData,
    enabled
  );
  return query;
}
