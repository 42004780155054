import "./LayingCranial.scss";

export default function LayingCranial({ angle }) {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 283.5 283.5"
    >
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          data-name="Unbenannter Verlauf"
          x1="705.8"
          y1="-641.4"
          x2="668.6"
          y2="-641.4"
          gradientTransform="translate(784.3 -446.9) rotate(90) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".3" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_2"
          data-name="Unbenannter Verlauf 2"
          x1="869.8"
          y1="523.7"
          x2="868.5"
          y2="523.7"
          gradientTransform="translate(1009 648.5) rotate(-180)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".3" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_3"
          data-name="Unbenannter Verlauf 3"
          x1="955.5"
          y1="-880"
          x2="996.1"
          y2="-880"
          gradientTransform="translate(-447.1 1236.3) rotate(-20.7)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_4"
          data-name="Unbenannter Verlauf 4"
          x1="948.5"
          y1="-917"
          x2="996.5"
          y2="-917"
          gradientTransform="translate(-447 1236.3) rotate(-20.7)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
      </defs>
      <g id="Ebene_11" data-name="Ebene_1">
        <g id="Ebene_1-2">
          <g id="Ebene_1-21" data-name="Ebene_1-2">
            <g id="a">
              <rect
                class="layingcranial3"
                x="1.8"
                y=".2"
                width="283.5"
                height="283.5"
              />
              <rect
                class="layingcranial4"
                x="9.8"
                y="255"
                width="265.5"
                height="16.7"
              />
              <g>
                <path
                  class="layingcranial5"
                  d="M222.4,256.7c1.3-.3,11.4-4.9,14.6-9.1,7.7-1.1,16.1,5.7,22.2,5.6,10.3-.9,12.2-3.5,12.5-13.2-1.1-12-11.6-15.3-12.5-14.8-1.4.8-2.7.8-3.5.4-2.7-1.6-3.7-3.5-6.3-3.1l-.2,2.7c-2.2-.8-3.7-.5-4.2-.2-.2.7-.8.5-1.1.9-1.4.1-2,.2-3.7.3-.6,0-1.5,1.8-.4,6,.4,0-2.2,5.3-14.3,3.8-22.4-16.7-28.8-9-28.8-9l-64.4,3.1c-11.7-1.1-39.9,5.7-48.3,7.4-17.9,3.9-42.9,4.2-56.2,4.2-11.5-4-9.8-13.1-12.2-19.2-2.9-3.1-1,30.1-1.5,27.3,1,2.9,7.1-.4,15.2-.3,11,0,22.1,4.2,33,4.6,8.9,0,16.5-.3,21.2-2,10.9,2.5,22.9,2.7,29.6,2.2,3.6.3,8.3-.4,14.8-2.5,7.5,3.2,11.8,5,16.5,6.1,8.9,1.6,13.7-6.4,25.4-6.4,5.6-.9,21.7,5.3,37.6,6.4l15.1-1.3h0Z"
                />
                <line
                  class="layingcranial6"
                  x1="140.7"
                  y1="35.9"
                  x2="140.7"
                  y2="39.6"
                />
                <line
                  class="layingcranial6"
                  x1="140.7"
                  y1="206.3"
                  x2="140.7"
                  y2="210.1"
                />
              </g>
              <g>
                <line
                  class="layingcranial0"
                  x1="139.8"
                  y1="202.7"
                  x2="139.8"
                  y2="46.9"
                />
                <line
                  class="layingcranial6"
                  x1="265.9"
                  y1="188.9"
                  x2="139.6"
                  y2="34.5"
                />
                <g>
                  <path
                    class="layingcranial6"
                    d="M257.7,192.4c-34.9,17-73.5,22.1-114.4,17.2"
                  />
                  <path
                    class="layingcranial7"
                    d="M264.5,188.9c-2.6,2.8-5.6,6.9-6.9,10.3l-.8-6.3-4.7-4.4c3.6.9,8.7.8,12.5.4h0Z"
                  />
                </g>
                <g>
                  <polygon
                    class="layingcranial1"
                    points="156.9 52.1 140.6 57.8 140.6 88.3 178.8 74.8 156.9 52.1"
                  />
                  <path
                    class="layingcranial2"
                    d="M162.3,30.7c2.6,12.4-6,24.3-19.2,26.6-13.2,2.3-25.9-5.9-28.4-18.3-2.6-12.4,6-24.3,19.2-26.6,13.2-2.3,25.9,5.9,28.4,18.3h0Z"
                  />
                </g>
              </g>
            </g>
            <g class="layingcranial8">
              <g class="layingcranial8">
                <g class="layingcranial8">
                  <text
                    class="layingcranial9"
                    transform="translate(151.1 193.7) scale(1 1)"
                  >
                    <tspan x="0" y="0">
                      {angle}°
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
