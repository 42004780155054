import image11 from "./patprep_men_11xx.svg";
import image12 from "./patprep_men_12xx.svg";
import image13 from "./patprep_men_13xx.svg";
import image14 from "./patprep_men_14xx.svg";
import image15 from "./patprep_men_15xx.svg";
import image17 from "./patprep_men_17xx.svg";
import image18 from "./patprep_men_18xx.svg";
import image20 from "./patprep_men_20xx.svg";
import image21 from "./patprep_men_21xx.svg";
import image23 from "./patprep_men_23xx.svg";
import image24 from "./patprep_men_24xx.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  image11,
  image12,
  image13,
  image14,
  image15,
  image17,
  image18,
  image20,
  image21,
  image23,
  image24,
};
