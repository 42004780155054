import { Modal, Row, Col, Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { MakeGetObject } from "../../Utilities/AuthHeader";
import { useKeycloak } from "@react-keycloak/web";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";

export default function ExamWorklistInfo({
  std_id,
  shown,
  onHide,
  headerless = false,
}) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const { keycloak } = useKeycloak();

  const loadData = () => {
    setLoading(true);
    fetch(
      `${window.conf.SERVER_CONFIG.BASE_URL}/ris/studies/${std_id}/worklist/info`,
      MakeGetObject(keycloak)
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setData(undefined);
        }
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
        console.warn(err);
      });
  };

  useEffect(() => {
    shown && loadData();
  }, [shown]);

  const getIcon = (value) => {
    value = parseInt(value);
    if (value === 10) {
      return <i title="Finished" className="fas fa-check fa-lg"></i>;
    } else if (value === 0) {
      return <i title="Waiting" className="far fa-hourglass fa-lg"></i>;
    } else {
      return <i title="Rejected" className="fas fa-exclamation fa-lg"></i>;
    }
  };

  const { t } = useTranslation(["modals", "common"]);

  return (
    <Modal show={shown} onHide={onHide} size="lg">
      {!headerless && (
        <Modal.Header className="bg-light p-2" closeButton>
          <h5>{t("examStatus.examStatus")}</h5>
        </Modal.Header>
      )}
      <Modal.Body>
        {loading ? (
          <ClipLoader color="#a1a1a1" loading={true} css={true} size={30} />
        ) : (
          <Container>
            {data !== undefined ? (
              <>
                <Row>
                  <Col
                    sm={{ span: 5 }}
                    className="border rounded text-center bg-light"
                  >
                    {t("examStatus.examOrderedBy") + " "}
                    <h5>
                      {data[0].worklist.vw_adressen.institutionsbezeichnung}
                    </h5>
                  </Col>

                  <Col
                    sm={{ span: 5, offset: 2 }}
                    className="border rounded text-center bg-light"
                  >
                    {t("refferingPhysicians", { ns: "common" }) + " "}
                    <h5>
                      {data[0].worklist.adressenref.vorname}{" "}
                      {data[0].worklist.adressenref.name}
                    </h5>
                  </Col>
                </Row>
                <div className="border-top my-3"></div>
                <Row className="my-2 px-2 py-2 border rounded bg-light">
                  <Col sm={6}>
                    <h5>{t("examStatus.examPerformed")} </h5>
                  </Col>
                  <Col sm={6}>{getIcon(data[0].worklist.untersuchung)}</Col>
                  <Col sm={6}>
                    <h5>{t("alerts.findingDone")}</h5>
                  </Col>
                  <Col sm={6}>{getIcon(data[0].worklist.befunden)}</Col>

                  <Col sm={6}>
                    <h5>{t("alerts.cdBurned")}</h5>
                  </Col>
                  <Col sm={6}>{getIcon(data[0].worklist.brennen)}</Col>

                  <Col sm={6}>
                    <h5>{t("alerts.foilPrinted")}</h5>
                  </Col>
                  <Col sm={6}>
                    {getIcon(data[0].worklist.untersfolienprint)}
                  </Col>
                </Row>
              </>
            ) : (
              <p>{t("alerts.unableLoadData")}</p>
            )}
          </Container>
        )}
      </Modal.Body>
    </Modal>
  );
}
