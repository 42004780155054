import "./SidHorizontal.scss";

function SidHorizontal({ distance }) {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 283.5 283.5"
    >
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          data-name="Unbenannter Verlauf"
          x1="297.9"
          y1="403.8"
          x2="337.1"
          y2="403.8"
          gradientTransform="translate(612.4 -173.8) rotate(90.1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#302f2e" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_2"
          data-name="Unbenannter Verlauf 2"
          x1="293.4"
          y1="367.3"
          x2="339.7"
          y2="367.3"
          gradientTransform="translate(613.7 -173.9) rotate(90.2)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#302f2e" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#000" />
        </linearGradient>
      </defs>
      <rect
        class="sidhor1"
        x="-.4"
        y=".5"
        width="283"
        height="283"
        transform="translate(-.2 .2) rotate(0)"
      />
      <line class="sidhor3" x1="21.2" y1="258" x2="21.7" y2="20.1" />
      <polygon
        class="sidhor5"
        points="30.4 140.8 60.6 125.9 54.9 137 76.6 137.1 76.6 145 55.7 144.9 60.6 156 30.4 140.8"
      />
      <g>
        <polygon
          class="sidhor0"
          points="193.5 162.6 193.6 123.4 222.5 134.6 222.4 151.3 193.5 162.6"
        />
        <circle class="sidhor2" cx="245.2" cy="141.3" r="23.2" />
        <line class="sidhor4" x1="193.6" y1="140.6" x2="161.3" y2="140.5" />
      </g>
      <g class="sidhor6">
        <g class="sidhor6">
          <g class="sidhor6">
            <g class="sidhor6">
              <text class="sidhor7" transform="translate(83.6 130.5)">
                <tspan x="0" y="0">
                  {distance}
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <g class="sidhor6">
          <g class="sidhor6">
            <g class="sidhor6">
              <text class="sidhor7" transform="translate(92.5 176.4)">
                <tspan x="0" y="0">
                  cm
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SidHorizontal;
