import { Button, Card, Container, Form } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";

import { availableThemes } from "../Components/ThemeSelector/ThemeSelector";
import { defaultRoutes, reportsOnlyRoutes } from "../Consts/routes";
import AuthorizedElement from "../Utilities/AuthorizedElement";
import { useContext } from "react";
import { UserPreferencesContext } from "@/Providers/UserPreferencesProvider";

function MenuItemsPicker({ menuItems, setMenuItems }) {
  return (
    <div className="p-3">
      <h5>Select menu items to show</h5>
      {Object.entries(menuItems).map(([key, props]) => (
        <div key={key}>
          <Form.Check
            type="checkbox"
            id={props.name}
            label={props.name}
            checked={props.visible}
            onChange={(e) => {
              setMenuItems(
                Object.fromEntries(
                  Object.entries(menuItems).map(([k, v]) => [
                    k,
                    k === key ? { ...v, visible: e.target.checked } : v,
                  ])
                )
              );
            }}
          />
        </div>
      ))}
    </div>
  );
}

function UserSettings() {
  const { keycloak } = useKeycloak();
  const { userPreferences, setUserPreferences } = useContext(
    UserPreferencesContext
  );

  const availableRoutes =
    process.env.REACT_APP_BUILD_TYPE === "reports"
      ? reportsOnlyRoutes
      : defaultRoutes;
  return (
    <Container className="mx-1">
      <Card className="mt-3" style={{ maxWidth: "50%" }}>
        <Card.Header className="py-2">
          <h5>User Preferences</h5>
        </Card.Header>
        <Card.Body className="d-flex flex-column" style={{ height: "80vh" }}>
          <div className="text-center mb-5">
            <i
              className="fa fa-user fa-3x  border rounded-circle d-flex align-items-center justify-content-center mx-auto"
              style={{
                backgroundColor: "#ededed",
                color: "#ff880d",
                width: "90px",
                height: "90px",
              }}
            ></i>
            <div className="font-weight-bold mt-1 text-uppercase">
              {keycloak.tokenParsed.preferred_username}
            </div>
            <div className="font-weight-bold mt-1">
              {keycloak.tokenParsed.name}
            </div>
            <div className="font-weight-bold">{keycloak.tokenParsed.email}</div>
            <div className="mt-3">
              <Button onClick={() => keycloak.logout()}>Logout</Button>
            </div>
          </div>
          <div className="d-flex flex-column mx-auto" style={{ width: "80%" }}>
            <Form>
              <AuthorizedElement roles={["pm_admin", "pm_user"]}>
                <Form.Row>
                  <Form.Label>Preffered Starting Page</Form.Label>
                  <Form.Control
                    as="select"
                    value={userPreferences.STARTING_PAGE}
                    onChange={(e) => {
                      const newPage = availableRoutes.find(
                        (route) => route.path === e.target.value
                      );
                      setUserPreferences({
                        ...userPreferences,
                        STARTING_PAGE: newPage.path,
                      });
                    }}
                  >
                    {availableRoutes.map(
                      (route) =>
                        route.isForListing && (
                          <option key={route.name} value={route.path}>
                            {`${route.name}  ("${route.path}")`}
                          </option>
                        )
                    )}
                  </Form.Control>
                </Form.Row>
              </AuthorizedElement>
              <Form.Row className="mt-2">
                <Form.Label>Preffered Viewer</Form.Label>
                <Form.Control
                  as="select"
                  value={userPreferences.VIEWER_TYPE}
                  onChange={(e) => {
                    setUserPreferences({
                      ...userPreferences,
                      VIEWER_TYPE: parseInt(e.target.value),
                    });
                  }}
                >
                  <option value={2}>Let me decide each time</option>
                  <option value={1}>pmView</option>
                  <option value={0}>WebViewer</option>
                </Form.Control>
              </Form.Row>
            </Form>
          </div>
          <div>
            <AuthorizedElement roles={["pm_admin", "pm_user"]}>
              <MenuItemsPicker
                menuItems={userPreferences.MENU}
                setMenuItems={(newMenuItems) =>
                  setUserPreferences({ ...userPreferences, MENU: newMenuItems })
                }
              />
            </AuthorizedElement>
          </div>
        </Card.Body>
        <Card.Footer className="d-flex py-1">
          <Button className="ml-auto">Save</Button>
        </Card.Footer>
      </Card>
    </Container>
  );
}

export default UserSettings;
