import ExamsSection from "../ExamsSection";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import styles from "./ExamGuide.module.scss";
import { useContext, useEffect, useMemo, useState } from "react";
import { EXAM_GUIDE_STEP_BY_STEP } from "../../../../Pages/Consts/ExamHelperRoutes";
import { useExamination } from "../../../../Utilities/FetchHooks/Ris/RisHooks";
import { ExamGuideData } from "./mocks/ExamGuide.mock";
import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import ExamHelperContentTab from "../../../../Blueprints/ExamHelper/ImageSwitchingTab/ImageSwitchingTab";
import useWindowSize from "../../../../Utilities/Window/windowSize";
import { ExamHelperContext } from "../../../../Pages/ExamHelper";
import useActiveExam from "../../Hooks/useActiveExam";
import { useTranslation } from "react-i18next";

function ExamGuideStepByStep({ selectedExam }) {
  const { t } = useTranslation(["examHelper", "common"]);
  const examsListContext = useContext(ExamHelperContext);
  const { data: examData } = useExamination({
    examId: selectedExam,
    enabled: selectedExam != null,
  });
  const data = selectedExam ? ExamGuideData[selectedExam] : undefined;
  const stepCount = data && data.steps.length;
  const [step, setStep] = useState(0);
  const stepData = data && data.steps[step];
  const [displayMode, setDisplayMode] = useState("drawing");
  const { width } = useWindowSize();

  useEffect(() => {
    setStep(0);
  }, [selectedExam]);

  const { singleHeight, multiHeight } = useMemo(() => {
    let sizing = { singleHeight: "600px", multiHeight: "300px" };
    if (width < 1200) {
      sizing = { singleHeight: "480px", multiHeight: "240px" };
    }
    if (width < 768) {
      sizing = { singleHeight: "220px", multiHeight: "110px" };
    }
    return sizing;
  }, [width]);

  const title = !selectedExam
    ? t("actions.selectExam", { ns: "common" })
    : examData
    ? examData.designationde
    : t("loading", { ns: "common" });

  const label = stepData ? stepData.label : "No data";
  const ModeSelector = () => (
    <ButtonGroup toggle style={{ position: "absolute", top: 0, right: 0 }}>
      <ToggleButton
        type="radio"
        variant="primary"
        name="radio"
        value={"image"}
        checked={displayMode === "image"}
        onChange={(e) => setDisplayMode(e.currentTarget.value)}
      >
        {t("positioning.img")}
      </ToggleButton>
      <ToggleButton
        type="radio"
        variant="primary"
        name="radio"
        value={"drawing"}
        checked={displayMode === "drawing"}
        onChange={(e) => setDisplayMode(e.currentTarget.value)}
      >
        {t("positioning.drw")}
      </ToggleButton>
    </ButtonGroup>
  );

  const TitleBar = ({ title }) => (
    <div className={styles.Heading}>
      <h3>{title}</h3>
      <ModeSelector />
    </div>
  );

  const leftButton = {
    disabled: step === 0,
    content: <i className="fas fa-chevron-left" />,
    onClick: () => setStep((prev) => prev - 1),
  };

  const rightButton = {
    disabled: step === stepCount - 1,
    content: <i className="fas fa-chevron-right" />,
    onClick: () => setStep((prev) => prev + 1),
  };

  const content = (
    <>
      {stepData !== undefined ? (
        <>
          {Array.isArray(stepData[displayMode]) ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
              }}
            >
              {stepData[displayMode].map((img, idx) => (
                <img
                  key={idx}
                  style={{ height: multiHeight, maxWidth: "32vw" }}
                  src={img}
                  alt="body position"
                />
              ))}
            </div>
          ) : (
            <img
              style={{
                height: singleHeight,
                maxWidth:
                  !examsListContext.examListShrinked && width > 768
                    ? "35vw"
                    : "70vw",
              }}
              src={stepData[displayMode]}
              alt="body position"
            />
          )}
        </>
      ) : (
        <div className="d-flex text-white w-100 justify-content-center align-items-center">
          <h5>{t("erros.noData", { ns: "common" })}</h5>
        </div>
      )}
    </>
  );

  return (
    <ExamHelperContentTab
      title={<TitleBar title={title} />}
      image={content}
      description={<h3>{label}</h3>}
      leftButton={leftButton}
      rightButton={rightButton}
    />
  );
}

export function ExamGuideSS(props) {
  const { activeExam, setExam } = useActiveExam({
    link: EXAM_GUIDE_STEP_BY_STEP.link,
  });
  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection selectedExam={activeExam} selectedExamSetter={setExam} />
      }
      rightComponent={<ExamGuideStepByStep selectedExam={activeExam} />}
    />
  );
}
