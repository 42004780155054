import MainImage from "../images/NoWords/proto/MainImage";
import FootView from "../images/NoWords/proto/FootView";
import TopView from "../images/NoWords/proto/TopView";
import RechteSchoulderFocus from "../images/NoWords/proto/rechte_schoulder_focus.svg";

export const VIEWS_MAP = {
  0: {
    MAIN_IMAGE: {
      label: "Main View",
      Image: MainImage,
    },
    FOOT_VIEW: {
      label: "Foot View",
      Image: FootView,
    },
    TOP_VIEW: {
      label: "Top View",
      Image: TopView,
    },
    RECHTE_SCHOULDER_FOCUS: {
      label: "Right Schoulder Focus",
      Image: RechteSchoulderFocus,
    },
  },
  1525: {
    MAIN_IMAGE: {
      label: "Main View",
      Image: FootView,
    },
    FOOT_VIEW: {
      label: "Foot View",
      Image: FootView,
    },
    TOP_VIEW: {
      label: "Top View",
      Image: TopView,
    },
    RECHTE_SCHOULDER_FOCUS: {
      label: "Right Schoulder Focus",
      Image: RechteSchoulderFocus,
    },
  },
};
