import PatientJournal from "../Pages/PatientJournal";
import { WebViewerStudy } from "../Pages/WebViewer";
import ExamWizard from "../Pages/ExamWizard";
import ReportGen from "../Components/ReportGen/ReportGen";
import ReportArchive from "../Components/ReportArchive/ReportArchive";
import SandBox from "../Pages/SandBox";
import PriceLists from "../Pages/PriceLists";
import ExamPlannerPage from "../Pages/ExamPlannerPage";
import ExaminationsStatistics from "../Pages/ExaminationsStatistics";
import AdminPage from "../Pages/AdminPage";
import ExamArchive from "../Pages/ExamArchive";
import UserSettings from "../Pages/UserSettings";
import ExamHelper from "../Pages/ExamHelper";
import ExamReferral from "../Pages/ExamReferral";
import ExamOrders from "../Pages/ExamOrders";

// import WorkInProgress from "../Components/WorkInProgress/WorkInProgress";

export const defaultRoutes = [
  {
    name: "Patient Journal",
    path: "/PatientJournal",
    roles: ["pm_admin", "pm_user", "pm_refphys", "pm_facility", "pm_patient"],
    component: PatientJournal,
    isForListing: true,
  },
  {
    name: "Exam Archive",
    path: "/ExamArchive",
    roles: ["pm_admin", "pm_user"],
    component: ExamArchive,
    isForListing: true,
  },
  {
    name: "User Settings",
    path: "/UserSettings",
    roles: ["pm_admin", "pm_user", "pm_refphys", "pm_facility", "pm_patient"],
    component: UserSettings,
    isForListing: true,
  },
  {
    name: "WebViewer Examination",
    path: "/viewer/:study_uid",
    roles: ["pm_admin", "pm_user", "pm_refphys", "pm_facility", "pm_patient"],
    component: WebViewerStudy,
    isForListing: false,
  },
  {
    name: "Exam Statistics",
    path: "/ExamStats",
    roles: ["pm_admin", "pm_user"],
    component: ExaminationsStatistics,
    isForListing: true,
  },
  {
    name: "Examination Wizard",
    path: "/ExamWizard",
    roles: ["pm_admin", "pm_user"],
    component: ExamWizard,
    isForListing: false,
  },
  {
    name: "Sand Box",
    path: "/SandBox",
    roles: ["pm_admin", "pm_user"],
    component: SandBox,
    isForListing: false,
  },
  {
    name: "Examination Planner",
    path: "/ExamPlanner",
    roles: ["pm_admin", "pm_user"],
    component: ExamPlannerPage,
    isForListing: true,
  },
  {
    name: "Report Generator",
    path: "/ReportGenerator",
    roles: ["pm_admin", "pm_user"],
    component: ReportGen,
    isForListing: true,
  },
  {
    name: "Report Archive",
    path: "/ReportArchive",
    roles: ["pm_admin", "pm_user"],
    component: ReportArchive,
    isForListing: true,
  },
  {
    name: "Price Lists",
    path: "/PriceLists",
    roles: ["pm_admin", "pm_user"],
    component: PriceLists,
    isForListing: true,
  },
  {
    name: "Admin Page",
    path: "/AdminPage",
    roles: ["pm_admin"],
    component: AdminPage,
    isForListing: true,
  },
  {
    name: "Exam Helper",
    path: "/ExamHelper",
    roles: ["pm_admin", "pm_user"],
    component: ExamHelper,
    isForListing: true,
  },
  {
    name: "Exam Referral",
    path: "/ExamReferral",
    roles: ["pm_admin", "pm_user"],
    component: ExamReferral,
    isForListing: true,
  },
  {
    name: "Exam Orders",
    path: "/ExamOrders",
    roles: ["pm_admin", "pm_user"],
    component: ExamOrders,
    isForListing: true,
  },
];

export const reportsOnlyRoutes = [
  {
    name: "Exam Statistics",
    path: "/ExamStats",
    roles: ["pm_admin", "pm_user"],
    component: ExaminationsStatistics,
    isForListing: true,
  },
  {
    name: "User Settings",
    path: "/UserSettings",
    roles: ["pm_admin", "pm_user", "pm_refphys", "pm_facility", "pm_patient"],
    component: UserSettings,
    isForListing: true,
  },
  {
    name: "Report Generator",
    path: "/ReportGenerator",
    roles: ["pm_admin", "pm_user"],
    component: ReportGen,
    isForListing: true,
  },
  {
    name: "Report Archive",
    path: "/ReportArchive",
    roles: ["pm_admin", "pm_user"],
    component: ReportArchive,
    isForListing: true,
  },
  {
    name: "Price Lists",
    path: "/PriceLists",
    roles: ["pm_admin", "pm_user"],
    component: PriceLists,
    isForListing: true,
  },
];
