import { Spinner } from "react-bootstrap";
import SidHorizontal from "./SidHorizontal";
import SidVertical from "./SidVertical";

function TubeDistance({ examData }) {
  if (!examData || !examData.sid_from) return <Spinner animation="border" />;
  if (examData.patpos === 2) {
    return (
      <SidHorizontal
        distance={`${examData.sid_from} ${
          examData.sid_till && examData.sid_till !== examData.sid_from
            ? ` - ${examData.sid_till}`
            : ""
        }`}
      />
    );
  }
  return (
    <SidVertical
      distance={`${examData.sid_from} ${
        examData.sid_till && examData.sid_till !== examData.sid_from
          ? ` - ${examData.sid_till}`
          : ""
      }`}
    />
  );
}

export default TubeDistance;
