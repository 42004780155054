import { useEffect, useMemo, useState } from "react";
import { useResources } from "../../Utilities/FetchHooks/Pmed/PmedHooks";
import styles from "./ExamOrdersApp.module.scss";

import deviceImages from "@Images/UtypResources";
import { Button, ButtonGroup, InputGroup, ToggleButton } from "react-bootstrap";
import { useWorklistQmList } from "../../Utilities/FetchHooks/Ris/RisHooks";
import { getHHMM_DDMMYYY } from "../../Utilities/DateTimeUtils/DateTimeUtils";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";

function ExamOrdersApp() {
  const [selectedDevice, setSelectedDevice] = useState([]);
  const utypen = [
    { utyp: 2, utypen: "Xray" },
    { utyp: 7, utypen: "US" },
    { utyp: 1, utypen: "CT" },
    { utyp: 3, utypen: "MG" },
    { utyp: 6, utypen: "MR" },
  ];
  const [selectedUtyp, setSelectedUtyp] = useState(utypen);
  const filter = useMemo(() => {
    const getUtypFilter = () => {
      const filter = {};
      if (selectedUtyp.length === 0) {
        return null;
      }
      if (selectedUtyp.length !== 5) {
        filter["field"] = "utyp";
        filter["value"] =
          selectedUtyp.length > 1
            ? selectedUtyp.map((u) => u.utyp)
            : selectedUtyp[0].utyp;
        filter["op"] = selectedUtyp.length > 1 ? "in" : "eq";
        return filter;
      }
    };
    const getDeviceFilter = () => {
      if (selectedDevice.length > 1) {
        return {
          field: "resid",
          value: selectedDevice,
          op: "in",
        };
      } else if (selectedDevice.length === 1) {
        return {
          field: "resid",
          value: selectedDevice[0],
          op: "eq",
        };
      } else {
        return null;
      }
    };
    const utypFilter = getUtypFilter();
    const deviceFilter = getDeviceFilter();

    if (!utypFilter && !deviceFilter)
      return {
        field: "status",
        value: 0,
        op: "eq",
      };

    if (utypFilter && !deviceFilter)
      return {
        and: [{ field: "status", value: 0, op: "eq" }, utypFilter],
      };
    if (!utypFilter && deviceFilter)
      return {
        and: [{ field: "status", value: 0, op: "eq" }, deviceFilter],
      };

    if (utypFilter && deviceFilter)
      return {
        and: [
          { field: "status", value: 0, op: "eq" },
          utypFilter,
          deviceFilter,
        ],
      };
  }, [selectedDevice, selectedUtyp]);

  const pageSize = 15;
  const [pageNr, setPageNr] = useState(1);

  useEffect(() => {
    setPageNr(1);
  }, [filter]);

  const qmListQuery = useWorklistQmList({
    pageNr: pageNr,
    pageSize: pageSize,
    xmask: "{total_count,orders{*}}",
    sort: { field: "creat_timestamp", direction: "desc" },
    filter: filter,
  });

  const wlData = useMemo(() => {
    if (qmListQuery.status !== "success") {
      return [];
    } else {
      if (!qmListQuery.data?.orders) {
        return [];
      }
      return Object.groupBy(
        qmListQuery.data.orders,
        ({ worklistid }) => worklistid
      );
    }
  }, [qmListQuery]);

  return (
    <div className={styles.wrapper}>
      <TopBar
        selectedDevice={selectedDevice}
        setSelectedDevice={setSelectedDevice}
        utypen={utypen}
        selectedUtyp={selectedUtyp}
        setSelectedUtyp={setSelectedUtyp}
      />
      <div className={styles.content}>
        {qmListQuery.status === "loading" ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ gap: "20px" }}
          >
            Loading
            <ClipLoader />
          </div>
        ) : (
          Object.entries(wlData)
            .reverse()
            .map(([key, value]) => <Order key={key} value={value} />)
        )}
      </div>
      <div className={styles.footer}>
        <div>
          Showing {pageNr * pageSize - pageSize + 1}-{pageSize * pageNr} exam
          orders out of {qmListQuery?.data?.total_count}
        </div>
        <div className={styles.pagination}>
          <Button disabled={pageNr === 1} onClick={() => setPageNr(1)}>
            <i className="fas fa-angle-double-left" />
          </Button>
          <Button
            disabled={pageNr === 1}
            onClick={() => setPageNr((prev) => prev - 1)}
          >
            <i className="fas fa-angle-left" />
          </Button>
          <Button
            disabled={
              pageNr === Math.floor(qmListQuery?.data?.total_count / pageSize)
            }
            onClick={() => setPageNr((prev) => prev + 1)}
          >
            <i className="fas fa-angle-right" />
          </Button>
          <Button
            disabled={
              pageNr === Math.floor(qmListQuery?.data?.total_count / pageSize)
            }
            onClick={() =>
              setPageNr(Math.floor(qmListQuery?.data?.total_count / pageSize))
            }
          >
            <i className="fas fa-angle-double-right" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ExamOrdersApp;

function TopBar({
  selectedDevice,
  setSelectedDevice,
  utypen,
  selectedUtyp,
  setSelectedUtyp,
}) {
  const filter = useMemo(() => {
    const filter = {};
    if (selectedUtyp.length === 0) {
      return null;
    }
    if (selectedUtyp.length !== 5) {
      filter["field"] = "utyp";
      filter["value"] =
        selectedUtyp.length > 1
          ? selectedUtyp.map((u) => u.utyp)
          : selectedUtyp[0].utyp;
      filter["op"] = selectedUtyp.length > 1 ? "in" : "eq";
      return filter;
    }
    return null;
  }, [selectedDevice, selectedUtyp]);

  const { data: resourcesData, refetch } = useResources({
    pageSize: 100,
    pageNumber: 1,
    filter,
  });

  return (
    <div className={styles.topbar}>
      <UtypPicker
        selectedUtyp={selectedUtyp}
        setSelectedUtyp={setSelectedUtyp}
        utypen={utypen}
        refetch={refetch}
      />
      <div className={styles.devicesBar}>
        {resourcesData?.resources?.map((resource) => (
          <Device
            resource={resource}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
          />
        ))}
      </div>
    </div>
  );
}

function Device({ resource, selectedDevice, setSelectedDevice }) {
  const getImage = (utypObj) => {
    switch (utypObj.utyp) {
      case 2:
        return deviceImages.Xray;
      case 7:
        return deviceImages.US;
      case 1:
        return deviceImages.CT;
      case 3:
        return deviceImages.MG;
      case 6:
        return deviceImages.MR;
      default:
        return deviceImages.Xray;
    }
  };
  return (
    <div
      className={`${styles.resource} ${
        selectedDevice.includes(resource.id) && styles.selected
      }`}
      onClick={() => {
        if (selectedDevice.includes(resource.id)) {
          setSelectedDevice([]);
        } else {
          setSelectedDevice([resource.id]);
        }
      }}
      key={resource.id}
    >
      <img src={getImage(resource.utypen)} alt={resource.utypen} />
      <p>{resource.beschreibung}</p>
    </div>
  );
}

function UtypPicker({ selectedUtyp, setSelectedUtyp, utypen, refetch }) {
  return (
    <div className={styles.uTypPicker}>
      <Button
        className="mr-2"
        variant={selectedUtyp.length === 5 ? "primary" : "secondary"}
        onClick={() =>
          selectedUtyp.length !== 5
            ? setSelectedUtyp(utypen)
            : setSelectedUtyp([])
        }
      >
        All
      </Button>
      <ButtonGroup toggle>
        {utypen.map((utypObj) => (
          <ToggleButton
            key={utypObj.utyp}
            type="checkbox"
            checked={selectedUtyp.map((u) => u.utyp).includes(utypObj.utyp)}
            onChange={() => {
              if (selectedUtyp.length === 5) {
                setSelectedUtyp([utypObj]);
              } else if (
                selectedUtyp.map((u) => u.utyp).includes(utypObj.utyp)
              ) {
                setSelectedUtyp(selectedUtyp.filter([]));
              } else {
                setSelectedUtyp([utypObj]);
              }
            }}
          >
            {utypObj.utypen}
          </ToggleButton>
        ))}
      </ButtonGroup>
      <Button className="ml-2" onClick={refetch}>
        Refresh
      </Button>
    </div>
  );
}

function Order({ key, value }) {
  const history = useHistory();
  const openInstructions = (order) => {
    let url = `/ExamHelper/systemPreparation?exams_selected=${order
      .map((e) => e.exid)
      .join(",")}`;
    history.push(url);
  };
  return (
    <div key={key}>
      <h3>
        {`${value[0].worklist.patient.patientsname} ${value[0].worklist.patient.patientsvorname}`}
        <div className={styles.orderDate}>
          {getHHMM_DDMMYYY(value[0].creat_timestamp)}
        </div>
        {value.filter((v) => v.exid).length > 0 && (
          <Button
            className="ml-2"
            size="sm"
            onClick={() => openInstructions(value)}
          >
            Show Instructions
          </Button>
        )}
      </h3>
      <ul>
        {value.map((order) => (
          <li key={order.id}>{order.studydescription}</li>
        ))}
      </ul>
    </div>
  );
}
