import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ExamHelperContext } from "../../../Pages/ExamHelper";
import { useRouteMatch } from "react-router-dom";

function useActiveExam({ link }) {
  const { push } = useHistory();
  const { activeExam, setActiveExam } = useContext(ExamHelperContext);
  const url = useRouteMatch();
  const examId = url.params?.examId;
  const setExam = (exam) => {
    setActiveExam(exam);
    push(`${link}/${exam}`);
  };
  useEffect(() => {
    if (activeExam) {
      setExam(activeExam);
    }
    if (!activeExam && examId) {
      setActiveExam(examId);
    }
  }, []);
  return {
    activeExam,
    setExam,
  };
}

export default useActiveExam;
