import "./TopView.scss";
function TopView() {
  return (
    <svg
      version="1.1"
      id="Ebene_2_00000103976456238264228070000008235709152493590146_"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 917 841.3"
    >
      <g>
        <path
          class="tvNW0"
          d="M108.3,256.2v-78.3c0-12,10-21.7,22-21.2l204.4,7.9c11.1,0.4,20,9.3,20.4,20.4l2.7,75.3l2.6,471.4
		c0.1,11.2-8.6,20.6-19.9,21.3l-205.4,13c-12.3,0.8-22.6-9-22.6-21.3l0.4-71.1c0.1-11.3,9-20.5,20.2-21.1l80.6-3.8
		c11.5-0.5,20.4-10.1,20.2-21.6l-6.4-324.2c-0.2-11-8.9-20.1-19.9-20.8l-79.6-5C117,276.7,108.3,267.4,108.3,256.2z"
        />
        <path
          class="tvNW1"
          d="M607.3,214.6V69c0-8.8-6.9-16-15.6-16.4L352,40.7c-9.5-0.5-17.4,7.2-17.3,16.7L345.2,825
		c0.1,9.3,8,16.7,17.3,16.2l232.5-12c8.8-0.5,15.7-7.7,15.6-16.5L607.3,214.6L607.3,214.6z"
        />
        <polygon
          class="tvNW2"
          points="334.8,48.5 266.9,185.2 269.6,721.8 345.3,825 	"
        />
        <path
          class="tvNW3"
          d="M507.4,238.8l-77.5-4.3c-7.2-0.4-13.3,5.4-13.2,12.7l3.8,389c0.1,10.1,8.7,18.1,18.8,17.5l103.9-6.8
		l-47.4-16.6L489,249.5L507.4,238.8L507.4,238.8z"
        />
        <polygon class="tvNW4" points="917,0 489,249.5 495.9,630.2 917,778 	" />
      </g>
      <g>
        <path
          class="tvNW5"
          d="M77.7,513.2h-7.9c-1.9,0-3.4-1.7-3.1-3.6l1.6-11.2c0.2-1.7,1.7-2.9,3.4-2.9l0,0L77.7,513.2L77.7,513.2z"
        />
        <path
          class="tvNW5"
          d="M161.7,342.9l-7.3-0.2c-4.7-20.2-20.5-30.8-41.9-36.7l-6.8-8.7c-18.4-13.1-34.7-9.1-50.1,2.3
		c-8.7-0.4-18.1,10.5-27.8,25.5c-3.2,10.9-4.5,22.1-1.6,33.9c1.1,4.5,4.3,8.2,8.7,9.5c4,1.2,8.3,0.3,13-4.1c6.2-4.5,5.3-9.4,0-14.6
		c9.1,0,13.3-3.8,16-9c1.6,20.6,4.3,42.8,21.9,51.4l54.9-7.9L161.7,342.9L161.7,342.9z"
        />
        <path
          class="tvNW5"
          d="M205,357.4c-8.4-16.8-23.5-19.2-41.7-15c-1,0.2-2,0.9-2.6,1.8c-16.5,24.7-43.7,37.8-74.1,47.5
		c-0.7,0.2-1.3,0.6-1.8,1.1c-14.6,15.4-27.5,31.8-32.5,53.2c0,0.2-0.1,0.3-0.1,0.5c-1.4,10.4,4.6,19.7,14.7,28.4
		c0.2,0.2,0.4,0.3,0.6,0.5l128.6,77.4c0.7,0.4,6.7,1.5,7.1,2.2c5.7,10.9,5.1,15.8,21.3,22.3c3.7,1.5,3.8,8.1,0,9.2l-2.7,1.8
		c-1.1,0.3-3.3,2.2-3.9,3.1c-6.4,9.3-21.7-13.2-30.3-6c-0.2,0.1,0.1,0.4,0.1,0.6c0,1.1,0.1,4.6,0.2,5.8c0,0.3,0,0.7,0.1,1
		c4.9,7.7,13.4,16.8,21.8,17.7c0.1,0,0.3,0,0.4,0l14.5,1.9c0.1,0,0.2,0,0.2,0c13.5,0.8,9.8-1.9,23.2-4.9c1.5-0.3,2.8-1.3,4.1-2.1
		c3.4-1.9,6.4-3.8,8.9-5.4c4.2-2.8,7.5-6.8,9.5-11.5c2.6-6.3,3.5-14.8,3.6-15.5c0-1.2,3.3-46.7,2.2-53.2c0-0.1-0.1-0.3-0.1-0.4
		c-0.6-6.5-0.9-7.4-3.4-15.1c-1.7-5.1-9.2-8.8-9.3-9c-7.9-15.8-22.1-62.2-47.7-115.3L205,357.4L205,357.4z"
        />
        <path
          class="tvNW6"
          d="M67.2,475.2c-10.4-7.8-15.4-17.4-14.9-28.9c1.5-9.9,6.4-20.2,13.8-30.8c6-2.6,11.2-2.8,15.5,0l88.7-46.5
		c8.3-7.1,15-15.9,20.4-25.9l8.2,5.7c-8.5,6-14.9,12.5-17.5,20.2l-97.1,51.2L67.2,475.2L67.2,475.2z"
        />
        <path
          class="tvNW5"
          d="M101.6,396.2c2.5-8.9,5.1-17.5,10-21c2.6-2.7,5.8-3.6,9.6-2.7c3.6,1.2,4.7,4.3,3.3,9.3L101.6,396.2
		L101.6,396.2z"
        />
        <path
          class="tvNW5"
          d="M68.7,484.1l-1.5-8.8c-4.3-25.1,4-50.9,22.3-68.6c3.7-3.6,7.7-7,12-10.4c36.3-27.2,73.5-34,111.7-20.5
		c27.8,10.4,46.7,28.5,56.5,54.2c8.8,26.5-3.1,49.7-9.1,78c-7.5,14.7-21.4,26.8-38.5,37.7c-33.2,21.1-75.3,22.9-110,4.4
		c-3.2-1.7-6.5-3.6-9.7-5.6C82.9,533.4,74.9,509.7,68.7,484.1L68.7,484.1z"
        />
      </g>
      <g>
        <polyline
          class="tvNW7"
          points="128,277 233.9,358.8 284.7,398 284.7,330.1 284.7,210 	"
        />
        <path class="tvNW8" d="M234.9,359.5c7.7-23.6,23.8-33.3,49.8-26.6" />
      </g>
      <line class="tvNW9" x1="276.6" y1="489.7" x2="276.6" y2="601.6" />
      <text
        transform="matrix(1 0 0 1 198.9698 316.45)"
        class="tvNW10 st11 st12"
      >
        45°
      </text>
    </svg>
  );
}

export default TopView;
