import {
  getFacilities,
  getFacility,
  getFacilityRefPhysicians,
  getRefPhysicians,
  getRefPhysician,
  getResources,
  getSystems,
  getUser,
  getUsers,
  getUserPreferences,
  getProtokol,
} from "../Helpers/FetchFunctions/pmed/PmedFetches";
import usePmedQuery from "../Helpers/UsePmedQuery";

export function useFacilities({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["facilities", pageNr, pageSize, filter, sort, xmask],
    getFacilities,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useFacility({
  facilityId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["facility", facilityId, xmask],
    getFacility,
    {
      facilityId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useFacilityRefPhysicians({
  facilityId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["facilityRefPhys", facilityId, xmask],
    getFacilityRefPhysicians,
    {
      facilityId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useRefPhysicians({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["refPhysicians", pageNr, pageSize, filter, sort, xmask],
    getRefPhysicians,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useRefPhysician({
  refPhysId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["refPhysician", refPhysId, xmask],
    getRefPhysician,
    {
      refPhysId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useResources({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["resources", pageNr, pageSize, filter, sort, xmask],
    getResources,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useSystems({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["systems", pageNr, pageSize, filter, sort, xmask],
    getSystems,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useUsers({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["users", pageNr, pageSize, filter, sort, xmask],
    getUsers,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useUser({
  userId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["user", userId, xmask],
    getUser,
    {
      userId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useUserPreferences({
  userId,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["userPreferences", userId],
    getUserPreferences,
    {
      userId,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useProtokol({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["protokol", pageNr, pageSize, filter, sort, xmask],
    getProtokol,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}
