import { useState } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import AuthorizedElement from "../../Utilities/AuthorizedElement";
import PatientsTable from "../../Patients/PatientListTable";
import { useTranslation } from "react-i18next";
import AddPatientModal from "../../Patients/AddPatientModal";

function SelectPatientModal({
  shown,
  close,
  amount,
  rowClickHandler = undefined,
  ...props
}) {
  const [isAddingOpened, setIsAddingOpened] = useState(false);
  const handleRowClick = (evt, row) => {
    if (rowClickHandler) {
      rowClickHandler(row.original);
    } else {
      console.warn("No click handler assigned");
    }
  };
  const { t } = useTranslation(["common", "common"]);

  return (
    <Modal size={"lg"} className={props?.className} show={shown} onHide={close}>
      <AddPatientModal
        onHide={() => setIsAddingOpened(false)}
        show={isAddingOpened}
      />
      <Modal.Header closeButton className="py-2">
        <h5>{t("selectDestinationPatient")}</h5>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <AuthorizedElement roles={["pm_admin", "pm_user", "pm_facility"]}>
              <Col xs={12} md={12} xl={12} sm={12} className="h-50">
                <PatientsTable pageSize={amount} rowClick={handleRowClick} />
              </Col>
            </AuthorizedElement>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={() => setIsAddingOpened(true)}
        >
          {t("addPatient")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectPatientModal;
