import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import PatientHeader from "./PatientRecordHead";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  setReloadExaminationsFlag,
  setSelectAllExaminationsFlag,
  setExpandAllExaminations,
  setReloadPatientDataFlag,
} from "../Redux/actions";

import {
  usePatient,
  usePatientStudies,
} from "../Utilities/FetchHooks/Patients/PatientsHooks";

import "./PatientBox.css";
import ExamRow from "../Components/PatientStudies/ExamRow";

function PatientBox({ patid }) {
  const { t } = useTranslation(["common"]);
  const [fetchFailed, setFetchFailed] = useState(false);
  const dispatch = useDispatch();

  const shouldReloadFlag = useSelector(
    (state) => state.journalFlags.reloadExaminations
  );

  const shouldReloadPatientFlag = useSelector(
    (state) => state.journalFlags.reloadPatientData
  );

  const shouldSelectAll = useSelector(
    (state) => state.journalFlags.selectAllExaminations
  );

  const ExaminationsExpanded = useSelector(
    (state) => state.journalFlags.expandAllExaminations
  );

  const patientQ = usePatient({ patientId: patid });
  const patientData = patientQ.data;

  const patientStudiesQ = usePatientStudies({
    patientId: patid,
    sort: { field: "studydate", direction: "desc" },
    xmask:
      "studies{id,dapatid,patientsname,accessionnumber,studydate,modality,studydescription,studyinstanceuid,kontoid}",
  });
  const patientStudies = patientStudiesQ.data?.studies;

  const reload = () => {
    setFetchFailed(false);
    patientStudiesQ.refetch();
  };

  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  useEffect(() => {
    if (shouldReloadFlag) {
      reload();
      dispatch(setReloadExaminationsFlag(false));
    }
  }, [shouldReloadFlag]);

  useEffect(() => {
    if (shouldReloadPatientFlag) {
      patientQ.refetch();
      dispatch(setReloadPatientDataFlag(false));
    }
  }, [shouldReloadPatientFlag]);

  const isSelected = (exam) => {
    return (
      selectedExaminations.find((selExam) => selExam.id === exam.id) !==
      undefined
    );
  };

  useEffect(() => {
    if (shouldSelectAll && patientStudies?.every(isSelected)) {
      dispatch(setSelectAllExaminationsFlag(false));
    }
  }, [selectedExaminations]);

  return (
    <Container fluid>
      {!fetchFailed ? (
        <>
          <Row className="mx-0 mt-3">
            <Col xs={12} sm={12} md={12} lg={12} className="px-0">
              {patientData ? (
                <PatientHeader patient={patientData} />
              ) : (
                <div className="text-center">
                  <ClipLoader
                    color="#ff880d"
                    loading={true}
                    css={true}
                    size={60}
                  />
                </div>
              )}
            </Col>
          </Row>

          <Row className="mt-2 mx-1">
            <div className="d-flex w-100">
              <Button
                className="ml-auto"
                onClick={() => {
                  dispatch(setExpandAllExaminations(!ExaminationsExpanded));
                }}
              >
                <i
                  className={`${
                    ExaminationsExpanded
                      ? "fas fa-chevron-up"
                      : "fas fa-chevron-down"
                  }`}
                />
              </Button>
            </div>
          </Row>

          <Row className="my-2"></Row>
          <Container fluid className="mx-0 px-0 my-0 py-0 tab">
            {patientStudiesQ.status !== "loading" && patientStudies != null ? (
              <div className="d-flex flex-column">
                {patientStudies.map((study, i) => (
                  <ExamRow
                    key={`examrowkey${i}`}
                    exam_data={study}
                    reloadHandler={reload}
                  />
                ))}
              </div>
            ) : (
              <>
                {patientStudiesQ.status === "loading" && (
                  <div className="text-center">
                    <ClipLoader
                      color="#ff880d"
                      loading={true}
                      css={true}
                      size={60}
                    />
                  </div>
                )}
              </>
            )}
          </Container>
        </>
      ) : (
        <div className="text-center mt-3 border rounded py-2 bg-warning">
          <h5>{t("errors.fetchFailed")} </h5>
          <Button onClick={reload}>
            Reload <i className="fas fa-sync-alt"></i>
          </Button>
        </div>
      )}
    </Container>
  );
}

export default PatientBox;
