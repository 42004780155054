import {
  getAnatomy,
  getExamination,
  getExaminations,
  getExaminationsInfinite,
  getExaminationsStats,
  getIndications,
  getMasKv,
  getMasKvs,
  getRefPhysStudies,
  getSubstantiations,
  getUserStudies,
} from "../Helpers/FetchFunctions/ris/RisFetches";
import { getWorklistQmList } from "../Helpers/FetchFunctions/ris/WorklistFetches";
import usePmedQuery, { usePmedInfiniteQuery } from "../Helpers/UsePmedQuery";

export function useAnatomy({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["anatomy", pageNr, pageSize, filter, sort, xmask],
    getAnatomy,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useExaminations({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["examinations", pageNr, pageSize, filter, sort, xmask],
    getExaminations,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useExaminationsInfinite({
  pageSize = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedInfiniteQuery(
    ["examinations-infinite", pageSize, filter, sort, xmask],
    getExaminationsInfinite,
    { pageSize, pageNr: 1, filter, sort, xmask },
    null,
    pageSize,
    enabled
  );
  return query;
}

export function useExamination({
  examId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["examinations", examId, xmask],
    getExamination,
    {
      examId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useIndications({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["indications", pageNr, pageSize, filter, sort, xmask],
    getIndications,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useSubstantiations({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["substantiations", pageNr, pageSize, filter, sort, xmask],
    getSubstantiations,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useExaminationStats({
  examId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["examstats", examId, xmask],
    getExaminationsStats,
    {
      examId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useMasKvs({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["maskvs", pageNr, pageSize, filter, sort, xmask],
    getMasKvs,
    { pageSize, pageNr, filter, sort, xmask },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useMasKv({
  masKvId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["maskv", masKvId, xmask],
    getMasKv,
    {
      masKvId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useUserStudies({
  userId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["userStudies", userId, xmask],
    getUserStudies,
    {
      userId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useRefPhysStudies({
  refPhysId,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["refPhysStudies", refPhysId, xmask],
    getRefPhysStudies,
    {
      refPhysId,
      xmask,
    },
    keepPreviousData,
    enabled
  );
  return query;
}

export function useWorklistQmList({
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined,
  xmask = undefined,
  enabled = true,
  keepPreviousData = false,
}) {
  const query = usePmedQuery(
    ["worklistQmList", xmask, pageNr, pageSize, filter, sort],
    getWorklistQmList,
    {
      xmask,
      pageSize,
      pageNr,
      filter,
      sort,
    },
    keepPreviousData,
    enabled
  );
  return query;
}
