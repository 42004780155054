import "./StandingCaudal.scss";

function StandingCaudal({ angle }) {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 283.5 283.5"
    >
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          data-name="Unbenannter Verlauf"
          x1="1322.5"
          y1="-228"
          x2="1284.9"
          y2="-228"
          gradientTransform="translate(1345.7 -84.2) rotate(-180)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".3" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_2"
          data-name="Unbenannter Verlauf 2"
          x1="824.4"
          y1="-78.4"
          x2="864.5"
          y2="-78.4"
          gradientTransform="translate(-153.8 -626.4) rotate(69.7)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_3"
          data-name="Unbenannter Verlauf 3"
          x1="817.9"
          y1="-115.6"
          x2="865.3"
          y2="-115.6"
          gradientTransform="translate(-153.8 -626.3) rotate(69.7)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
      </defs>
      <g id="Ebene_11" data-name="Ebene_1">
        <g id="Ebene_1-2">
          <g id="Ebene_1-21" data-name="Ebene_1-2">
            <g id="a">
              <rect
                class="standingcaudal2"
                x="-.5"
                y="0"
                width="283.5"
                height="283.5"
              />
              <rect
                class="standingcaudal1"
                x="10.3"
                y="13"
                width="16.9"
                height="262.2"
              />
              <path
                class="standingcaudal3"
                d="M25.3,65.2l-1.3,14.9c1.1,15.7,7.3,31.7,6.4,37.2,0,11.5-8.1,16.3-6.4,25,1.1,4.6,2.9,8.9,6.1,16.3-2.1,6.4-2.7,11-2.6,14.6-.5,6.6-.3,18.5,2.2,29.3-1.7,4.7-2.2,12.2-2,20.9.4,10.7,4.8,21.6,4.7,32.5,0,8-3.2,14.1-.3,15-2.7-.4,30.8,1.5,27.7-1.5-6.1-2.3-15.3-.7-19.4-12,0-13.1.3-37.8,4.3-55.5,1.8-8.3,8.6-36.1,7.5-47.7l3.1-63.6s7.9-6.4-9.1-28.4c-1.5-12,3.8-14.6,3.9-14.1,4.2,1.1,6.1.3,6.1-.4.1-1.6.1-2.3.3-3.7.4-.4.3-.9,1-1.1.3-.5.6-2-.2-4.2l2.7-.2c.5-2.5-1.5-3.4-3.1-6.2-.4-.8-.4-2.1.4-3.5.4-.9-2.8-11.2-15-12.3-10,.3-12.5,2.1-13.4,12.3-.2,6.1,6.8,14.3,5.7,22-4.3,3.2-8.9,13.2-9.3,14.4h0Z"
              />
              <g>
                <g>
                  <line
                    class="standingcaudal4"
                    x1="245.6"
                    y1="124.1"
                    x2="241.8"
                    y2="124.1"
                  />
                  <line
                    class="standingcaudal0"
                    x1="234.5"
                    y1="124"
                    x2="76.7"
                    y2="122.8"
                  />
                  <line
                    class="standingcaudal4"
                    x1="73"
                    y1="122.8"
                    x2="69.2"
                    y2="122.7"
                  />
                </g>
                <line
                  class="standingcaudal4"
                  x1="246.9"
                  y1="124"
                  x2="89.7"
                  y2="247.5"
                />
                <g>
                  <path
                    class="standingcaudal4"
                    d="M69.6,126.2c-5.4,40.4-.4,78.5,16.5,113.2"
                  />
                  <path
                    class="standingcaudal7"
                    d="M89.7,246.1c-2.8-2.6-6.9-5.6-10.3-6.9l6.4-.8,4.4-4.7c-.9,3.5-1,8.5-.5,12.3h0Z"
                  />
                </g>
                <g>
                  <polygon
                    class="standingcaudal5"
                    points="229 140.9 223.4 124.8 192.6 124.5 205.9 162.4 229 140.9"
                  />
                  <path
                    class="standingcaudal6"
                    d="M223.9,127.3c-2.2-13,6.2-25.5,18.7-27.9,12.6-2.4,24.5,6.1,26.8,19.1,2.2,13-6.2,25.5-18.7,27.9-12.5,2.4-24.5-6.1-26.8-19.1h0Z"
                  />
                </g>
              </g>
            </g>
            <g class="standingcaudal8">
              <g class="standingcaudal8">
                <g class="standingcaudal8">
                  <text
                    class="standingcaudal9"
                    transform="translate(86.9 185.5) scale(1 1)"
                  >
                    <tspan x="0" y="0">
                      {angle}°
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default StandingCaudal;
