import { getFetch, MakeUrl, REST_URL } from "../../FetchWrapper";

export async function getPatients(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/ris/patients`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getPatient(keycloak, params) {
  if (!params.patientId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/patients/${params.patientId}`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getPatientWebAccessCredentials(keycloak, params) {
  if (!params.patientId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/patients/${params.patientId}/webaccess`);
  return getFetch(url, keycloak);
}

export async function getPatientDoses(keycloak, params) {
  if (!params.patientId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/patients/${params.patientId}/doses`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getPatientStudies(keycloak, params) {
  if (!params.patientId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/patients/${params.patientId}/studies`);
  if (params.sort) {
    url.searchParams.append("sort", JSON.stringify(params.sort));
  }
  return getFetch(url, keycloak, params.xmask);
}

export async function getPatientWorklist(keycloak, params) {
  if (!params.patientId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/patients/${params.patientId}/worklist`);
  return getFetch(url, keycloak, params.xmask);
}
