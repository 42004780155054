import { EXAM_QA_OVERVIEW } from "../../../../Pages/Consts/ExamHelperRoutes";

import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import CompleteQaSection from "./ExamQa/CompleteQaSection";
import ExamsSection from "../ExamsSection";
import useActiveExam from "../../Hooks/useActiveExam";

function ExamHelperOverviewApp(props) {
  const { activeExam, setExam } = useActiveExam({
    link: EXAM_QA_OVERVIEW.link,
  });
  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection selectedExam={activeExam} selectedExamSetter={setExam} />
      }
      rightComponent={<CompleteQaSection selectedExamId={activeExam} />}
    />
  );
}

export default ExamHelperOverviewApp;
