import { useEffect, useState, useMemo } from "react";
import "./pdfTemplates.css";
import { useTranslation } from "react-i18next";
import { getDDMMYYY_HHMM } from "../../../Utilities/DateTimeUtils/DateTimeUtils";

const currency = {
  de: "€",
  pl: "zł",
  en: "€",
};

export function FacilitySummaryReport({ data, variant = null }) {
  const [total, setTotal] = useState(0);
  const { t, i18n } = useTranslation(["reports", "common"]);
  const [Index, setIndex] = useState(1);

  let total_temp = 0;

  const getStudyCount = (list_of_exams, bump_total = false) => {
    if (bump_total) {
      total_temp += list_of_exams.length - 1;
    }
    return list_of_exams.length - 1;
  };

  const getTotalForSingleReport = (report) => {
    try {
      let title = Object.keys(report);
      if (title.length > 1) {
        title = title[0];
      }
      let doctors_reffered = Object.values(report[title]);
      let total = 0;
      doctors_reffered.forEach((dr) => {
        total += dr["rows"].length - 1;
      });
      return total;
    } catch {
      return "nd.";
    }
  };

  useEffect(() => {
    setTotal(total_temp);
  }, [data]);

  return (
    <div className="report-table-parent">
      {data.map((report) => (
        <table className="minimalistBlack my-2">
          <thead>
            <tr>
              <th colSpan="6">{Object.keys(report)[0]}</th>
              {report.sum > 0 && (
                <th colSpan="2" className="font-weight-bold">
                  <>
                    {t("pdfTemplate.sum")}: {report.sum}{" "}
                    {currency[i18n.language]}
                  </>
                </th>
              )}
            </tr>
            <tr>
              <th colSpan="10">
                {t("pdfTemplate.totalRefferedStudies")}
                {getTotalForSingleReport(report)}
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(report[Object.keys(report)[0]]).map((doctor) => (
              <>
                <tr className="highlighted-report-row">
                  <td className="font-weight-bold" colSpan="6">
                    {doctor + " "}
                    {t("pdfTemplate.totalRefferedStudies")}
                    {getStudyCount(
                      report[Object.keys(report)[0]][doctor]["rows"]
                    )}
                    {t("pdfTemplate.pictureShort", {
                      count: getStudyCount(
                        report[Object.keys(report)[0]][doctor]["rows"],
                        true
                      ),
                    })}
                  </td>
                  {report[Object.keys(report)[0]][doctor]["partial sum"] >
                    0 && (
                    <td colSpan="2">
                      {t("pdfTemplate.partialSum")}{" "}
                      {report[Object.keys(report)[0]][doctor]["partial sum"]}{" "}
                      {currency[i18n.language]}
                    </td>
                  )}
                </tr>
                {report[Object.keys(report)[0]][doctor]["rows"].map(
                  (element, i) =>
                    i == 0 ? (
                      <tr className="font-weight-bold">
                        {element.map((col) => (
                          <td>{col}</td>
                        ))}
                      </tr>
                    ) : (
                      <tr>
                        {element.map((col) =>
                          col != "None" ? <td>{col}</td> : <td>0</td>
                        )}
                      </tr>
                    )
                )}
              </>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
}

export function FacilityBaseSummaryReport({ data, variant = null }) {
  const [total, setTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(null);
  const { t, i18n } = useTranslation(["reports", "common"]);
  let total_temp = 0;
  const getStudyCount = (list_of_exams) => {
    if (list_of_exams[0][1] === "Amount") {
      return list_of_exams
        .slice(1)
        .reduce((sum, row) => sum + parseInt(row[1]), 0);
    }
    total_temp += list_of_exams.length;
    return list_of_exams.length - 1;
  };

  useEffect(() => {
    setTotal(total_temp);
  }, [data]);

  return (
    <div className="report-table-parent">
      {data.map((report) => (
        <table className="minimalistBlack my-2">
          <thead>
            <tr>
              <th colSpan="4" id="1125">
                {Object.keys(report)[0]}
              </th>
              {report.sum > 0 ? (
                <th colSpan="2" className="font-weight-bold">
                  <>
                    {t("pdfTemplate.sum")}: {report.sum}{" "}
                    {currency[i18n.language]}
                  </>
                </th>
              ) : (
                <script language="javascript">
                  var th = document.getElementById(1125);
                  th.setAttribute("colSpan", 6);
                </script>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(report[Object.keys(report)[0]]).map((doctor) => (
              <>
                {![
                  "FacReportVariants.ref_fac_summary.single",
                  "FacReportVariants.ref_fac_summary",
                  "FacReportVariants.study_type_summary.single",
                  "FacReportVariants.study_type_summary",
                ].includes(variant) && (
                  <tr className="highlighted-report-row">
                    <td className="font-weight-bold" colSpan="10">
                      {"Całkowita ilość zleconych badań: "}
                      {getStudyCount(
                        report[Object.keys(report)[0]][doctor]["rows"]
                      )}
                      {" szt."}
                    </td>
                  </tr>
                )}
                {report[Object.keys(report)[0]][doctor]["rows"].map(
                  (element, i) =>
                    i === 0 ? (
                      <tr className="font-weight-bold">
                        {element.map((col) => (
                          <td>{col}</td>
                        ))}
                      </tr>
                    ) : (
                      <tr>
                        {element.map((col) => {
                          if (col !== "None") {
                            return <td>{col}</td>;
                          } else {
                            <td>0</td>;
                          }
                        })}
                      </tr>
                    )
                )}
              </>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
}

export function RefPhysSummaryReport({ data }) {
  const [total, setTotal] = useState(0);
  const [colSpan, setColSpan] = useState(0);
  const { t, i18n } = useTranslation(["reports", "common"]);
  let total_temp = 0;
  const getStudyCount = (list_of_exams) => {
    let amountCol = 0;
    if (list_of_exams.length > 1) {
      if (isNaN(parseInt(list_of_exams[1][2]))) {
        if (isNaN(parseInt(list_of_exams[1][1]))) {
          return list_of_exams.length - 1;
        } else amountCol = 1;
      } else amountCol = 2;
    }

    for (let i = 1; i < list_of_exams.length; i++) {
      total_temp += parseInt(list_of_exams[i][amountCol]);
    }
    return total_temp;
  };

  useEffect(() => {
    setTotal(total_temp);
    let report = data[0];
    let doctor = Object.keys(report[Object.keys(report)[0]]);
    if (report.sum > 0)
      setColSpan(report[Object.keys(report)[0]][doctor]["rows"][0].length - 1);
    else setColSpan(report[Object.keys(report)[0]][doctor]["rows"][0].length);
  }, [data]);

  return (
    <div className="report-table-parent">
      {data.map((report) => (
        <table className="minimalistBlack my-2">
          <thead>
            <tr>
              <th colSpan="10">{Object.keys(report)[0]}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(report[Object.keys(report)[0]]).map((doctor) => (
              <>
                <tr className="highlighted-report-row">
                  <td className="font-weight-bold" colSpan={colSpan}>
                    {doctor != "No_doc" && doctor}
                    {t("pdfTemplate.totalRefferedStudies")}
                    {getStudyCount(
                      report[Object.keys(report)[0]][doctor]["rows"]
                    )}
                    {" pcs"}
                  </td>
                  {report.sum > 0 && (
                    <td className="font-weight-bold" colSpan="2">
                      {t("pdfTemplate.sum")}:{report.sum}{" "}
                      {currency[i18n.language]}
                    </td>
                  )}
                </tr>
                {report[Object.keys(report)[0]][doctor]["rows"].map(
                  (element, i) =>
                    i == 0 ? (
                      <tr className="font-weight-bold">
                        {element.map((col) => (
                          <td>{col}</td>
                        ))}
                      </tr>
                    ) : (
                      <tr>
                        {element.map((col) =>
                          col != "None" ? <td>{col}</td> : <td>0</td>
                        )}
                      </tr>
                    )
                )}
              </>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
}

export function RadiologistReport({ data }) {
  const [total, setTotal] = useState(0);
  const { t, i18n } = useTranslation(["reports", "common"]);
  let total_temp = 0;

  useEffect(() => {
    setTotal(total_temp);
  }, [data]);

  const summary = useMemo(() => {
    if (!data) {
      return { valid: false };
    }
    if (JSON.stringify(data).includes("Detailed Radiologist Report")) {
      //hacky but fine xd
      return {
        valid: false,
      };
    }
    try {
      var monster = Object.values(data[0][Object.keys(data[0])])[0]["rows"]; //monster selector:3
      let ordered = monster
        .slice(1)
        .reduce((sum, row) => (sum += parseInt(row[2])), 0);
      let wFinding = monster
        .slice(1)
        .reduce((sum, row) => (sum += parseInt(row[3])), 0);
      return {
        valid: true,
        ordered,
        wFinding,
      };
    } catch {
      return {
        valid: false,
      };
    }
  }, [data]);

  return (
    <div className="report-table-parent">
      {data.map((report) => (
        <table className="minimalistBlack my-2">
          <thead>
            <tr>
              <th colSpan="10">{Object.keys(report)[0]}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(report[Object.keys(report)[0]]).map((date) => (
              <>
                <tr className="highlighted-report-row">
                  <td className="font-weight-bold" colSpan="10">
                    {t("pdfTemplate.reportDateRange")} {date}
                  </td>
                </tr>
                {report[Object.keys(report)[0]][date]["rows"].map(
                  (element, i) =>
                    i == 0 ? (
                      <tr className="font-weight-bold">
                        {element.map((col) => (
                          <td>{col}</td>
                        ))}
                      </tr>
                    ) : (
                      <tr>
                        {element.map((col, i) => {
                          if (element?.length > 4 && i > 3) {
                            return col != "None" ? (
                              <td>{getDDMMYYY_HHMM(col)}</td>
                            ) : (
                              <td>0</td>
                            );
                          }
                          return col != "None" ? <td>{col}</td> : <td>0</td>;
                        })}
                      </tr>
                    )
                )}
              </>
            ))}
            {summary.valid && (
              <tr className="highlighted-report-row">
                <td colSpan={2}>Łącznie</td>
                <td>{summary.ordered}</td>
                <td>{summary.wFinding}</td>
              </tr>
            )}
          </tbody>
        </table>
      ))}
    </div>
  );
}

export function RadiologistDetailedReport({ data }) {
  const { t, i18n } = useTranslation(["reports", "common"]);
  let total_temp = 0;

  const [Index, setIndex] = useState(1);
  useEffect(() => {}, [data]);

  return (
    <div className="report-table-parent">
      {data.map((report) => (
        <table className="minimalistBlack my-2">
          <thead>
            <tr>
              <th colSpan="6">{Object.keys(report)[0]}</th>
              {report.sum > 0 && (
                <th colSpan="2" className="font-weight-bold">
                  <>
                    {t("pdfTemplate.sum")}: {report.sum}{" "}
                    {currency[i18n.language]}
                  </>
                </th>
              )}
            </tr>
            <tr>
              <th colSpan="10">
                {t("pdfTemplate.totalRefferedStudies") +
                  " " +
                  report[Object.keys(report)[0]["referredTotal"]]}
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(report[Object.keys(report)[0]]).map((doctor) => (
              <>
                <tr className="highlighted-report-row">
                  <td className="font-weight-bold" colSpan="4">
                    {doctor}
                  </td>
                  <td className="font-weight-bold" colSpan="1">
                    {report[Object.keys(report)[0]][doctor]["referredPartial"]}
                  </td>
                  <td className="font-weight-bold" colSpan="1">
                    {report[Object.keys(report)[0]][doctor]["findingsPartial"]}
                  </td>
                </tr>
                {report[Object.keys(report)[0]][doctor]["rows"].map(
                  (element, i) =>
                    i == 0 ? (
                      <tr className="font-weight-bold">
                        {element.map((col) => (
                          <td>{col}</td>
                        ))}
                      </tr>
                    ) : (
                      <tr>
                        {element.map((col) =>
                          col != "None" ? <td>{col}</td> : <td>0</td>
                        )}
                      </tr>
                    )
                )}
              </>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
}

export function DemographicReport({ data }) {
  const [total, setTotal] = useState(0);
  const { t, i18n } = useTranslation(["reports", "common"]);
  let total_temp = 0;
  const getStudyCount = (list_of_exams) => {
    total_temp += list_of_exams.length;
    return list_of_exams.length;
  };

  useEffect(() => {
    setTotal(total_temp);
  }, [data]);

  return (
    <div className="report-table-parent">
      {data.map((report) => (
        <table className="minimalistBlack my-2">
          <thead>
            <tr>
              <th colSpan="10">{Object.keys(report)[0]}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(report[Object.keys(report)[0]]).map((doctor) => (
              <>
                <tr className="highlighted-report-row">
                  <td className="font-weight-bold" colSpan="10">
                    {t("pdfTemplate.reportDateRange")} {doctor}
                  </td>
                </tr>
                {report[Object.keys(report)[0]][doctor]["rows"].map(
                  (element, i) =>
                    i == 0 ? (
                      <tr className="font-weight-bold">
                        {element.map((col) => (
                          <td>{col}</td>
                        ))}
                      </tr>
                    ) : (
                      <tr>
                        {element.map((col) =>
                          col != "None" ? <td>{col}</td> : <td>0</td>
                        )}
                      </tr>
                    )
                )}
              </>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
}

export function RowBasedTemplate({ data }) {
  if (data) {
    return (
      <div className="report-table-parent text-left p-2">
        <table className="w-100">
          {data[0].map((row, key) => {
            if (row.type === "head") {
              return (
                <tr style={{ backgroundColor: "#e0e0e0" }}>
                  <th colspan="6">{row.cells[0]}</th>
                </tr>
              );
            }
            if (row.type === "sub_head") {
              return (
                <tr style={{ backgroundColor: "#f1f1f1" }}>
                  <th colspan="6">{row.cells[0]}</th>
                </tr>
              );
            }

            if (row.type === "row") {
              return (
                <tr>
                  {row.cells.map((cell) => (
                    <td>{cell}</td>
                  ))}
                </tr>
              );
            }
            if (row.type === "row_desc") {
              return (
                <tr style={{ backgroundColor: "#eeeeee" }}>
                  {row.cells.map((cell) => (
                    <td>{cell}</td>
                  ))}
                </tr>
              );
            }
          })}
        </table>
      </div>
    );
  } else {
    return <></>;
  }
}

export function RowDefinedTemplate({ data }) {
  const { col_count, report } = data;
  if (report) {
    return (
      <div className="report-table-parent text-left p-2">
        <table className="w-100">
          {report.map((row, key) => {
            if (row.type === "head") {
              return (
                <tr style={{ backgroundColor: "#e0e0e0" }}>
                  <th colspan={col_count}>{row.data[0]}</th>
                </tr>
              );
            }
            if (row.type === "sub_head") {
              return (
                <tr style={{ backgroundColor: "#f1f1f1" }}>
                  <th colspan={col_count}>{row.data[0]}</th>
                </tr>
              );
            }

            if (row.type === "row") {
              return (
                <tr>
                  {row.data.map((cell) => (
                    <td>{cell}</td>
                  ))}
                </tr>
              );
            }
            if (row.type === "row_desc") {
              return (
                <tr style={{ backgroundColor: "#eeeeee" }}>
                  {row.data.map((cell) => (
                    <td>{cell}</td>
                  ))}
                </tr>
              );
            }
          })}
        </table>
      </div>
    );
  } else {
    return <></>;
  }
}
