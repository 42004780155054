import { Button, Modal } from "react-bootstrap";
import { useStudyKartei } from "../../Utilities/FetchHooks/Studies/StudiesHooks";
import { createRef, useEffect, useState } from "react";

function StudyFindingDialog({ show, onHide, studyId }) {
  const karteiQ = useStudyKartei({ studyId: studyId, enabled: show });
  const [finding, setFinding] = useState("");
  useEffect(() => {
    if (karteiQ.status === "success") {
      karteiQ?.data?.kartei_entries.forEach((entry) => {
        entry.kartei_data.forEach((data) => {
          if (data.data) {
            setFinding(data.data);
          }
        });
      });
    }
  }, [karteiQ]);

  const replacements = {
    "\\'a3": "Ł",
    "\\'b3": "ł",
    "\\'d3": "Ó",
    "\\'f3": "ó",
    "\\'9b": "Ś",
    "\\'9c": "ś",
    "\\'bc": "Ż",
    "\\'bf": "ż",
    "\\'d9": "Ź",
    "\\'fa": "ź",
    "\\'eb": "Ę",
    "\\'ea": "ę",
    "\\'a4": "Ą",
    "\\'b9": "ą",
    "\\'c6": "Ć",
    "\\'e6": "ć",
    "\\'d1": "Ń",
    "\\'f1": "ń",
  };

  const parsed = finding
    ? finding
        .slice(1, -1)
        .replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "")
        .trim()
        .replace(/\\'([a-z0-9]{2})/gi, (match, p1) => {
          const key = `\\'${p1}`;
          return replacements[key] || match; // Replace if found in replacements, otherwise return the match unchanged
        })
        .replace(/\n/g, "</br>")
    : "";

  const ref = createRef(null);

  useEffect(() => {
    if (!ref.current || parsed.length === 0) return;
    ref.current.innerHTML = parsed;
  }, [parsed, ref]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton className="p-1 bg-dark text-white">
        <Modal.Title>Study Finding (no formatting)</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={ref} className="p-1 py-3 bg-dark text-white">
        {parsed || "No data"}
      </Modal.Body>
      <Modal.Footer className="p-1 bg-dark">
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default StudyFindingDialog;
