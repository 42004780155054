import { createContext, useEffect, useState } from "react";
import { useUserPreferences } from "../Utilities/FetchHooks/Pmed/PmedHooks";
import { setUserPreferences as setUserPreferencesQuery } from "../Utilities/FetchHooks/Helpers/FetchFunctions/pmed/PmedFetches";
import { useKeycloak } from "@react-keycloak/web";
import { NotificationManager } from "react-notifications";

export const UserPreferencesContext = createContext();

const DEFAULT_STARTING_PAGE = "/PatientJournal";
const DEFAULT_THEME = "light";

const defaultPrefs = {
  LANG: window.conf.LANG,
  STARTING_PAGE: DEFAULT_STARTING_PAGE,
  THEME: DEFAULT_THEME,
  VIEWER_TYPE: window.conf.VIEWER_TYPE,
  PATIENT_JOURNAL: {
    DOSE_WINDOW_MODE: window.conf.PATIENT_JOURNAL.DOSE_WINDOW_MODE,
    SHOW_DOSE_EDIT_FIELDS: window.conf.PATIENT_JOURNAL.SHOW_DOSE_EDIT_FIELDS,
    BLOCK_WRONG_PAT_ID_INPUT:
      window.conf.PATIENT_JOURNAL.BLOCK_WRONG_PAT_ID_INPUT,
  },
  EXAM_ARCHIVE: {
    defaultSort: window.conf.EXAM_ARCHIVE.defaultSort,
    defaultDaysBack: window.conf.EXAM_ARCHIVE.defaultDaysBack,
  },
  EXAM_PLANNER: {
    BODY_PART_SELECTION_MODE: window.conf.EXAM_PLANNER.BODY_PART_SELECTION_MODE,
    AUTO_CLOSE_AFTER_SAVE_SUCCESS:
      window.conf.EXAM_PLANNER.AUTO_CLOSE_AFTER_SAVE_SUCCESS,
    AUTO_CLEAR_PAT_AFTER_ORDER:
      window.conf.EXAM_PLANNER.AUTO_CLEAR_PAT_AFTER_ORDER,
    AFTER_ORDER_GO_BACK_TO_JOURNAL:
      window.conf.EXAM_PLANNER.AFTER_ORDER_GO_BACK_TO_JOURNAL,
    SHOW_INSTRUCTIONS_ON_SAVE:
      window.conf.EXAM_PLANNER.SHOW_INSTRUCTIONS_ON_SAVE,
  },
  EXAM_HELPER: window.conf.EXAM_HELPER,
  MENU: window.conf.MENU,
};

function UserPreferencesProvider({ children }) {
  const [loadingEnabled, setLoadingEnabled] = useState(true);
  const { keycloak } = useKeycloak();
  const UID = keycloak.tokenParsed?.pmed_mid || 0;
  const userPreferencesQuery = useUserPreferences({
    userId: UID,
    enabled: loadingEnabled,
  });
  const userPreferencesFromLocalStorage =
    JSON.parse(localStorage.getItem(`user_preferences_${UID}`)) || null;
  const [userPreferences, setUserPreferences] = useState(
    userPreferencesFromLocalStorage
  );

  const handleSetUserPreferences = (newUserPreferences) => {
    localStorage.setItem(
      `user_preferences_${UID}`,
      JSON.stringify(newUserPreferences)
    );
    setUserPreferencesQuery(keycloak, {
      userId: UID,
      data: newUserPreferences,
    }).then((res) => {
      if (res) {
        NotificationManager.success("Preferences saved successfully");
        setUserPreferences({ ...newUserPreferences });
      } else {
        NotificationManager.error("Failed to save preferences");
      }
    });
  };

  useEffect(() => {
    if (!userPreferencesQuery.isLoading) {
      if (userPreferencesQuery.data) {
        setLoadingEnabled(false);
        setUserPreferences(userPreferencesQuery.data);
        if (!userPreferencesFromLocalStorage) {
          localStorage.setItem(
            `user_preferences_${UID}`,
            JSON.stringify(userPreferencesQuery.data)
          );
        }
      } else setUserPreferences(defaultPrefs);
    }
  }, [userPreferencesQuery.data, userPreferencesQuery.isLoading]);

  return (
    <UserPreferencesContext.Provider
      value={{
        userPreferences,
        setUserPreferences: handleSetUserPreferences,
      }}
    >
      {userPreferences == null ? <div>Loading...</div> : children}
    </UserPreferencesContext.Provider>
  );
}

export default UserPreferencesProvider;
