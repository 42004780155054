import PropTypes from "prop-types";
import SimpleExamRow from "./SimpleExamRow.jsx/SimpleExamRow";
import { ClipLoader } from "react-spinners";

import styles from "./StudiesList.module.scss";

function StudiesList({
  status,
  studies,
  allowSelect = false,
  selectedStudies = [],
  handleStudySelect = () => {},
}) {
  const gridTemplate = allowSelect
    ? {
        gridTemplateColumns: "30px 50px 50px 50px 1fr 1fr 1fr 4fr 1fr 2fr",
      }
    : {
        gridTemplateColumns: "50px 50px 50px 1fr 1fr 1fr 4fr 1fr 2fr",
      };
  return (
    <>
      <div className={styles.wrapper}>
        {status === "loading" ? (
          <div className={styles.message}>
            <ClipLoader color={"#dbdbdb"} loading={true} size={30} />
            Loading...
          </div>
        ) : status === "error" || !studies ? (
          <div className={styles.message}>No data</div>
        ) : (
          studies.map((study) => (
            <SimpleExamRow
              study={study}
              allowSelect={allowSelect}
              selectedStudies={selectedStudies}
              handleStudySelect={handleStudySelect}
              gridTemplate={gridTemplate}
            />
          ))
        )}
      </div>
    </>
  );
}

StudiesList.propTypes = {
  studies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      modality: PropTypes.string.isRequired,
      patient: PropTypes.shape({
        patientssex: PropTypes.string.isRequired,
        patientsname: PropTypes.string.isRequired,
        patientsvorname: PropTypes.string.isRequired,
        patientid: PropTypes.string.isRequired,
      }).isRequired,
      studydescription: PropTypes.string.isRequired,
      studydate: PropTypes.string.isRequired,
    })
  ).isRequired,
  allowSelect: PropTypes.bool,
  selectedStudies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  handleStudySelect: PropTypes.func,
};

export default StudiesList;
