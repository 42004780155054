import { useEffect, useState } from "react";
import { Button, Row, Col, Container, Card } from "react-bootstrap";
import {
  FacilitySummaryReport,
  DemographicReport,
  RefPhysSummaryReport,
  FacilityBaseSummaryReport,
  RadiologistReport,
  RowBasedTemplate,
  RowDefinedTemplate,
} from "./pdfTemplates/pdfTemplates";
import { loadReports } from "../../Utilities/ReportUtils/ReportUtils";
import { ClipLoader } from "react-spinners";
import ReportRow from "./ReportRow";
import "./ReportArchive.css";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

export default function ReportArchive(props) {
  const [isReady, setIsReady] = useState(false);
  const [reports, setReports] = useState([]);
  const [data, setData] = useState(null);
  const [variant, setVariant] = useState("FacReportVariants.ref_fac_summary");
  const [isPdfPrevievVisible, setisPdfPreviewVisible] = useState(false);

  //pagination
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { t, i18n } = useTranslation(["reports", "common"]);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const variant_map = {
    // facilities
    "FacReportVariants.ref_fac_summary": FacilityBaseSummaryReport,
    "FacReportVariants.ref_fac_detailed_summary": FacilitySummaryReport,
    "FacReportVariants.study_type_summary": FacilityBaseSummaryReport,
    "FacReportVariants.inc_prc": FacilityBaseSummaryReport,
    "FacReportVariants.ref_fac_summary.single": FacilityBaseSummaryReport,
    "FacReportVariants.ref_fac_detailed_summary.single": FacilitySummaryReport,
    "FacReportVariants.study_type_summary.single": FacilityBaseSummaryReport,
    "FacReportVariants.inc_prc.single": FacilityBaseSummaryReport,
    // refphys
    "RefPhysReportVariants.detailed_summary": RefPhysSummaryReport,
    "RefPhysReportVariants.basic_summary": RefPhysSummaryReport,
    "RefPhysReportVariants.modality_type_summary": RefPhysSummaryReport,
    "RefPhysReportVariants.detailed_summary.single": RefPhysSummaryReport,
    "RefPhysReportVariants.basic_summary.single": RefPhysSummaryReport,
    "RefPhysReportVariants.modality_type_summary.single": RefPhysSummaryReport,
    //others
    "RadiologistReportVariants.basic_summary": RadiologistReport,
    "RadiologistReportVariants.detailed_summary": RadiologistReport,
    "DemographicReportVariants.local_orders": DemographicReport,
    "DemographicReportVariants.external_orders": DemographicReport,
    "DemographicDoseReportVariants.external_orders": DemographicReport,
    "DemographicDoseReportVariants.local_orders": DemographicReport,
    "PatRecordReport.pat_record": DemographicReport,
    external_orders_report: RowBasedTemplate,
  };

  const CurrentTemplate = variant_map[variant] || RowDefinedTemplate;

  const toggle_pdf = () => {
    setisPdfPreviewVisible(!isPdfPrevievVisible);
  };

  useEffect(() => {
    loadRep();
  }, []);

  useEffect(() => {
    loadRep();
  }, [currentPage]);

  const load_pdf = (data, variant) => {
    console.log(variant);
    setVariant(variant);
    setData(data);
    toggle_pdf();
  };

  const loadRep = () => {
    loadReports(props.keycloak, pageSize, currentPage)
      .then((reports) => {
        setTotalCount(reports?.total_count);
        setReports(reports?.reports);
        setIsReady(true);
      })
      .catch((reason) => {
        setIsReady(false);
        NotificationManager.error("Unable to load Reports");
      });
  };

  const printPDF = () => {
    var mywindow = window.open("", "PRINT", "height=400,width=600");
    var toPrint = document.getElementById("pdf").innerHTML;
    mywindow.document.write(
      "<html><head><title>" + document.title + "</title>"
    );
    mywindow.document.write(
      "<style>table.minimalistBlack{border: 2px solid #000000; width: 100%; text-align: left; border-collapse: collapse;}table.minimalistBlack td,table.minimalistBlack th{border: 1px solid #000000; padding: 5px 4px;}table.minimalistBlack tbody td{font-size: 14px;}table.minimalistBlack thead{background: #cfcfcf; background: -moz-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #cfcfcf 100%); background: -webkit-linear-gradient( top, #dbdbdb 0%, #d3d3d3 66%, #cfcfcf 100% ); background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #cfcfcf 100%); border-bottom: 2px solid #000000;}table.minimalistBlack thead th{font-size: 16px; font-weight: bold; color: #000000; text-align: left;}table.minimalistBlack tfoot td{font-size: 12px;} .rowbased table,th,td {border: 1px solid black}</style>"
    );
    mywindow.document.write("</head><body >");
    mywindow.document.write("<h1>" + document.title + "</h1>");
    mywindow.document.write(toPrint);
    mywindow.document.write("</body></html>");
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();

    return true;
  };

  return (
    <Container fluid className="mx-0 px-3 my-0 py-2">
      <Row className="mb-4 justify-content-center">
        <Col md={10} sm={12}>
          <Card
            className={
              isPdfPrevievVisible
                ? "report-archive-card hidden"
                : "report-archive-card shown"
            }
          >
            <Card.Header className="p-2">
              <h4 className="mr-3">{t("archive.title")}</h4>
              {!isReady && <ClipLoader />}
            </Card.Header>
            <Card.Body className="scrollable-reports">
              {isReady ? (
                reports?.map((report, key) => (
                  <ReportRow
                    key={`reportkey${key}`}
                    dataSetter={setData}
                    variantSetter={setVariant}
                    keycloak={props.keycloak}
                    pdfLoader={load_pdf}
                    report_data={report}
                    assignedVariant={report.report_variant}
                  />
                ))
              ) : (
                <h5>{t("common:loading")}</h5>
              )}
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between">
              <div className="d-flex" style={{ gap: "2px" }}>
                <Button
                  size="sm"
                  disabled={currentPage <= 1}
                  onClick={() => {
                    setCurrentPage(1);
                  }}
                >
                  <i className="fas fa-angle-double-left"></i>
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                  disabled={currentPage <= 1}
                >
                  <i className="fas fa-angle-left"></i>
                </Button>
              </div>
              <div
                className="page-status border rounded p-1"
                style={{ backgroundColor: "#ff880d", color: "white" }}
              >
                <div className="d-flex">
                  {currentPage} <span className="font-weight-bold mx-1">/</span>{" "}
                  {Math.ceil(totalCount / pageSize)}
                </div>
              </div>
              <div className="d-flex" style={{ gap: "2px" }}>
                <Button
                  size="sm"
                  disabled={
                    currentPage === Math.ceil(totalCount / pageSize) - 1
                  }
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                >
                  <i className="fas fa-angle-right"></i>
                </Button>
                <Button
                  size="sm"
                  disabled={
                    currentPage === Math.ceil(totalCount / pageSize) - 1
                  }
                  onClick={() => {
                    setCurrentPage(Math.ceil(totalCount / pageSize) - 1);
                  }}
                >
                  <i className="fas fa-angle-double-right"></i>
                </Button>
              </div>
            </Card.Footer>
          </Card>
          <Card
            className={
              isPdfPrevievVisible
                ? "report-archive-card shown"
                : "report-archive-card hidden"
            }
          >
            <Card.Header>
              <Row>
                <Col md={6}>
                  <h4>{t("archive.preview")}</h4>
                </Col>
                <Col className="text-right">
                  <Button onClick={toggle_pdf}>
                    {t("archive.hidePreview")}
                  </Button>
                  <Button onClick={printPDF} className="ml-2">
                    {t("archive.print")}
                    <i className="fas fa-print ml-2"></i>
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body id="pdf">
              {data != null && (
                <CurrentTemplate data={data} variant={variant} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
