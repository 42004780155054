import "./LayingCaudal.scss";

function LayingCaudal({ angle }) {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 283.5 283.5"
    >
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          data-name="Unbenannter Verlauf"
          x1="704.9"
          y1="-643.3"
          x2="667.8"
          y2="-643.3"
          gradientTransform="translate(783.5 -446) rotate(90) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".3" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_2"
          data-name="Unbenannter Verlauf 2"
          x1="1637.9"
          y1="-622.1"
          x2="1678"
          y2="-622.1"
          gradientTransform="translate(1454.3 1236) rotate(-159.3) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_3"
          data-name="Unbenannter Verlauf 3"
          x1="1631.1"
          y1="-659.1"
          x2="1678.7"
          y2="-659.1"
          gradientTransform="translate(1454.2 1236) rotate(-159.3) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#31302f" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#010101" />
        </linearGradient>
      </defs>
      <g id="Ebene_11" data-name="Ebene_1">
        <g id="Ebene_1-2">
          <g id="Ebene_1-21" data-name="Ebene_1-2">
            <g id="a">
              <rect
                class="layingcaudal3"
                x=".6"
                y="0"
                width="283.5"
                height="283.5"
              />
              <rect
                class="layingcaudal5"
                x="8.5"
                y="255.1"
                width="262.6"
                height="16.7"
              />
              <g>
                <line
                  class="layingcaudal2"
                  x1="138"
                  y1="35.6"
                  x2="138"
                  y2="39.4"
                />
                <line
                  class="layingcaudal8"
                  x1="138"
                  y1="46.6"
                  x2="138"
                  y2="202.7"
                />
                <line
                  class="layingcaudal2"
                  x1="138"
                  y1="206.3"
                  x2="138"
                  y2="210.1"
                />
              </g>
              <path
                class="layingcaudal7"
                d="M218.9,256.8c1.3-.3,11.2-4.9,14.5-9.2,7.6-1.1,15.9,5.7,22,5.6,10.2-.9,12.1-3.5,12.3-13.3-1.1-12.1-11.5-15.3-12.3-14.8-1.4.8-2.7.8-3.5.4-2.7-1.6-3.7-3.5-6.2-3.1l-.2,2.7c-2.1-.8-3.7-.5-4.2-.2-.2.7-.8.5-1.1.9-1.4.1-2,.2-3.7.3-.6,0-1.5,1.8-.4,6,.4,0-2.2,5.3-14.2,3.9-22.2-16.7-28.5-9-28.5-9l-63.7,3.1c-11.6-1.1-39.5,5.7-47.8,7.5-17.8,3.9-42.5,4.2-55.6,4.2-11.4-4-9.7-13.1-12.1-19.2-2.9-3.1-1,30.1-1.5,27.4.9,2.9,7-.4,15-.3,10.9,0,21.9,4.2,32.6,4.6,8.8,0,16.4-.3,21-2,10.8,2.5,22.7,2.7,29.3,2.2,3.5.3,8.2-.4,14.6-2.5,7.4,3.2,11.7,5,16.4,6.1,8.8,1.6,13.5-6.4,25.1-6.4,5.5-.9,21.5,5.3,37.2,6.4l14.9-1.3h0Z"
              />
              <line
                class="layingcaudal2"
                x1="138.2"
                y1="34.3"
                x2="13.3"
                y2="188.8"
              />
              <g>
                <path
                  class="layingcaudal2"
                  d="M134.6,209.6c-40.5,4.9-78.7-.2-113.2-17.2"
                />
                <path
                  class="layingcaudal9"
                  d="M14.6,188.8c3.8.4,8.8.5,12.4-.4l-4.7,4.4-.8,6.3c-1.3-3.4-4.3-7.5-6.8-10.3h0Z"
                />
              </g>
              <g>
                <polygon
                  class="layingcaudal4"
                  points="99.4 74.6 137.3 88.1 137.2 57.6 121.2 51.9 99.4 74.6"
                />
                <path
                  class="layingcaudal6"
                  d="M115.8,30.4c2.5-12.4,15.1-20.6,28.1-18.3,13,2.3,21.5,14.2,19,26.6-2.5,12.4-15.1,20.6-28.1,18.3s-21.5-14.2-19-26.6h0Z"
                />
              </g>
            </g>
            <g class="layingcaudal1">
              <g class="layingcaudal1">
                <g class="layingcaudal1">
                  <text class="layingcaudal0" transform="translate(44.7 185.5)">
                    <tspan x="0" y="0">
                      {angle}°
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LayingCaudal;
