import { EXAM_GUIDE_BREATH_COMMAND } from "../../../../Pages/Consts/ExamHelperRoutes";
import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import ExamsSection from "../ExamsSection";
import ExamHelperContentWrapper from "../../../../Blueprints/ExamHelper/ContentWrapper/ContentWrapper";
import { useSpeech } from "react-text-to-speech";
import { useExamination } from "../../../../Utilities/FetchHooks/Ris/RisHooks";
import useCommandSteps from "./useCommandSteps/useCommandSteps";
import useActiveExam from "../../Hooks/useActiveExam";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ExamHelperContext } from "../../../../Pages/ExamHelper";
import { Button } from "react-bootstrap";

import styles from "./BreathCommand.module.scss";

const VOICES_MAP = {
  pl: {
    lang: "pl-PL",
    voiceUri: "Google polski",
    name: "Polski",
  },
  de: {
    lang: "de-DE",
    voiceUri: "Google Deutsch",
    name: "Deutsch",
  },
  gb: {
    lang: "en-US",
    voiceUri: "Google US English",
    name: "English",
  },
  nl: {
    lang: "nl-NL",
    voiceUri: "Google Nederlands",
    name: "Nederlands",
  },
  tur: {
    lang: "tr-TR",
    voiceUri: "Yelda",
    name: "Türkçe",
  },
};

const CMD_LANG = {
  de: "cmdDE",
  pl: "cmdPL",
  en: "cmdEN",
  gb: "cmdEN",
  nl: "cmdNL",
  tur: "cmdTR",
};

function BreathCommandContent({ examId }) {
  const { t } = useTranslation(["examHelper", "common"]);
  const examData = useExamination({ examId, enabled: !!examId });
  const commands = useCommandSteps(examData?.data?.pat_cmd);
  const { patientSex, setPatientSex } = useContext(ExamHelperContext);

  return (
    <ExamHelperContentWrapper>
      <div className={styles.heading}>
        <h1>
          {examData?.data?.designationde
            ? examData?.data?.designationde
            : examData.isFetching
            ? t("loading", { ns: "common" })
            : t("breathCommand.title")}
        </h1>
        <Button
          onClick={() => setPatientSex(patientSex === "men" ? "woman" : "men")}
        >
          <i
            className={`fa-lg ${
              patientSex === "men" ? "fas fa-mars" : "fas fa-venus"
            }`}
          />
        </Button>
      </div>
      <div className={styles.tileWrapper}>
        {commands &&
          commands.map((command) => (
            <StepTile key={`stepTile_${command.cmdDE}`} command={command} />
          ))}
      </div>
    </ExamHelperContentWrapper>
  );
}

function StepTile({ command, idx }) {
  const { activeLang, patientSex } = useContext(ExamHelperContext);
  const langPicker = CMD_LANG[activeLang];
  const { lang, voiceUri } = VOICES_MAP[activeLang] || VOICES_MAP["de"];
  const {
    speechStatus, // String that stores current speech status
    start, // Function to start the speech or put it in queue
    stop, // Function to stop the speech or remove it from queue
  } = useSpeech({ text: command[langPicker], voiceURI: voiceUri, lang: lang });

  const imgPicker = `image${patientSex}`;

  const handleClick = () => {
    if (speechStatus !== "started") {
      start();
    }
    stop();
  };
  return (
    <div
      key={`${idx}${command[langPicker]}`}
      className={`${styles.stepTile}`}
      onClick={handleClick}
    >
      <div className={styles.playback}>
        {speechStatus !== "started" && (
          <i className="fas fa-play" onClick={start} />
        )}
        {speechStatus === "started" && (
          <>
            <i className="fas fa-stop" />
          </>
        )}
      </div>
      <h3 className={styles.title}>
        {command[langPicker] ? command[langPicker] : command["de"]}
      </h3>
      {command[imgPicker] && (
        <img src={command[imgPicker]} alt={command[langPicker]} />
      )}
    </div>
  );
}

function BreathCommand(props) {
  const { activeExam, setExam } = useActiveExam({
    link: EXAM_GUIDE_BREATH_COMMAND.link,
  });

  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection selectedExam={activeExam} selectedExamSetter={setExam} />
      }
      rightComponent={<BreathCommandContent examId={activeExam} />}
    />
  );
}

export default BreathCommand;
