import React, { useEffect, useState } from "react";
import "./App.scss";
import keycloak from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import reactManifest from 'react-manifest';
import getPMEDFingerprint from "./Utilities/PMEDFingerprint";

import RisNet from "./RisNet";
import ThemeProvider from "./Utilities/ThemeProvider";

function setTokens(token, id_token, refresh_token) {
  localStorage.setItem("token", token);
  localStorage.setItem("id_token", id_token);
  localStorage.setItem("refresh_token", refresh_token);
}

function getTokens(token, id_token, refresh_token) {
  return [localStorage.getItem("token"), localStorage.getItem("refresh_token")];
}

const queryClient = new QueryClient();

function App() {
  const [token, refreshToken] = getTokens();
  const [PMEDfingerprint, setPMEDfingerprint] = useState(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) 
    let fp = params.get('fp') 

    getPMEDFingerprint(fp).then((fingerprint) => {
      setPMEDfingerprint(fingerprint);  
    })

}, [])

  useEffect(() => {
    const manifestDetails = {
      "short_name": "pmRisNet",
      "name": "PergamonMED Web Ris",
      "icons": [
        {
          "src": `${new URL(`/favicon.ico`, window.location.origin).href}`,
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/x-icon"
        },
        {
          "src":`${new URL(`/android-chrome-144x144.png`, window.location.origin).href}`,
          "sizes": "144x144",
          "type": "image/png"
        }
      ],
      "start_url": `${new URL(`/?fp=${PMEDfingerprint}`, window.location.origin).href}`,
      "display": "standalone",
      "theme_color": "#000000",
      "background_color": "#ffffff"
    };
  
    reactManifest.update(manifestDetails, "#manifest-placeholder");

  }, [PMEDfingerprint]); 

  return (
    <Provider store={store}>
      <ReactKeycloakProvider
        authClient={keycloak}
        onTokens={(tokens) =>
          setTokens(
            tokens.token ?? "",
            tokens.idToken ?? "",
            tokens.refreshToken ?? ""
          )
        }
        initOptions={{ onLoad: "login-required", token, refreshToken }}
      >
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <RisNet />
            {window.conf.DEBUG_MODE && <ReactQueryDevtools />}
          </QueryClientProvider>
        </ThemeProvider>
      </ReactKeycloakProvider>
    </Provider>
  );
}

export default App;
