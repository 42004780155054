import { getFetch, MakeUrl, REST_URL } from "../../FetchWrapper";

export async function getStudies(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/ris/studies`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getStudy(keycloak, params) {
  if (!params.studyId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/studies/${params.studyId}`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getStudyKartei(keycloak, params) {
  if (!params.studyId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/studies/${params.studyId}/kartei`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getStudySeries(keycloak, params) {
  if (!params.studyId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/studies/${params.studyId}/series`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getStudyFindings(keycloak, params) {
  if (!params.studyId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/studies/${params.studyId}/findings`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getStudyDose(keycloak, params) {
  if (!params.studyId) {
    return {};
  }
  const url = MakeUrl(
    `${REST_URL}/ris/studies/${params.studyId}/dosecollective`
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getStudyWorklistInfo(keycloak, params) {
  if (!params.studyId) {
    return {};
  }
  const url = MakeUrl(
    `${REST_URL}/ris/studies/${params.studyId}/worklist/info`
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getStudyWorklist(keycloak, params) {
  if (!params.studyId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/studies/${params.studyId}/worklist`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getStudySerieImagePreview(keycloak, params) {
  if (!params.imageId) {
    return {};
  }
  const url = MakeUrl(
    `${REST_URL}/ris/studies/series/images/${params.imageId}/preview`
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getStudySerieImageContour(keycloak, params) {
  if (!params.imageId) {
    return {};
  }
  const url = MakeUrl(
    `${REST_URL}/ris/studies/series/images/${params.imageId}/contour`
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getStudyStatus(keycloak, params) {
  if (!params.studyId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/studies/${params.studyId}/status`);
  return getFetch(url, keycloak, params.xmask);
}
