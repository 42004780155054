import AddPatientButton from "./AddPatient";
import AddEmergencyPatientButton from "./AddEmergencyPatient";
import React from "react";
import PatientsTable from "./PatientListTable";
import AuthorizedElement from "../Utilities/AuthorizedElement";
import useWindowSize from "../Utilities/Window/windowSize";

function PatientSearchBox({ amount }) {
  const { width } = useWindowSize();

  return (
    <div
      className="d-flex flex-column bg-light rounded"
      style={{
        flex: 1,
        // height: "100%",
        maxHeight: width > 1199 ? "99vh" : "89vh",
        alignItems: "stretch",
        overflowY: "auto",
      }}
    >
      <PatientsTable pageSize={amount} />
      <AuthorizedElement roles={["pm_admin", "pm_user"]}>
        <div className="d-flex mt-auto p-1 justify-content-between">
          <AddPatientButton />
          <AddEmergencyPatientButton />
        </div>
      </AuthorizedElement>
    </div>
  );
}

export default PatientSearchBox;
