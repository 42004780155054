import { Modal, Row, Col, Button, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { NotificationManager } from "react-notifications";
import "./stylesheets/DeletePatientModal.scss";
import { DeletePatient } from "../../Utilities/Patients/PatientUtils";
import {
  clearOpenedPatient,
  clearSelectedExaminations,
  setReloadPatientListFlag,
} from "../../Redux/actions";
import { useTranslation } from "react-i18next";

export default function DeletePatientModal({
  shown,
  headerless = false,
  close,
}) {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const { t } = useTranslation(["examplanner", "common"]);

  const selectedPatient = useSelector(
    (state) => state.patientView.selectedPatient
  );

  const send = () => {
    let data = selectedPatient;
    DeletePatient(keycloak, data)
      .then((data) => {
        NotificationManager.success(t("alert.delPatSuccess"));
        dispatch(clearOpenedPatient());
        dispatch(clearSelectedExaminations());

        // waiting for integration
        dispatch(setReloadPatientListFlag(true));

        close();
      })
      .catch((reason) => {
        NotificationManager.error(t("alert.delePatFailed"));
        console.warn(reason);
      });
  };

  return (
    <Modal
      className="top-modal"
      show={shown}
      onHide={close}
      size={"md"}
      onClick={(e) => e.stopPropagation()}
    >
      {!headerless && (
        <Modal.Header className="py-2 bg-light">
          <h4>{t("areYouSure", { ns: "common" })}</h4>
        </Modal.Header>
      )}
      <Modal.Body className="text-center ">
        <Container>
          {t("alert.deletePatAlert")}
          <Row>
            <Col>
              <Button onClick={close}>
                {t("buttons.cancel", { ns: "common" })}
              </Button>
            </Col>
            <Col>
              <Button variant="danger" onClick={send}>
                {t("buttons.delete", { ns: "common" })}
                <i className="fas fa-trash ml-2"></i>
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
