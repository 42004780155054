import { EXAM_GUIDE_NO_WORDS } from "@Pages/Consts/ExamHelperRoutes";
import TwoPartsDynamic from "@Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import ExamHelperContentTab from "@Blueprints/ExamHelper/ImageSwitchingTab/ImageSwitchingTab";
import { useExamination } from "@Loaders/Ris/RisHooks";
import { Button } from "react-bootstrap";

import useActiveExam from "../../Hooks/useActiveExam";
import UseNowWordsData from "./hooks/useNoWordsData";
import ExamsSection from "../ExamsSection";

import { useTranslation } from "react-i18next";

export function ExamGuideNW(props) {
  const { activeExam, setExam } = useActiveExam({
    link: EXAM_GUIDE_NO_WORDS.link,
  });
  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection selectedExam={activeExam} selectedExamSetter={setExam} />
      }
      rightComponent={<ExamGuideNoWords selectedExam={activeExam} />}
    />
  );
}

function ExamGuideNoWords({ selectedExam }) {
  const { t } = useTranslation(["examHelper", "common"]);
  const { data: examData } = useExamination({
    examId: selectedExam,
    enabled: selectedExam != null,
  });
  const title = !selectedExam
    ? t("actions.selectExam", { ns: "common" })
    : examData
    ? examData.designationde
    : t("loading", { ns: "common" });

  const { Image, isMainView, setMainView } = UseNowWordsData({
    examId: selectedExam,
  });

  return (
    <ExamHelperContentTab
      title={<h3>{title}</h3>}
      image={Image}
      description={
        !isMainView && (
          <Button onClick={setMainView}>
            <i className="fas fa-arrow-left pr-2" />
            {t("back", { ns: "common" })}
          </Button>
        )
      }
    />
  );
}
