import TabSubMenu from "../Blueprints/TabSubMenu/TabSubMenu";
import { EXAM_HELPER_ROUTES } from "./Consts/ExamHelperRoutes";
import { createContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ExamHelperContext = createContext(null);

function ExamHelper() {
  const location = useLocation();
  const q = new URLSearchParams(location.search).get("exams_selected");
  const searchParams = q ? q.split(",") : [];

  const [activeExam, setActiveExam] = useState(null);
  const { t } = useTranslation(["examHelper"]);
  const [examListShrinked, setExamListShrinked] = useState(false);
  const [allowedExams, setAllowedExams] = useState(
    searchParams.map((e) => parseInt(e))
  );
  const [patientSex, setPatientSex] = useState("woman");
  const [activeLang, setActiveLang] = useState(
    window.conf.LANG.toLowerCase() || "de"
  );

  return (
    <ExamHelperContext.Provider
      value={{
        examListShrinked,
        setExamListShrinked,
        activeExam,
        setActiveExam,
        allowedExams,
        setAllowedExams,
        patientSex,
        setPatientSex,
        activeLang,
        setActiveLang,
      }}
    >
      <TabSubMenu subMenuItems={EXAM_HELPER_ROUTES} translator={t} />
    </ExamHelperContext.Provider>
  );
}

export default ExamHelper;
