import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import usePmedFilter from "../../../Utilities/Filtering/usePmedFilter";

function BaseSearch({ field, filterSetter, className = "" }) {
  const [searchPhrase, setSearchPhrase] = useState("");
  const { filter, updateFilter } = usePmedFilter({
    field,
    searchPhrase: searchPhrase,
    manual: true,
  });

  useEffect(() => {
    filterSetter(filter);
  }, [filter]);

  return (
    <div className={`d-flex ${className}`}>
      <input
        className="my-2 mr-1 border rounded"
        type="search"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updateFilter();
          }
        }}
        onChange={(e) => {
          setSearchPhrase(e.target.value);
          if (e.target.value === "") {
            updateFilter();
          }
        }}
      />
      <Button className="my-2 mr-2" onClick={updateFilter}>
        <i className="fas fa-search" />
      </Button>
    </div>
  );
}

export default BaseSearch;
