import TwoPartsDynamic from "../../../../Blueprints/ExamHelper/TwoPartsDynamic/TwoPartsDynamic";
import ExamsSection from "../ExamsSection";
import { EXAM_GUIDE_PATIENT_PREP } from "../../../../Pages/Consts/ExamHelperRoutes";
import ExamHelperContentWrapper from "../../../../Blueprints/ExamHelper/ContentWrapper/ContentWrapper";
import useExamPreparationImage from "./hooks/useExamPreparationImage";
import { useExamination } from "../../../../Utilities/FetchHooks/Ris/RisHooks";
import useExamPreparationDirections from "./hooks/useExamPreparationDirections";
import { useContext } from "react";
import { useSpeech } from "react-text-to-speech";
import useActiveExam from "../../Hooks/useActiveExam";
import Flag from "react-world-flags";

import styles from "./PatientPreparation.module.scss";
import { Button, Dropdown } from "react-bootstrap";
import { ExamHelperContext } from "../../../../Pages/ExamHelper";

const VOICES_MAP = {
  pl: {
    lang: "pl-PL",
    voiceUri: "Google polski",
    name: "Polski",
  },
  de: {
    lang: "de-DE",
    voiceUri: "Google Deutsch",
    name: "Deutsch",
  },
  gb: {
    lang: "en-US",
    voiceUri: "Google US English",
    name: "English",
  },
  nl: {
    lang: "nl-NL",
    voiceUri: "Google Nederlands",
    name: "Nederlands",
  },
  tur: {
    lang: "tr-TR",
    voiceUri: "Yelda",
    name: "Türkçe",
  },
};

function PatPrepContent({ examId }) {
  const {
    patientSex: selectedGender,
    setPatientSex: setSelectedGender,
    activeLang,
    setActiveLang,
  } = useContext(ExamHelperContext);

  const examData = useExamination({ examId, enabled: !!examId });
  const image = useExamPreparationImage(examId, selectedGender);
  const directions = useExamPreparationDirections(
    examData?.data?.pat_prep,
    activeLang
  );
  const voiceToUse = VOICES_MAP[activeLang];

  return (
    <ExamHelperContentWrapper>
      <div className={styles.heading}>
        <h1>
          {" "}
          {examData?.data?.designationde
            ? examData?.data?.designationde
            : "Patient Preparation"}
        </h1>
        <Button
          className="ml-auto"
          size="lg"
          onClick={() => {
            setSelectedGender((prev) => (prev === "woman" ? "men" : "woman"));
          }}
        >
          <i
            className={`fas ${
              selectedGender === "woman" ? "fa-venus" : "fa-mars"
            } fa-lg`}
          />
        </Button>
        <Dropdown size="lg" className={`ml-1 ${styles.langDropdown}`}>
          <Dropdown.Toggle id="dropdown-basic">
            <Flag code={activeLang.toUpperCase()} />
            {VOICES_MAP[activeLang].name}
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.dropdownMenu}>
            <Dropdown.Item onClick={() => setActiveLang("de")}>
              <Flag code="DE" />
              Deutsch
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setActiveLang("gb")}>
              <Flag code="GB" />
              English
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setActiveLang("pl")}>
              <Flag code="PL" />
              Polski
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setActiveLang("nl")}>
              <Flag code="NL" />
              Nederlands
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setActiveLang("tur")}>
              <Flag code="TR" />
              Türkçe
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {image && (
        <div className={styles.imageWrapper}>
          <img src={image} alt="Patient Preparation" />
          <div className={styles.directionsWrapper}>
            {directions &&
              directions.map((direction, index) => (
                <SpeechBox
                  key={`spBox_${direction}`}
                  direction={direction}
                  index={index}
                  voiceToUse={voiceToUse}
                />
              ))}
            <NoJewelrlyBox voiceToUse={voiceToUse} lang={activeLang} />
          </div>
        </div>
      )}
    </ExamHelperContentWrapper>
  );
}

function PatientPreparation(props) {
  const { activeExam, setExam } = useActiveExam({
    link: EXAM_GUIDE_PATIENT_PREP.link,
  });

  return (
    <TwoPartsDynamic
      leftComponent={
        <ExamsSection selectedExam={activeExam} selectedExamSetter={setExam} />
      }
      rightComponent={<PatPrepContent examId={activeExam} />}
    />
  );
}

export default PatientPreparation;

function SpeechBox({ direction, index, voiceToUse }) {
  const {
    speechStatus, // String that stores current speech status
    start, // Function to start the speech or put it in queue
    stop, // Function to stop the speech or remove it from queue
  } = useSpeech({
    text: direction,
    voiceURI: voiceToUse.voiceUri,
    lang: voiceToUse.lang,
  });

  return (
    <div
      key={index}
      className={styles.direction}
      style={{ gap: "20px" }}
      onClick={() => {
        if (speechStatus !== "started") {
          start();
        }
        stop();
      }}
    >
      <div className={styles.directionTitle}>{direction}</div>
      <div>
        {speechStatus !== "started" ? (
          <i className="fas fa-play" onClick={start} />
        ) : (
          <i className="fas fa-stop" onClick={stop} />
        )}
      </div>
    </div>
  );
}

const infoDE = "Piercing und Schmuck im Aufnahmebereich ablegen !";
const infoPL = "Proszę zdjąć kolczyki i biżuterię z twarzy!";
const infoGB = "Please remove piercings and jewelry from the recording area!";
const infoNL = "Verwijder piercings en sieraden uit het opnamegebied!";
const infoTUR = "Kayıt alanından piercing ve takıları çıkarın !";

const infoMap = {
  de: infoDE,
  pl: infoPL,
  gb: infoGB,
  nl: infoNL,
  tur: infoTUR,
};

function NoJewelrlyBox({ voiceToUse, lang }) {
  const TEXT = infoMap[lang.toLowerCase()];
  const {
    speechStatus, // String that stores current speech status
    start, // Function to start the speech or put it in queue
    stop, // Function to stop the speech or remove it from queue
  } = useSpeech({
    text: TEXT,
    voiceURI: voiceToUse.voiceUri,
    lang: voiceToUse.lang,
  });
  return (
    <div
      className={styles.jewelryInfo}
      onClick={() => {
        if (speechStatus !== "started") {
          start();
        }
        stop();
      }}
    >
      <i className="fas fa-info-circle" />
      <div>{TEXT}</div>
      <div className="ml-auto">
        {speechStatus !== "started" ? (
          <i className="fas fa-play" onClick={start} />
        ) : (
          <i className="fas fa-stop" onClick={stop} />
        )}
      </div>
    </div>
  );
}
