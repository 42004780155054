import React, { useState } from "react";
import { MakeGetObject } from "../Utilities/AuthHeader";
import { useKeycloak } from "@react-keycloak/web";
import Table from "../Components/Tables/backendTable";
import "./PatientListTable.css";

import {
  setOpenedPatient,
  removeOpenedPatient,
  clearSelectedExaminations,
  setReloadPatientListFlag,
  clearOpenedPatient,
} from "../Redux/actions";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function PatientsTable({
  selectedIds,
  rowClick,
  pageSize = 12,
  idField = "id",
}) {
  const { t, i18n } = useTranslation(["common"]);
  const columns = React.useMemo(() => [
    {
      Header: t("patientname"),
      accessor: "patientsname",
      Cell: ({ row }) =>
        row.original.patientsname + " " + row.original.patientsvorname,
    },
    // {
    //   Header: "First Name",
    //   accessor: "patientsvorname",
    // },
    {
      Header: t("id"),
      accessor: "patientid",
      Cell: ({ row }) => {
        if (window.conf.LANG === "PL") {
          return row.original.patientid;
        } else {
          return row.original.patientid2;
        }
      },
    },
    {
      Header: t("age"),
      accessor: "gebdat",
      Cell: ({ row }) => {
        return (
          <>
            <div>
              {t("yearOldPref")} {ageCalc(row.original.gebdat)}{" "}
              {t("yearOldSuf")}
            </div>
            <div>{row.original.gebdat}</div>
          </>
        );
      },
    },
    {
      Header: t("bSex"),
      accessor: "patientssex",
      Cell: ({ row }) => {
        let selected = (
          <i className="fas fa-genderless fa-2x mt-1 pl-2 mr-2"></i>
        );
        if (row.original.patientssex === "M") {
          selected = <i className="fas fa-mars fa-2x mt-1 pl-2 mr-2"></i>;
        } else if (row.original.patientssex === "F") {
          selected = <i className="fas fa-venus fa-2x mt-1 pl-2 mr-2"></i>;
        }
        return selected;
      },
    },
  ]);

  const self = PatientsTable;
  const { keycloak } = useKeycloak();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const journalReloadPatientsFlag = useSelector(
    (state) => state.journalFlags.reloadPatientList
  );
  const fetchIdRef = React.useRef(0);
  const noDataInfo = [
    {
      id: 0,
      patientid: 0,
      patientid2: "string",
      patientsname: "No More",
      patientsvorname: "Data available",
      gebdat: "1900-01-01",
      patientssex: "O",
    },
  ];

  const loadData = React.useCallback(
    ({ pageSize, pageIndex, sortBy }, filerValue) => {
      //apply global filter to request only if search phrase is set
      const buildFilter = () => {
        if (filerValue !== "") {
          //setPageIndex to 0 here only if filtering is active to see 1st page before loading data
          setPageIndex(0);
          const searchValue = `%${filerValue}%`;
          const filter = {
            or: [
              { field: "patientsname", op: "like", value: searchValue },
              { field: "patientsvorname", op: "like", value: searchValue },
              { field: "gebdat", op: "like", value: searchValue },
              { field: "patientid", op: "like", value: searchValue },
            ],
          };
          return `&search=${encodeURIComponent(JSON.stringify(filter))}`;
        } else {
          return "";
        }
      };

      //returns sorting query
      const buildSorting = () => {
        if (sortBy.length > 0) {
          setPageIndex(0);
          return `&sort=${encodeURIComponent(
            JSON.stringify({
              field: sortBy[0].id,
              direction: sortBy[0].desc === true ? "desc" : "asc",
            })
          )}`;
        } else {
          return "";
        }
      };

      //builds url for request with pagination filters and sorting
      const buildURL = () => {
        return `${
          window.conf.SERVER_CONFIG.BASE_URL
        }/ris/patients?page_size=${pageSize}&page_number=${
          pageIndex + 1
        }${buildFilter()}${buildSorting()}`;
      };

      const fetchId = ++fetchIdRef.current;
      //set loading state in table
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        setPageIndex(pageIndex);
        try {
          let url = buildURL();
          fetch(url, MakeGetObject(keycloak))
            .then((response) => response.json())
            .then((rdata) => {
              if (!rdata.patients) {
                //no data in response so give table no data info
                setTableData(noDataInfo);
                //jump to first page
                setPageCount(1);
                setItemCount(0);
                //loading finished disable loading state
                setLoading(false);
              } else {
                //provide data to table
                setTableData(rdata.patients);
                //set pageCount
                setPageCount(Math.ceil(rdata.total_count / pageSize));
                setItemCount(rdata.total_count);
                //loading finished disable loading state
                setLoading(false);
              }
            });
        } catch (error) {
          console.warn(error);
        }
      }
      if (journalReloadPatientsFlag) {
        dispatch(setReloadPatientListFlag(false));
      }
    },
    [journalReloadPatientsFlag]
  );

  const dispatch = useDispatch();
  const journalSelectedPatients = useSelector(
    (state) => state.examManager.openedPatients
  );

  const ChooseSelector = () => {
    if (selectedIds) {
      return selectedIds;
    } else {
      return journalSelectedPatients;
    }
  };

  const selectedPatients = ChooseSelector();

  const rowclick = (event, row) => {
    if (rowClick) {
      rowClick(event, row);
    } else {
      if (window.conf.PATIENT_JOURNAL.ALLOW_MULTISELECTION) {
        if (!selectedPatients.includes(row.original.id)) {
          dispatch(setOpenedPatient(row.original.id));
        } else {
          dispatch(removeOpenedPatient(row.original.id));
          dispatch(clearSelectedExaminations());
        }
      } else {
        dispatch(clearSelectedExaminations());
        dispatch(clearOpenedPatient());
        dispatch(setOpenedPatient(row.original.id));
      }
    }
  };

  const ageCalc = (birthdate) => {
    let today = new Date();
    let birthDate = new Date(birthdate);
    let age_now = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };

  return (
    <Table
      columns={columns}
      data={tableData}
      idfield={idField}
      fetchData={loadData}
      loading={loading}
      pageCount={pageCount}
      controlledPageIndex={pageIndex}
      idprefix=""
      itemCount={itemCount}
      rowClickHandler={rowclick}
      selectedids={selectedPatients}
      pageCustomSize={pageSize}
    />
  );
}

export default PatientsTable;
