import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import { PMED_FINGERPRINT } from '../Consts/keys';

const generateRandomString = async (length) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const visitor = await getCurrentBrowserFingerPrint();

    return `${visitor}_${result}`;
  };

export default async function  getPMEDFingerprint(query_fingerprint = null) {
    const pmedFingerprint = localStorage.getItem(PMED_FINGERPRINT);

    if (query_fingerprint) {
        if (pmedFingerprint) {
            if (pmedFingerprint === query_fingerprint){
                return pmedFingerprint;  
            } else {
                localStorage.setItem(PMED_FINGERPRINT, query_fingerprint);   
                return query_fingerprint;   
            } 
        }
    }
    else if (pmedFingerprint) {
        return pmedFingerprint;
    } else {
        const fp = await generateRandomString(10);

        if (fp !== "") {
            localStorage.setItem(PMED_FINGERPRINT, fp);
            return fp;
        }
    }   
  }
  