import { useState, useContext } from "react";
import { FormCheck, Popover, Button, OverlayTrigger } from "react-bootstrap";
import { capitalize } from "@Utils/stringHelpers/stringHelpers";
import { getDDMMYYY_HHMM } from "@Utils/DateTimeUtils/DateTimeUtils";
import ModalitiesIcons from "@Components/ModalitiesIcons/ModalitiesIcons";
import { getGenderIcon } from "@Utils/Patients/PatientUtils";
import { useStudyDose } from "@Loaders/Studies/StudiesHooks";
import { OpenExamInPmView } from "@Utils/ClientServices/ClientServices";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import ViewerSelectModal from "../../ViewerSelectModal/ViewerSelectModal";
import StudyFindingDialog from "../../StudyFindingDialog/StudyFindingDialog";
import { useStudySeries } from "@Utils/FetchHooks/Studies/StudiesHooks";
import { UserPreferencesContext } from "@/Providers/UserPreferencesProvider";

import styles from "./SimpleExamRow.module.scss";
import DicomSendModal from "../../DicomSendModal/DicomSendModal";
import moment from "moment";
import StudyInfoPopover from "../../StudyInfoPopover/StudyInfoPopover";

const DosePopover = forwardRef(
  ({ popper, children, show: _, ...props }, ref) => {
    const { data, status } = useStudyDose({ studyId: props.studyId });
    return (
      <Popover ref={ref} content {...props}>
        {status === "loading" ? (
          <div>Loading...</div>
        ) : status === "error" ? (
          <div>Error</div>
        ) : (
          <div>
            <div>{data.mas} mAs</div>
            <div>{data.kv} kV</div>
            <div>{data.ugy} uGy</div>
          </div>
        )}
      </Popover>
    );
  }
);

DosePopover.propTypes = {
  popper: PropTypes.object,
  children: PropTypes.node,
  show: PropTypes.bool,
  studyId: PropTypes.string.isRequired,
};

const FindingStatus = ({ flag }) => {
  switch (flag) {
    case 0:
      return (
        <div className="text-warning text-center" title="Nieopisane">
          <i className="fas fa-exclamation-circle"></i>
        </div>
      );
    case 1:
      return (
        <div className="text-success text-center" title="Opisane">
          <i className="fas fa-check-circle"></i>
        </div>
      );
    default:
      return (
        <div className="text-danger text-center" title="Błąd">
          <i className="fas fa-times-circle"></i>
        </div>
      );
  }
};

FindingStatus.propTypes = {
  studyId: PropTypes.string.isRequired,
};

function SimpleExamRow({
  study,
  allowSelect,
  selectedStudies,
  handleStudySelect,
  gridTemplate,
}) {
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const [viewerPickerModalShown, setViewerPickerModalShown] = useState(false);
  const [StudyFindingDialogShown, setStudyFindingDialogShown] = useState(false);
  const [dicomSendModalShown, setDicomSendModalShown] = useState(false);

  const [seriesRowShown, setSeriesRowShown] = useState(false);
  const { userPreferences } = useContext(UserPreferencesContext);
  function openExamInViewer() {
    switch (userPreferences.VIEWER_TYPE) {
      case 0:
        openWebViewer();
        break;
      case 1:
        openPmView();
        break;
      case 2:
        setViewerPickerModalShown(true);
        break;
      default:
        setViewerPickerModalShown(true);
        break;
    }
  }
  function openPmView() {
    OpenExamInPmView(keycloak, study.studyinstanceuid);
  }
  function openWebViewer() {
    history.push("/viewer/" + study.studyinstanceuid);
  }
  function openPatient() {
    history.push("/PatientJournal?PatientIDs=" + [study.patient.patientid]);
  }
  return (
    <>
      <div
        key={study.id}
        className={styles.study}
        style={gridTemplate}
        onDoubleClick={openExamInViewer}
      >
        <DicomSendModal
          shown={dicomSendModalShown}
          close={() => setDicomSendModalShown(false)}
          selectedExams={[
            {
              id: study.id,
              studydescription: study.studydescription,
              date: study.studydate,
              patient: {
                patientsname: study.patient.patientsname,
                patientsvorname: study.patient.patientsvorname,
              },
            },
          ]}
        />
        <ViewerSelectModal
          shown={viewerPickerModalShown}
          onHide={() => setViewerPickerModalShown(false)}
          localViewerCallback={openPmView}
          webViewerCallback={openWebViewer}
        />
        <StudyFindingDialog
          show={StudyFindingDialogShown}
          onHide={() => setStudyFindingDialogShown(false)}
          studyId={study.id}
        />
        {allowSelect && (
          <div className="text-center">
            <FormCheck
              type="checkbox"
              checked={selectedStudies.map((s) => s.id).includes(study.id)}
              onChange={() => handleStudySelect(study)}
            />
          </div>
        )}
        <div className="text-center">
          <FindingStatus flag={study.flag} />
        </div>
        <div className="text-center" title={`Modalność ${study.modality}`}>
          <ModalitiesIcons
            modality={study.modality}
            style={{ width: "25px", height: "auto" }}
          />
        </div>
        <div
          className="text-center"
          title={`Płeć: ${capitalize(study.patient.patientssex)}`}
        >
          {getGenderIcon(study.patient.patientssex, "fa-lg")}
        </div>
        <div className={styles.wrap} title="Nazwisko">
          {capitalize(study.patient.patientsname)}
        </div>
        <div className={styles.wrap} title="Imie">
          {capitalize(study.patient.patientsvorname)}
        </div>
        <div title="PESEL">{study.patient.patientid}</div>
        <div title="Nazwa badania">{study.studydescription}</div>
        <div title="Data badania">
          {moment(study.studydate).format("DD.MM.YYYY HH:mm")}
        </div>
        <div className={styles.actionButtons}>
          <Button variant="secondary" size="sm" onClick={openExamInViewer}>
            <i className="fas fa-eye fa-sm"></i>
          </Button>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => setDicomSendModalShown(true)}
          >
            <i className="fas fa-paper-plane fa-sm"></i>
          </Button>
          <OverlayTrigger
            trigger="focus"
            placement="top"
            overlay={<DosePopover studyId={study.id} />}
          >
            <Button variant="secondary" size="sm">
              <i className="fas fa-radiation fa-sm"></i>
            </Button>
          </OverlayTrigger>
          <StudyInfoPopover
            studyId={study.id}
            autoLoad={window.conf.EXAM_ARCHIVE.autoLoadStatus}
          />
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setSeriesRowShown((Prev) => !Prev)}
          >
            <i className="fas fa-images fa-sm"></i>
          </Button>
          <Button variant="secondary" size="sm" onClick={openPatient}>
            <i className="fas fa-user fa-sm"></i>
          </Button>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setStudyFindingDialogShown(true)}
          >
            <i className="fas fa-file-medical-alt fa-sm"></i>
          </Button>
        </div>
      </div>
      {seriesRowShown && (
        <SeriesRow studyId={study.id} shouldLoad={seriesRowShown} />
      )}
    </>
  );
}

function SeriesRow({ studyId, shouldLoad }) {
  const { data, status } = useStudySeries({
    studyId: studyId,
    enabled: shouldLoad,
    xmask: "series{seriesdescription,protocolname,seriesdatetime,imgcount}",
  });
  return (
    <div className={styles.seriesRow}>
      {status === "loading" ? (
        <div>Loading...</div>
      ) : status === "error" ? (
        <div>Error</div>
      ) : (
        data.series.map((serie) => (
          <div>
            <div>
              <span>Nazwa: </span>
              <div>{serie.protocolname || serie.seriesdescription}</div>
            </div>
            <div>
              <span>Data: </span>
              <div>{getDDMMYYY_HHMM(serie.seriesdatetime)}</div>
            </div>
            <div>
              <span>Ilość obrazów: </span>
              <div>{serie.imgcount || "Brak danych"}</div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default SimpleExamRow;
