import { Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import {
  addExamplannerIndication,
  removeExamplannerIndication,
  clearExamplannerIndication,
} from "../../../Redux/actions.js";
import useWindowSize from "../../../Utilities/Window/windowSize";
import "./stylesheets/IndicationList.scss";
import { loadIndications } from "../../../Utilities/Examinations/ExamsHarmonizer";

import { ExamPlannerIndicationImage } from "./ExamPlannerImage.jsx";

import { LOG_DEBUG } from "../../../Utilities/Debug/DebugLevels.js";
import DebugLogger from "../../../Utilities/Debug/DebugLogger";
import { useTranslation } from "react-i18next";

const capitalize = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

function ExamPlannerIndicationRow({ indication, selectedIndications }) {
  const dispatch = useDispatch();

  const isSelected =
    selectedIndications.findIndex((i) => i.id === indication.id) > -1;

  return indication.designationde != "" ? (
    <div
      className={`p-2 border rounded d-flex align-items-center justify-content-left exmpl-row-btn ${
        isSelected ? "selected" : ""
      }`}
      onClick={() => {
        if (!isSelected) {
          dispatch(addExamplannerIndication(indication));
        } else {
          dispatch(removeExamplannerIndication(indication));
        }
      }}
    >
      <div className="font-weight-bold">{indication.designationde}</div>
      <div className="ml-auto mr-2 p-1 patpos-img-box">
        <ExamPlannerIndicationImage indId={indication.id} />
      </div>
    </div>
  ) : (
    <></>
  );
}

function IndicationList() {
  const { keycloak } = useKeycloak();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const selectedIndications = useSelector(
    (state) => state.examPlanner.risData.indications
  );
  const selectedBodyPart = useSelector(
    (state) => state.examPlanner.viewControll.selectedBodyPart
  );
  const [isLoading, setIsLoading] = useState(false);
  const [indicationsFilter, setIndicationsFilter] = useState("");
  const [indicationsList, setIndicationsList] = useState([]);
  const [hasMoreIndications, setHasMoreIndications] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation(["examplanner", "common"]);

  const loadIndicationsFromDb = (loadNextPage = false) => {
    setIsLoading(true);
    let page = currentPage;
    let filter = undefined;
    let anatomy = undefined;

    if (loadNextPage) {
      setCurrentPage(currentPage + 1);
      page = page + 1;
    } else {
      setCurrentPage(1);
      page = 1;
    }

    DebugLogger(LOG_DEBUG, `Indications page = ${page}`);

    if (indicationsFilter && indicationsFilter.length > 0) {
      filter = {
        or: [
          {
            field: "designationde",
            op: "like",
            value: `%${indicationsFilter}%`,
          },
          {
            field: "designationde",
            op: "like",
            value: `%${indicationsFilter.toUpperCase()}%`,
          },
          {
            field: "designationde",
            op: "like",
            value: `%${indicationsFilter.toLowerCase()}%`,
          },
          {
            field: "designationde",
            op: "like",
            value: `%${capitalize(indicationsFilter)}%`,
          },
        ],
      };
    }

    if (Object.keys(selectedBodyPart).length > 0) {
      anatomy = { anatomyId: selectedBodyPart.id };
    }

    // let sort = { model: "xmreasons", field: "id", direction: "asc" };
    const sort = undefined;

    loadIndications(keycloak, filter, 25, page, anatomy, sort)
      .then((data) => {
        setHasMoreIndications(
          data.indications && data.indications.length === 25
        );
        if (loadNextPage) {
          let examaintionListCopy = [...indicationsList];
          setIndicationsList([...examaintionListCopy, ...data.indications]);
        } else {
          setIndicationsList(data?.indications);
        }
      })
      .catch((reason) => {
        console.error(reason);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getListHeight = () => {
    if (windowSize.width < 1200) {
      return "64vh";
    } else {
      return "75vh";
    }
  };

  useEffect(() => {
    loadIndicationsFromDb(false);
  }, [selectedBodyPart, indicationsFilter]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <Form style={{ flexGrow: 20 }} onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="my-auto" controlId="formIndications">
            <Form.Control
              type="search"
              placeholder={t("reasonSelect.reasonsSearch")}
              value={indicationsFilter}
              onChange={(e) => setIndicationsFilter(e.target.value)}
            />
          </Form.Group>
        </Form>
        <button
          style={{ flexGrow: 1, justifySelf: "end" }}
          className="border-0 p-0 m-0 bg-white"
          disabled={selectedIndications.length === 0}
        >
          <i
            className="fa fa-broom p-2 border rounded btn-like-icon"
            onClick={() => dispatch(clearExamplannerIndication())}
          />
        </button>
      </div>
      {selectedIndications.length > 0 && (
        <div className="d-flex mb-3" style={{ flexWrap: "wrap" }}>
          {selectedIndications.map((indication, key) => (
            <div
              className="d-flex border align-items-center p-1 exp-selected-item"
              key={`selexmskey${key}`}
              onClick={() => {
                dispatch(removeExamplannerIndication(indication));
              }}
            >
              <div className="ml-2">{indication?.designationde}</div>
              <i className="fas fa-times fa-xs ml-2" />
            </div>
          ))}
        </div>
      )}
      <div
        name="IndicationsList"
        className="scrollable-list d-flex flex-column"
        style={{ maxHeight: getListHeight() }}
      >
        {isLoading && (
          <div className="d-flex justify-content-center align-items-center">
            <i className="fas fa-spinner fa-spin fa-2x" />
          </div>
        )}
        {!isLoading &&
          indicationsList?.map((indication, key) => (
            <ExamPlannerIndicationRow
              key={`indicationKey${key}`}
              indication={indication}
              selectedIndications={selectedIndications}
            ></ExamPlannerIndicationRow>
          ))}
        <Button
          onClick={() => loadIndicationsFromDb(true)}
          disabled={!hasMoreIndications || isLoading}
        >
          <i className="mr-2 fas fa-sync fa-xs" />
          {t("buttons.loadMore", { ns: "common" })}
        </Button>
      </div>
    </div>
  );
}

export default IndicationList;
