import { useEffect, useState, Fragment, useMemo, useContext } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";

import ImagesModal from "../ImagesModal/ImagesModal";
import ExamWorklistInfo from "../ExamWorklistInfo/ExamWorklistInfo";
import DoseModal from "../DoseModal/DoseModal";
import ViewerSelectModal from "../ViewerSelectModal/ViewerSelectModal";
import useWindowSize from "../../Utilities/Window/windowSize";
import ModalitiesIcons from "../ModalitiesIcons/ModalitiesIcons";
import { useInView } from "react-intersection-observer";
import { UserPreferencesContext } from "../../Providers/UserPreferencesProvider";

import { updateMasKvEntry } from "../../Utilities/Examinations/maskv";
import {
  useStudyDose,
  useStudyKartei,
  useStudySeries,
} from "../../Utilities/FetchHooks/Studies/StudiesHooks";

import {
  selectExam,
  unselectExam,
  selectExamSerie,
  unselectExamSerie,
  selectExamSerieImage,
  unselectExamSerieImage,
} from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";

import "./stylesheets/ExamRow.scss";
import { NotificationManager } from "react-notifications";
import { OpenExamInPmView } from "../../Utilities/ClientServices/ClientServices";

export default function ExamRow({
  exam_data,
  reloadHandler,
  showPatient = false,
  dark = false,
  showExamResultState = false,
}) {
  let history = useHistory();
  let { keycloak } = useKeycloak();

  const { ref, inView } = useInView({ triggerOnce: true });
  const { width } = useWindowSize();

  const serieDataQ = useStudySeries({
    studyId: exam_data.id,
    xmask: ["ct", "mr", "mri"].includes(exam_data.modality.toLowerCase())
      ? "series{id, seriesdescription, seriesdatetime, bodypartexamined, imgcount, image_collection{mas_kv_collection}}"
      : "",
    enabled: inView,
  });

  const studyKartei = useStudyKartei({
    studyId: exam_data.id,
    enabled: showExamResultState,
  });

  const serieData = serieDataQ.data;
  const doseDataQ = useStudyDose({ studyId: exam_data.id });
  const doseData = doseDataQ.data;
  const [infoModalShown, setInfoModalShown] = useState(false);
  const [doseModalShown, setDoseModalShown] = useState(false);
  const [viewerSelectModalShown, setViewerSelectModalShown] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const { userPreferences } = useContext(UserPreferencesContext);

  const isRadiationModality = ["ct", "dx", "cr", "mg"].includes(
    exam_data?.modality.toLowerCase()
  );

  const ExaminationsExpanded = useSelector(
    (state) => state.journalFlags.expandAllExaminations
  );

  const [isExpanded, setIsExpanded] = useState(ExaminationsExpanded);

  const shouldSelectAll = useSelector(
    (state) => state.journalFlags.selectAllExaminations
  );

  const toggleInfoModal = () => {
    setInfoModalShown(!infoModalShown);
  };

  const loadToWebViewer = () => {
    console.log(`WebViewer open exam with id = ${exam_data.studyinstanceuid}`);
    history.push("/viewer/" + exam_data.studyinstanceuid);
  };

  const loadToPmView = () => {
    console.log(`pmView open exam with id = ${exam_data.studyinstanceuid}`);
    OpenExamInPmView(keycloak, exam_data.studyinstanceuid);
  };

  const loadExam = () => {
    switch (userPreferences.VIEWER_TYPE) {
      case 0:
        loadToWebViewer();
        break;
      case 1:
        loadToPmView();
        break;
      case 2:
        toggleViewerSelectModal();
        break;
      default:
        toggleViewerSelectModal();
        break;
    }
  };

  const copyViewerUrl = () => {
    let location = window.location;
    navigator.clipboard.writeText(
      `${location.origin}/viewer/${exam_data.studyinstanceuid}`
    );
  };

  const toggleViewerSelectModal = () => {
    setViewerSelectModalShown(!viewerSelectModalShown);
  };

  const toggleDoseModal = () => {
    setDoseModalShown(!doseModalShown);
  };

  const dispatch = useDispatch();
  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  const makeExamSelectedObject = () => {
    let res = {
      id: exam_data.id,
      suid: exam_data.studyinstanceuid,
      kontoid: exam_data.kontoid,
      series: [],
    };
    serieData?.series?.forEach((serie) => {
      let imagesList = [];
      serie?.image_collection?.forEach((image) => {
        imagesList.push(image.image_id);
      });
      res.series.push({ id: serie.id, images: imagesList });
    });
    return res;
  };

  const toggleSelected = () => {
    if (serieDataQ.status !== "success") {
      NotificationManager.warning("Series are still loading wait to select");
      return;
    }
    let isAlreadySelected = selectedExaminations.find(
      (exam) => exam.id === exam_data.id
    );
    if (isAlreadySelected) {
      dispatch(unselectExam(exam_data.id));
    } else {
      let obj = makeExamSelectedObject();
      dispatch(selectExam(obj));
    }
    setIsSelected(!isSelected);
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const selectMe = () => {
    if (serieDataQ.status !== "success") {
      NotificationManager.warning(
        "Series are still loading wait to select all"
      );
      return;
    }

    let isAlreadySelected = selectedExaminations.find(
      (exam) => exam.id === exam_data.id
    );
    if (isAlreadySelected) return;

    let obj = makeExamSelectedObject();
    dispatch(selectExam(obj));
    setIsSelected(!isSelected);
  };

  useEffect(() => {
    setIsExpanded(ExaminationsExpanded);
  }, [ExaminationsExpanded]);

  useEffect(() => {
    if (shouldSelectAll) {
      selectMe();
    }
  }, [shouldSelectAll]);

  return (
    <div className={`${isExpanded ? "mb-2" : ""}`}>
      <ExamWorklistInfo
        std_id={exam_data.id}
        shown={infoModalShown}
        onHide={toggleInfoModal}
      />
      <DoseModal
        exam_data={exam_data}
        shown={doseModalShown}
        onHide={toggleDoseModal}
      />
      <ViewerSelectModal
        shown={viewerSelectModalShown}
        onHide={toggleViewerSelectModal}
        localViewerCallback={loadToPmView}
        webViewerCallback={loadToWebViewer}
      />
      <div
        className={`${
          isExpanded ? "rounded-expanded" : "rounded"
        } mt-1 d-flex flex-column w-100 px-1 ${
          dark
            ? "bg-exam-archive-dark border dark-border"
            : "bg-exam-archive border light-border "
        }`}
      >
        <div
          ref={ref}
          className={`journal-examination-row ${
            showPatient ? "show-patient" : ""
          } ${showExamResultState ? "finding_status" : ""}`}
        >
          <div>
            {serieDataQ.status !== "success" ? (
              <ClipLoader size={15} />
            ) : selectedExaminations.find(
                (exam) => exam.id === exam_data.id
              ) ? (
              <i
                className="fas fa-check-square exam-button-like fa-lg "
                onClick={toggleSelected}
              ></i>
            ) : (
              <i
                onClick={toggleSelected}
                className="far fa-square fa-lg exam-button-like "
              ></i>
            )}
          </div>
          {showExamResultState && <FindingState studyKartei={studyKartei} />}
          <div className="d-flex align-items-center justify-content-center">
            <ModalitiesIcons
              modality={exam_data.modality}
              style={{ height: width > 500 ? "30px" : "25px" }}
            />
            {/* <h5 className="my-1">{exam_data.modality}</h5> */}
          </div>
          {showPatient && (
            <>
              <div
                className="description-break"
                style={{ justifySelf: "start" }}
              >
                {exam_data?.patientsname
                  ? exam_data?.patientsname.replace("^", " ")
                  : "nd"}
              </div>
              <div
                className="description-break"
                style={{ justifySelf: "start" }}
              >
                {exam_data?.patient.patientid
                  ? exam_data?.patient.patientid
                  : exam_data.dapatid
                  ? exam_data.dapatid
                  : "nd"}
              </div>
            </>
          )}
          <div className="description-break" style={{ justifySelf: "start" }}>
            {exam_data?.studydescription}
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center right-part">
            <div className={`${width < 500 ? "small" : ""}`}>
              {moment(exam_data?.studydate).format("DD-MM-YYYY")}
            </div>
            {width > 500 && (
              <div className="small">
                {moment(exam_data?.studydate).fromNow()}
              </div>
            )}
          </div>
          {isRadiationModality ? (
            <div className="d-flex align-items-center justify-content-center">
              {doseData === undefined && <ClipLoader size={5} />}
              {doseData === null && (
                <i
                  name="No dose Informations available"
                  className="fas fa-ban"
                />
              )}
              {doseData && (
                <div className="d-flex flex-column">
                  <div className={`${width < 500 ? "small" : ""}`}>
                    {doseData?.mas} <span className="small">mAs</span>
                  </div>
                  <div className={`${width < 500 ? "small" : ""}`}>
                    {doseData?.ugy?.toPrecision(3)}{" "}
                    <span className="small">uGy</span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <i className="fas fa-ban fa-sm" />
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center">
            <i
              onClick={loadExam}
              title="Open in Exam Viewer"
              className="fas fa-eye exam-button-like"
            ></i>
          </div>
          <div className="mx-0 d-flex justify-content-center align-items-center">
            {isExpanded ? (
              <i
                className="fas fa-chevron-up exam-button-like"
                onClick={toggleExpanded}
              ></i>
            ) : (
              <i
                className="fas fa-chevron-down exam-button-like"
                onClick={toggleExpanded}
              ></i>
            )}
          </div>
        </div>
        <div
          className={`journal-examination-sub-row ${
            isExpanded ? "" : "hidden"
          } ${!dark && "light"}`}
        >
          <div className={`${width < 500 ? "small" : ""}`}>
            {serieData ? (
              `${serieData?.series?.length} Serie(s)`
            ) : (
              <ClipLoader size={20} />
            )}
          </div>
          <div>
            {isRadiationModality && (
              <i
                onClick={toggleDoseModal}
                title="Show dose informations"
                className="fas fa-radiation exam-button-like"
              ></i>
            )}
          </div>
          <div>
            <i
              onClick={copyViewerUrl}
              title="Copy viewer link"
              className="fas fa-copy exam-button-like"
            ></i>
          </div>
          <div>
            <i
              title="Show exam result"
              className="fas fa-file-medical-alt exam-button-like"
            ></i>
          </div>
          <div>
            <i
              onClick={toggleInfoModal}
              title="Show exam informations"
              className="fas fa-info-circle exam-button-like"
            ></i>
          </div>
        </div>
      </div>
      {serieData?.series?.map((serie, i) => (
        <SerieRow
          exam_id={exam_data.id}
          parentExpanded={isExpanded}
          serieData={serie}
          key={`serie${i}`}
          modality={exam_data.modality}
          className={isExpanded ? "serie show" : "serie hide"}
          dark={dark}
        />
      ))}
    </div>
  );
}

function SerieRow({
  exam_id,
  parentExpanded,
  serieData,
  className,
  modality,
  dark = false,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [imagesExpandable, setimagesExpandable] = useState(true);
  const [dosisInfo, setDosisInfo] = useState(undefined);
  const isRadiationModality = ["ct", "dx", "cr", "mg"].includes(
    modality.toLowerCase()
  );

  const expandableModalities = ["CR", "DX", "OT", "MG"];

  function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }

  const calculateTotalDosisInfo = () => {
    let doseInfo = { mas: 0, ugy: 0 };
    serieData?.image_collection?.forEach((image) => {
      doseInfo.mas = roundToTwo(
        doseInfo.mas + image?.mas_kv_collection[0]?.mas
      );
      doseInfo.ugy = roundToTwo(
        doseInfo.ugy + image?.mas_kv_collection[0]?.dosis
      );
    });
    setDosisInfo(doseInfo);
  };

  useEffect(() => {
    calculateTotalDosisInfo();
  }, [serieData]);

  useEffect(() => {
    if (!parentExpanded) {
      setimagesExpandable(false);
    } else {
      setimagesExpandable(true);
    }
  }, [parentExpanded]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const shouldExpand = expandableModalities.includes(modality);

  const dispatch = useDispatch();
  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  const isChecked = () => {
    let examSelectedIdx = selectedExaminations.findIndex(
      (exam) => exam.id === exam_id
    );
    if (examSelectedIdx > -1) {
      return selectedExaminations[examSelectedIdx].series.find(
        (serie) => serie.id === serieData.id
      );
    } else {
      return false;
    }
  };

  const toggleSelected = () => {
    const getSerieImages = () => {
      let res = [];
      serieData.image_collection.forEach((image) => {
        res.push(image.image_id);
      });
      return res;
    };
    if (isChecked()) {
      dispatch(unselectExamSerie({ exam_id: exam_id, serie_id: serieData.id }));
    } else {
      let obj = getSerieImages();
      dispatch(
        selectExamSerie({
          id: exam_id,
          series: [{ id: serieData.id, images: obj }],
        })
      );
    }
  };

  const { width } = useWindowSize();

  return (
    <>
      <div
        className={`journal-serie-row ${!dark && "light"} ${
          dark ? "bg-exam-archive-dark" : "bg-exam-archive"
        } ${parentExpanded ? "" : "hidden"} `}
      >
        <div className="">
          {isChecked() ? (
            <i
              className="fas fa-check-square exam-button-like fa-lg "
              onClick={toggleSelected}
            ></i>
          ) : (
            <i
              className="far fa-square fa-lg exam-button-like "
              onClick={toggleSelected}
            ></i>
          )}
        </div>
        <div style={{ justifySelf: "start" }} className="description-break">
          {serieData.seriesdescription}
        </div>
        <div className={`${width < 500 ? "small" : ""}`}>
          [ {serieData?.imgcount} ]
        </div>
        {isRadiationModality ? (
          <div className={`${width < 500 ? "small" : ""}`}>
            {dosisInfo?.mas} <span className="small">mAs</span> {dosisInfo?.ugy}
            <span className="small">uGy</span>
          </div>
        ) : (
          <div></div>
        )}
        {shouldExpand && (
          <div>
            {isExpanded ? (
              <i
                className="fas fa-chevron-up exam-button-like"
                onClick={toggleExpanded}
              ></i>
            ) : (
              <i
                className="fas fa-chevron-down exam-button-like"
                onClick={toggleExpanded}
              ></i>
            )}
          </div>
        )}
      </div>

      {expandableModalities.includes(modality) &&
        serieData?.image_collection?.map((image, i) => (
          <ImageRow
            exam_id={exam_id}
            serie_id={serieData.id}
            imageData={image}
            key={`image${i}`}
            parentExpanded={isExpanded && imagesExpandable}
            className={
              isExpanded && imagesExpandable ? "image shown" : "image hidden"
            }
            dark={dark}
          />
        ))}
    </>
  );
}

function ImageRow({
  exam_id,
  serie_id,
  imageData,
  className,
  parentExpanded,
  dark = false,
}) {
  const { keycloak } = useKeycloak();

  const [isSelected, setIsSelected] = useState(false);
  const [isImagesModalShown, setIsImagesModalShown] = useState(false);

  const [mas, setMas] = useState(imageData?.mas_kv_collection[0]?.mas);
  const [kv, setKv] = useState(imageData?.mas_kv_collection[0]?.kv);
  const [ugy, setUgy] = useState(imageData?.mas_kv_collection[0]?.dosis);

  const toggleImagesModal = () => {
    setIsImagesModalShown(!isImagesModalShown);
  };
  const dispatch = useDispatch();
  const selectedExaminations = useSelector(
    (state) => state.examSelector.selectedExaminations
  );

  const saveDoseChange = () => {
    let obj = {
      mas: mas,
      kv: kv,
      dosis: ugy,
    };
    updateMasKvEntry(keycloak, imageData.mas_kv_collection[0].id, obj)
      .then(() => {
        NotificationManager.success("Dose updated successfully");
      })
      .catch((reason) => {
        console.warn("Unable to update maskv entry", reason);
        NotificationManager.error("Unable to update dose informations");
      });
  };

  const isChecked = () => {
    let examSelectedIdx = selectedExaminations.findIndex(
      (exam) => exam.id === exam_id
    );
    if (examSelectedIdx > -1) {
      let serieSelectedIdx = selectedExaminations[
        examSelectedIdx
      ].series.findIndex((serie) => serie.id === serie_id);
      if (serieSelectedIdx > -1) {
        return selectedExaminations[examSelectedIdx].series[
          serieSelectedIdx
        ].images.includes(imageData.image_id);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const toggleSelected = () => {
    if (isChecked()) {
      dispatch(
        unselectExamSerieImage({
          exam_id: exam_id,
          serie_id: serie_id,
          image_id: imageData.image_id,
        })
      );
    } else {
      dispatch(
        selectExamSerieImage({
          id: exam_id,
          series: [{ id: serie_id, images: [imageData.image_id] }],
        })
      );
    }
  };

  return (
    <>
      <ImagesModal
        shown={isImagesModalShown}
        onHide={toggleImagesModal}
        imgUrl={imageData.preview_url}
      />
      <div
        className={`journal-image-row ${
          !window.conf.PATIENT_JOURNAL.SHOW_DOSE_EDIT_FIELDS && "no-edit"
        } ${!dark && "light"} ${
          dark ? "bg-exam-archive-dark" : "bg-exam-archive"
        } ${parentExpanded ? "" : "hidden"}`}
      >
        <div>
          {isChecked() ? (
            <i
              className="fas fa-check-square exam-button-like fa-lg "
              onClick={toggleSelected}
            ></i>
          ) : (
            <i
              className="far fa-square fa-lg exam-button-like "
              onClick={toggleSelected}
            ></i>
          )}
        </div>
        <div style={{ justifySelf: "start" }} className="description-break">
          {imageData?.imgdetails_collection[0]?.imagedescription
            ? imageData?.imgdetails_collection[0]?.imagedescription
            : "unknown"}
        </div>
        {window.conf.PATIENT_JOURNAL.SHOW_DOSE_EDIT_FIELDS && (
          <Fragment>
            <div>
              <InputGroup>
                <Form.Control
                  className=" border-0"
                  size="sm"
                  type="text"
                  value={mas}
                  onChange={(e) => setMas(e.target.value)}
                />
                <InputGroup.Append>
                  <InputGroup.Text className="border-0 p-0 bg-white pr-1 text-xs">
                    mAs
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </div>
            <div>
              <InputGroup>
                <Form.Control
                  className=" border-0"
                  size="sm"
                  type="text"
                  value={kv}
                  onChange={(e) => setKv(e.target.value)}
                />
                <InputGroup.Append>
                  <InputGroup.Text className="border-0 p-0 bg-white pr-1 text-xs">
                    kV
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </div>
            <div>
              <InputGroup>
                <Form.Control
                  className=" border-0"
                  size="sm"
                  type="text"
                  value={ugy}
                  onChange={(e) => setUgy(e.target.value)}
                />
                <InputGroup.Append>
                  <InputGroup.Text className="border-0 p-0 bg-white pr-1 text-xs">
                    uGy
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </div>
            <div onClick={saveDoseChange} title="Save dose changes">
              <i className="fas fa-save  exam-button-like "></i>
            </div>
          </Fragment>
        )}
        <div title="show preview">
          <i
            className="fas fa-search  exam-button-like"
            onClick={toggleImagesModal}
          ></i>
        </div>
      </div>
    </>
  );
}

function FindingState({ studyKartei }) {
  const { befunds, statuses } = useMemo(() => {
    const befunds = [];
    const statuses = [];
    studyKartei?.data?.kartei_entries.forEach((entry) => {
      if (entry?.text?.toLowerCase().includes("befund")) {
        befunds.push(1);
      }
    });
    studyKartei?.data?.kartei_entries.forEach((entry) => {
      entry?.kartei_data?.forEach((data) => {
        statuses.push(data.finding_status);
      });
    });
    return { befunds, statuses };
  }, [studyKartei]);

  if (statuses.includes(1)) {
    return <i title="Opisane" className="fas fa-check-circle text-success"></i>;
  }

  if (befunds.includes(1)) {
    return (
      <i
        title="Opis nie ukończony"
        className="fas fa-stop-circle text-warning"
      ></i>
    );
  }

  return <i title="Brak opisu" className="fas fa-times-circle text-danger"></i>;
}
