import { Spinner } from "react-bootstrap";
import LayingCaudal from "./ImageComps/LayingCaudal";
import LayingCranial from "./ImageComps/LayingCranial";
import StandingCaudal from "./ImageComps/StandingCaudal";
import StandingCranial from "./ImageComps/StandingCranial";

import verticalImg from "./ImageComps/images/layingNoTilt.svg";
import horizontalImg from "./ImageComps/images/standingNoTilt.svg";

function TubeTilt({ examData }) {
  const tiltDegFrom = examData?.obj_deg_from;
  const tiltDegTill = examData?.obj_deg_till;

  const tiltDeg =
    tiltDegFrom && tiltDegTill && tiltDegFrom !== tiltDegTill
      ? `${tiltDegFrom}° - ${tiltDegTill}°`
      : tiltDegFrom
      ? `${tiltDegFrom}°`
      : tiltDegTill
      ? `${tiltDegTill}°`
      : "";

  if (examData?.tube_tilt && !examData?.tube_direction) {
    if (examData.tube_tilt === "waagerecht") {
      return <img src={horizontalImg} alt="standing" />;
    }
    if (examData.tube_tilt === "senkrecht") {
      return <img src={verticalImg} alt="laying" />;
    }
  }
  if (examData?.tube_tilt && examData?.tube_direction) {
    if (examData.tube_tilt === "waagerecht") {
      if (examData.tube_direction === "cranial") {
        return <StandingCranial angle={tiltDeg} />;
      }
      if (examData.tube_direction === "caudal") {
        return <StandingCaudal angle={tiltDeg} />;
      }
    }
    if (examData.tube_tilt === "senkrecht") {
      if (examData.tube_direction === "cranial") {
        return <LayingCranial angle={tiltDeg} />;
      }
      if (examData.tube_direction === "caudal") {
        return <LayingCaudal angle={tiltDeg} />;
      }
    }
  }

  return <Spinner animation="border" />;
}

export default TubeTilt;
