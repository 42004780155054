import { useEffect, useState } from "react";
import { VIEWS_MAP } from "../mocks/NoWords.mock";
export const MAIN_IMAGE = "MAIN_IMAGE";

function UseNowWordsData({ examId }) {
  const [activeViewKey, setActiveViewKey] = useState(MAIN_IMAGE);
  const { Image, label } = Object.keys(VIEWS_MAP).includes(examId?.toString())
    ? VIEWS_MAP[examId][activeViewKey]
    : VIEWS_MAP[0][activeViewKey];

  useEffect(() => {
    setActiveViewKey(MAIN_IMAGE);
  }, [examId]);

  const Img =
    typeof Image === "function" ? (
      <Image setKey={setActiveViewKey} />
    ) : (
      <img src={Image} alt={label} />
    );

  return {
    Image: Img,
    isMainView: activeViewKey === MAIN_IMAGE,
    setMainView: () => setActiveViewKey(MAIN_IMAGE),
  };
}

export default UseNowWordsData;
