import "./SidVertical.scss";

function SidVertical({ distance }) {
  return (
    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 283.5 283.5"
    >
      <defs>
        <linearGradient
          id="Unbenannter_Verlauf"
          data-name="Unbenannter Verlauf"
          x1="302"
          y1="-109.6"
          x2="341.2"
          y2="-109.6"
          gradientTransform="translate(-178.1 -35.2) rotate(0) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#302f2e" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#000" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_2"
          data-name="Unbenannter Verlauf 2"
          x1="297.2"
          y1="-73"
          x2="343.6"
          y2="-73"
          gradientTransform="translate(-178.5 -36.3) rotate(.2) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".7" stop-color="#302f2e" />
          <stop offset=".8" stop-color="#363635" />
          <stop offset="1" stop-color="#000" />
        </linearGradient>
      </defs>
      <rect
        class="sidvert4"
        x=".9"
        y=".5"
        width="283"
        height="283"
        transform="translate(-.3 .3) rotate(-.1)"
      />
      <line class="sidvert7" x1="258.3" y1="261.8" x2="20.5" y2="261.3" />
      <polygon
        class="sidvert3"
        points="141.2 252.7 126.3 222.4 137.4 228.1 137.5 206.4 145.4 206.4 145.3 227.3 156.4 222.4 141.2 252.7"
      />
      <g>
        <polygon
          class="sidvert2"
          points="163 89.5 123.8 89.4 135 60.5 151.7 60.6 163 89.5"
        />
        <circle class="sidvert5" cx="141.7" cy="37.9" r="23.2" />
        <line class="sidvert6" x1="140.9" y1="89.4" x2="140.9" y2="121.7" />
      </g>
      <g class="sidvert0">
        <g class="sidvert0">
          <g class="sidvert0">
            <text class="sidvert1" transform="translate(77.8 174.7)">
              <tspan x="0" y="0">
                {distance} cm
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SidVertical;
