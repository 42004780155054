import "./FootView.scss";
function FootView() {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 750.7 538.8"
    >
      <g id="Ebene_2_00000116203882871106391580000004068816992970089603_">
        <path
          className="footviewNW0"
          d="M338.5,510.3l37.8-237.2c1.7-10.5-6.1-20.1-16.7-20.6l-196.8-9.3L70.4,507.7L338.5,510.3L338.5,510.3z"
        />
        <path
          className="footviewNW1"
          d="M39.7,512.7l138.1-353.9l410.2,3.9l-12.7,88.9L448,254.7c-7.6,0.2-14,6-14.9,13.7l-30.8,244.9L39.7,512.7
		L39.7,512.7z"
        />
        <path
          className="footviewNW2"
          d="M415.6,513.3l21.8-213.8c0.8-7.9,7.2-14,15-14.3l111.9-4.4l11.1-30.7l-126.3,3.1c-8.2,0.2-15.1,6.5-16.1,14.8
		l-30.6,245.1L415.6,513.3L415.6,513.3z"
        />
        <path
          className="footviewNW3"
          d="M353.4,507.7L389,294.5c-2.4-25.7-11.2-34.5-21.3-39.6l-38.9,252.7L353.4,507.7L353.4,507.7z"
        />
        <polyline
          className="footviewNW4"
          points="74.3,34.8 120.6,502.5 205.3,502.5 216.3,482.5 252.7,483.4 252.7,507.7 292.8,507.7 269.2,22 	"
        />
        <path
          className="footviewNW5"
          d="M227.6,33.1l25.1,450.3l-36.5-0.9l-10.9,20.1c0,0.1-15.2-203.1-34.9-464.8"
        />
        <polyline
          className="footviewNW6"
          points="269.7,33.1 292.8,507.7 344.3,302.5 334,22 	"
        />
      </g>
      <g id="Ebene_1_00000080897391881867882380000009193295692665484960_">
        <path
          className="footviewNW7"
          d="M514.8,389.8c-12.5,1.5-19,22.8-15.8,32.9c3.6,11.3,12,18.1,23.9,18.2c4.5,0,9.1-0.8,13.3-2.4l26.6-9.7
		c1.8-0.7,3.8-1.1,5.7-1.3c32.3-3,58.2-11.5,70.6-31.5c5.1-7.4,6.3-15.9,3-25.7c-2.2-6.6-8.5-11.6-16.3-12.9c-9.9-1.6-23,0-36.3,1.8
		c-3.2,0.4-6.5,0.5-9.6,0.1c-17.5-2-27.9,0.3-34.5,5c-0.3,0.2-0.1,0.6,0.2,0.6c10.6-1.2,17.9,3.6,20.7,16.6
		c0.4,12.6-7.7,23.4-18.3,33.6c-3.5,3.4-8.7,5-13.7,4.3C514.5,416.9,520.1,399,514.8,389.8L514.8,389.8z"
        />
        <polygon
          className="footviewNW8"
          points="568.8,417.3 589.4,406.8 608.2,408.7 616.1,422.6 602.2,456.3 	"
        />
        <g>
          <path
            className="footviewNW9"
            d="M648.8,78.1c-2.3,18.8-5.2,37.7-8.7,56.6c-5,26.2-12.6,50.4-22.2,73c-7.4,17.3-11.2,35.9-11.3,54.8
			c-0.1,41.9-6.9,90.6-17.5,142.9c4.8,15.6,12,24.9,23.2,24.6l-4.6,24.6c-12.8,12.2-24.4,17.6-34.6,15
			c-16.5-4.1-26.4-13.9-30.5-28.7l1.8-21.9c-12.1,4.9-22.6-0.5-31.9-12.8l-0.5-40.1l-25.7-114.7c-3.7-16.5-4.6-33.6-2.4-50.4
			c1.5-11.3,4.2-20.8,7.9-28.9c13.5-30,18.6-63,15.5-95.6"
          />

          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="482.3134"
            y1="-12.2296"
            x2="649.9066"
            y2="-12.2296"
            gradientTransform="matrix(1 0 0 -1 0 233.3044)"
          ></linearGradient>
          <path
            className="footviewNW10"
            d="M649.9,79.7c-2.6,25.5-6.5,48.6-12.5,67.8c-3.7,21.2-10.7,42-20.5,62.4c-12.1,28.9-7.9,147-26.1,197.1
			c-15.2,4.7-31.9,6.8-49.5,7.3l-1.7-38.9c-10.2,1.8-20.5,0.5-30.8-3.9l-15.9-90.7c-13.7-41-13.6-75.6-2.5-105.2
			c13-27.9,20.3-59.8,16.9-99"
          />
          <path
            className="footviewNW11"
            d="M539.7,375.5c-0.9-8.6-2-18.9-3-28c-19.7-51.3-15.3-94.1-5-134.9c8.4-28.7,12.3-44.5,14.9-72.3
			c0.4-4.3,0.2-8.6-0.5-12.8c-2.7-16-5.1-32.6-7.1-49.2"
          />
        </g>
        <path
          className="footviewNW7"
          d="M542.6,441c-4.6,7.7-7,15.3-5.7,22.8c0.2,0.9,0.3,1.8,0.4,2.7c1.8,13.9,8.2,23,21.4,25.1
		c4.5,0.7,9.1,0.4,13.5-0.8l27.7-7.2c1.9-0.5,3.9-0.7,5.9-0.6c32.4,1.4,59.2-4.5,74-25.7c6-7.7,8.3-17.3,6.3-29.1
		c-1.4-8-6.9-14.7-14.5-17.3c-9.6-3.3-22.8-3.4-36.2-3.3c-3.2,0-6.5-0.4-9.6-1.3c-17.1-4.9-27.7-3.8-34.9,0.7
		c-0.3,0.2-0.2,0.7,0.2,0.7c10.7,0.2,17.3,6.8,18.4,22.2c-1.2,14.6-10.6,25.8-22.4,35.9c-3.9,3.3-9.2,4.4-14.1,2.9
		C553.4,462.9,546.7,452.4,542.6,441L542.6,441z"
        />
        <line
          className="footviewNW11"
          x1="544.4"
          y1="419.2"
          x2="544.4"
          y2="414.4"
        />
      </g>
      <polyline
        className="footviewNW12"
        points="551.9,500.1 486,430.1 440.5,381.7 434.6,447.6 428.8,512.3 "
      />
      <path
        className="footviewNW12"
        d="M435.8,447.6c23.2,4.9,38.9-2.7,50.2-17.6"
      />
      <text
        transform="matrix(1 0 0 1 460.9207 492.038)"
        className="footviewNW13 st14 st15"
      >
        45°
      </text>
    </svg>
  );
}

export default FootView;
