import exhaleW from "./Images/breathe_out_girl_10x10.svg";
import exhaleM from "./Images/breathe_out_boy_10x10.svg";
import inhaleW from "./Images/breathe_in_girl_10x10.svg";
import inhaleM from "./Images/breathe_in_boy_10x10.svg";
import breathnormallyW from "./Images/breathing_normaly_girl_10x10.svg";
import breathnormallyM from "./Images/breathing_normaly_boy_10x10.svg";
import holdstillW from "./Images/hold_breath_girl_10x10.svg";
import holdstillM from "./Images/hold_breath_boy_10x10.svg";
import exposition from "./Images/radioactive_sign_black_without_circle.svg";

const EXHALE = {
  imagewoman: exhaleW,
  imagemen: exhaleM,
  cmdDE:
    "Bitte atmen Sie jetzt einmal tief aus und halten dann kurz die Luft an!",
  cmdPL: "Proszę teraz wykonać głęboki wydech i na chwilę zatrzymać oddech!",
  cmdEN: "Please exhale deeply and hold your breath for a moment!",
  cmdNL: "Adem nu diep uit en houd dan even uw adem in",
  cmdTR: "Lütfen derin bir nefes alın ve bir an için nefesinizi tutun",
};

const INHALE = {
  imagewoman: inhaleW,
  imagemen: inhaleM,
  cmdDE:
    "Bitte atmen Sie jetzt einmal tief ein und halten dann kurz die Luft an!",
  cmdPL: "Proszę teraz wykonać głęboki wdech i na chwilę zatrzymać oddech!",
  cmdEN: "Please inhale deeply and hold your breath for a moment!",
  cmdNL: "Adem nu diep in en houd dan even uw adem in",
  cmdTR: "Lütfen derin bir nefes alın ve bir an için nefesinizi tutun",
};

const HOLD_STILL = {
  imagewoman: holdstillW,
  imagemen: holdstillM,
  cmdDE: "Bitte bewegen Sie sich keinen Moment",
  cmdPL: "Proszę nie ruszać się przez chwilę",
  cmdEN: "Please hold still for a moment",
  cmdNL: "Blijf even stil staan",
  cmdTR: "Lütfen bir an hareket etmeyin",
};

const BREATHING_ALLOWED = {
  imagewoman: breathnormallyW,
  imagemen: breathnormallyM,
  cmdDE: "Sie können wieder normal atmen",
  cmdPL: "Możesz znów oddychać normalnie",
  cmdEN: "You can breathe normally again",
  cmdNL: "U kunt weer normaal ademen",
  cmdTR: "Tekrar normal nefes alabilirsin",
};

const RELAX = {
  imagewoman: breathnormallyW,
  imagemen: breathnormallyM,
  cmdDE: "Jetz bitte wieder entspannt stehen/liegen",
  cmdPL: "Możesz już się poruszać",
  cmdEN: "You can move again",
  cmdNL: "U kunt weer bewegen",
  cmdTR: "Tekrar rahatlayın",
};

const EXPOSITION = {
  imagewoman: exposition,
  imagemen: exposition,
  cmdDE: "Belichtung",
  cmdPL: "Ekspozycja",
  cmdEN: "Exposition",
  cmdNL: "Belichting",
  cmdTR: "Maruz Kalma",
};

const PAT_CMDS = {
  0: [HOLD_STILL, EXPOSITION, RELAX],
  1: [INHALE, EXPOSITION, BREATHING_ALLOWED],
  2: [EXHALE, EXPOSITION, BREATHING_ALLOWED],
};

function useCommandSteps(patCmd) {
  if (
    patCmd === null ||
    patCmd === undefined ||
    !Object.keys(PAT_CMDS).includes(patCmd.toString())
  )
    return [];
  return PAT_CMDS[patCmd];
}

export default useCommandSteps;
