import { getFetch, MakeUrl, REST_URL } from "../../FetchWrapper";

export async function getWorklists(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/ris/worklist`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getWorklist(keycloak, params) {
  if (!params.worklistId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/worklist/${params.worklistId}`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getWorklistStudies(keycloak, params) {
  if (!params.worklistId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/worklist/${params.worklistId}/studies`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getWorklistStudiesList(
  keycloak,
  params,
  pageParam = undefined
) {
  const url = MakeUrl(
    `${REST_URL}/ris/worklist/studies`,
    params.pageSize,
    pageParam ? pageParam : params.pageNr,
    params.filter,
    params.sort
  );
  if (params.ignoreEmptyImageMasKv) {
    url.searchParams.append("ignore_empty_mas_kv", true);
  }
  return getFetch(url, keycloak, params.xmask);
}

export async function getWorklistQmList(
  keycloak,
  params,
  pageParam = undefined
) {
  const url = MakeUrl(
    `${REST_URL}/ris/worklist_qm`,
    params.pageSize,
    pageParam ? pageParam : params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}
