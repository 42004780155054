import { useContext } from "react";
import { UserPreferencesContext } from "@/Providers/UserPreferencesProvider";

function useUserPreferences() {
  const { userPreferences, setUserPreferences } = useContext(
    UserPreferencesContext
  );
  return { userPreferences, setUserPreferences };
}

export default useUserPreferences;
